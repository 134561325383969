import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from '@mui/styles'
import {Link, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles({
    tooltipHeader: {
        marginBottom: "3px",
        fontWeight: "bold"
    }
});

const HtmlTooltipComponent = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #988F8F",
        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.75)",
        webkitBoxShadow: "5px 5px 5px 0px rgba(0,0,0,0.75)",
        mozBoxShadow: "5px 5px 5px 0px rgba(0,0,0,0.75)"
    },
    arrow: {
        "&:before": {
            border: "1px solid #988F8F"
        },
        color: theme.palette.common.white
    },
}))(Tooltip);

const HtmlTooltip = ({help, children, ...props}) => {

    const classes = useStyles();

    // @ts-ignore
    return <>{help && help.body ? <HtmlTooltipComponent title={<>
        {help.title ?
            <Typography color="inherit" variant={"subtitle1"} className={classes.tooltipHeader}>{help.title}</Typography> : ""}
        <Typography color="inherit" variant={"body1"}>{help.body}</Typography>
        {help.link_href ? <Typography color="inherit" variant={"caption"}><Link href={help.link_href}
                                                              target={"blank"}>{help.link_anchor}</Link></Typography>: ""}
    </>} {...props}>{children}</HtmlTooltipComponent> : <>{children}</>}</>
}

HtmlTooltip.propTypes = {
    help: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    children: PropTypes.any.isRequired
}

export default HtmlTooltip;