import * as React from 'react';
import {useEffect, useState} from 'react';
import {usePageTitle} from "../hooks/usePageTitle";
import {Box, CircularProgress, Collapse, Typography} from "@mui/material";
import {useAppSelector} from "../store/store";
import {parseQueryArgs} from "../util/parseQueryArgs";
import {Navigate} from "react-router-dom";
import {isUserSubscriptionActive} from "../util/isUserSubscriptionActive";
import NavigateToLogin from "../components/NavigateToLogin";
import DarkThemePaper from "../template/DarkThemePaper";
import {useConfirmSubscriptionMutation, useGetSessionQuery} from "../store/slice/myContentDashAdminApi";
import {useHeadScript} from "../hooks/useHeadScript";

const BillingConfirm = () => {
    useHeadScript('/js/affiliateHit.js');
    useHeadScript('https://cdn.firstpromoter.com/fpr.js');
    usePageTitle("Billing");
    const [error, setError] = useState<string | null>(null);
    const params = parseQueryArgs(useAppSelector(state => state.router.location.search))
    const {data: session} = useGetSessionQuery();
    const [confirmSubscription, {isLoading}] = useConfirmSubscriptionMutation();
    const user = session.user;

    useEffect(() => {
        (async () => {
            if (!params.id || !params.state || !user) {
                return;
            }
            try {
                const wasPreviouslyActive = isUserSubscriptionActive(user);
                const response = await confirmSubscription(params.id).unwrap();
                if (response.subscription_status === "active") {
                    if (!wasPreviouslyActive) {
                        // @ts-ignore
                        window.fpr("referral", {email: user.email_address, uid: response.customer_id});
                    }
                } else {
                    setError("An error occurred during the billing process, you may need to contact MCD support.");
                }
            } catch (error) {
                console.error(error);
                setError(error.data.message);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!user) {
        return <NavigateToLogin/>
    }
    if (!error && !isLoading && isUserSubscriptionActive(user)) {
        return <Navigate to={"/report-setup"}/>
    }
    if (!params.id || !params.state) {
        return <Navigate to={"/billing"}/>
    }

    return <DarkThemePaper title={"Billing"}>
        <Box
            width={"300px"}
            py={3}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <CircularProgress/>
            <Typography
                sx={{ml: 2}}
                variant={'h4'}
            >
                Finishing up...
            </Typography>
        </Box>
        <Collapse in={error !== null}>
            <Typography color={'error'} sx={{mt: 2}}>{error}</Typography>
        </Collapse>
    </DarkThemePaper>
}

export default BillingConfirm;