import {useMatch} from "react-router-dom";
import {
    useGetReportQuery,
    useGetReportsQuery,
    useGetSavedUserFiltersQuery,
    useGetSessionQuery
} from "../store/slice/myContentDashAdminApi";
import FullPageSpinner from "../components/FullPageSpinner";
import React from "react";
import ReportNotFound from "../components/report/ReportNotFound";
import ReportContext from "../context/ReportContext";
import Report from "../components/report/Report";
import NavigateToLogin from "../components/NavigateToLogin";

const ReportContainer = () => {
    const {params} = useMatch<"domain">("report/:domain/*");
    const {data: session} = useGetSessionQuery();
    const reportDomain = params.domain;
    const {
        data: allReportsResponse,
        isLoading: isLoadingAllReports
    } = useGetReportsQuery(undefined, {skip: !session.user});
    const noReportFound = allReportsResponse !== undefined && !allReportsResponse.reports.some(report => report.domain === reportDomain);

    const reportId = allReportsResponse ? allReportsResponse.reports.find(report => report.domain === reportDomain)?.id : undefined;

    const {data: reportResponse, isLoading} = useGetReportQuery(
        allReportsResponse ? allReportsResponse.reports.find(report => report.domain === reportDomain)?.id : undefined,
        {skip: isLoadingAllReports || noReportFound || !session.user}
    );
    const {data: savedUserFilters} = useGetSavedUserFiltersQuery({reportId}, {skip: reportId === undefined});

    if (!session.user) {
        return <NavigateToLogin/>;
    }

    if (noReportFound) {
        return <ReportNotFound reportDomain={reportDomain}/>
    }

    if (isLoadingAllReports || isLoading || !savedUserFilters) {
        return <FullPageSpinner/>;
    }

    return <ReportContext.Provider value={{report: reportResponse.report}}>
        <Report/>
    </ReportContext.Provider>
}

export default ReportContainer;