import * as React from 'react';
import IntegrationCard from "./IntegrationCard";
import {Box} from "@mui/material";
import GoogleAnalyticsConfiguration from "./GoogleAnalyticsConfiguration";
import {GoogleIntegrationSetup} from "../../../../types/GoogleIntegration";
import ConnectGoogleButton from "./ConnectGoogleButton";
import ResetGoogleButton from "../../../ResetGoogleButton";

import {useContext} from "react";
import ReportContext from "../../../../context/ReportContext";

const googleAnalyticsLogo = require("../../../../../image/google_analytics_banner_logo.png").default;

const GoogleAnalyticsIntegrationCard = () => {
    const {report} = useContext(ReportContext);

    return <IntegrationCard
        isConnected={report.integrations.google.is_connected &&
            ((report.integrations.google.analytics_type === "UA" && report.integrations.google.analytics.view !== null) ||
                (report.integrations.google.analytics_type === "GA4" && report.integrations.google.analytics4.property !== null))}
        header={<img src={googleAnalyticsLogo} alt={"Google Analytics Banner Logo"} height={"100%"}
                     style={{height: "100%"}}/>}
    >
        {report.integrations.google.is_connected && report.integrations.google.scopes.includes("analytics.readonly") ?
            <>
                <GoogleAnalyticsConfiguration
                    report={report}
                    googleIntegration={report.integrations.google as GoogleIntegrationSetup}
                />
                <Box display={"flex"} justifyContent={"center"}>
                    <ResetGoogleButton resetFor={"analytics"} variant={"contained"} disabled={report.integrations.google.lock_configuration}/>
                </Box>
            </>
            : <ConnectGoogleButton/>
        }
    </IntegrationCard>
}

export default GoogleAnalyticsIntegrationCard;