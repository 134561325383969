import React, {ForwardedRef, forwardRef} from "react";
import {Card, CardContent, CardHeader, Link, Typography} from "@mui/material";
import ReportTodoItem from "../../types/ReportTodoItem";
import extractPathFromUrl from "../../util/extractPathFromUrl";
import TodoListItem from "./TodoListItem";
import {useAppSelector} from "../../store/store";

export type TodoPage = {
    pageUrl: string;
    todoItems: ReportTodoItem[];
}

type TodoPageItemProps = {
    todoPage: TodoPage;
}

const TodoPageItem = forwardRef(({todoPage}: TodoPageItemProps, ref: ForwardedRef<HTMLDivElement>) => {
    const userSearch = useAppSelector(state => state.todoPanel.search);
    const todos = todoPage.todoItems.filter(todo =>
        userSearch === '' || todo.text.toLowerCase().includes(userSearch.toLowerCase())
    );

    return <Card ref={ref} variant={"outlined"} sx={{my: 0.5}} elevation={4}>
        <CardHeader
            title={todoPage.pageUrl ?
            <Link variant={'h5'} href={todoPage.pageUrl} target={"_blank"}>
                {extractPathFromUrl(todoPage.pageUrl)}
            </Link> : <Typography variant={'h5'}>General</Typography>
            }
        />
        <CardContent sx={{pt: 0}}>
            {(todos.length === 0 ? todoPage.todoItems : todos).map(todoItem => (
                <TodoListItem todo={todoItem} key={todoItem.id}/>
            ))}
        </CardContent>
    </Card>
});

export default TodoPageItem;