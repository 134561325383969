import Table from "../../types/Table";
import {styled} from "@mui/material/styles";
import React from "react";
import {Typography} from "@mui/material";

type DisabledReportTableMessageProps = {
    table: Table;
}

const DisabledReportTableMessageRoot = styled('div')`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  background: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DisabledReportTableMessage = ({table}: DisabledReportTableMessageProps) => {
    return <DisabledReportTableMessageRoot>
        <Typography variant={'h2'}>{table.disabled_message}</Typography>
    </DisabledReportTableMessageRoot>
}

export default DisabledReportTableMessage;