import {MutableRefObject, useState} from "react";
import useEffectWhen from "../../util/useEffectWhen";
import ReportsApi from "../../api/ReportsApi";
import {FilterData, ServerFormattedFeaturedFilterInput} from "./useTableFilters";
import {SortByData} from "./useReportTable";
import Table from "../../types/Table";
import useSearchBarValue from "../../hooks/useSearchBarValue";

export type Export = {
    is_complete: boolean,
    export_id: number,
    total_rows: number,
    page: number,
    total_pages: number,
    rows_exported: number
}

export type UseTableExportProps = {
    reportId: number,
    table: Table,
    onError: (errorMessage: string) => void,
    onExportCompleted: (downloadExport: () => Promise<void>, exportJob: Export) => void,
    ref: MutableRefObject<any>,
    advancedFilterData?: FilterData,
    sortBy?: SortByData,
    featuredFilterInput?: ServerFormattedFeaturedFilterInput[]
}

export const useTableExport = ({
                                   reportId,
                                   table,
                                   onError,
                                   advancedFilterData = [],
                                   sortBy = [],
                                   featuredFilterInput = [],
                                   onExportCompleted,
                                   ref
                               }: UseTableExportProps) => {
    const [{isExportInProgress, activeExport}, setActiveExportData] = useState<{
        isExportInProgress: boolean,
        activeExport: null | Export
    }>({
        isExportInProgress: false,
        activeExport: null
    });
    const [inheritFilters, setInheritFilters] = useState(true);
    const [inheritSortOptions, setInheritSortOptions] = useState(true);
    const searchBarValue = useSearchBarValue();

    const tableFilterData = table && table.filter_preset ? [table.filter_preset.filter_data] : null;

    const cancelExport = () => {
        setActiveExportData({
            isExportInProgress: false,
            activeExport: null
        })
        ref.current.isExportingRows = false;
        ref.current.cancelled = true;
        if (ref.current.abortController) {
            ref.current.abortController.abort();
        }
    }

    useEffectWhen(() => {
        if (ref.current.cancelled === true || ref.current.isExportingRows || !activeExport) {
            return;
        }
        (async () => {
            if (activeExport !== null && activeExport.is_complete) {
                cancelExport();
                onExportCompleted(downloadExport, activeExport);
                return;
            }

            ref.current.isExportingRows = true;
            ref.current.abortController = new AbortController();
            try {
                const nextExportData = await ReportsApi.runExport({
                    reportId: reportId,
                    tableId: table.id,

                    filterData: inheritFilters ? advancedFilterData : tableFilterData,
                    sortByData: inheritSortOptions ? sortBy : {},
                    search: inheritFilters ? {
                        search_term: searchBarValue.searchTerm,
                        column_ids: searchBarValue.columnIds
                    } : {},
                    exportId: activeExport!.export_id,
                    featuredFilterOptions: inheritFilters ? featuredFilterInput : [],
                    abortController: ref.current.abortController
                });
                ref.current.isExportingRows = false;
                setActiveExportData({
                    isExportInProgress: true,
                    activeExport: nextExportData
                })
            } catch (error) {
                if (error.name !== "AbortError") {
                    cancelExport();
                }
            }
        })();
    }, [activeExport]);

    const startExport = async (inheritFilters: boolean, inheritSortOptions: boolean) => {
        ref.current.cancelled = false;
        setActiveExportData({
            isExportInProgress: true,
            activeExport: null
        });
        setInheritFilters(inheritFilters);
        setInheritSortOptions(inheritSortOptions);
        try {
            ref.current.isExportingRows = true;
            const exportData = await ReportsApi.runExport({
                reportId: reportId,
                tableId: table.id,
                filterData: inheritFilters ? advancedFilterData : tableFilterData,
                sortByData: inheritSortOptions ? sortBy : {},
                search: inheritFilters ? {
                    search_term: searchBarValue.searchTerm,
                    column_ids: searchBarValue.columnIds
                } : {},
                featuredFilterOptions: inheritFilters ? featuredFilterInput : []
            });
            ref.current.isExportingRows = false;
            setActiveExportData({
                isExportInProgress: true,
                activeExport: exportData
            })
        } catch (error) {
            onError(error.message);
            setActiveExportData({
                isExportInProgress: false,
                activeExport: null
            });
        }
    }

    const downloadExport = async () => {
        if (activeExport === null) {
            return;
        }
        await ReportsApi.downloadExport(activeExport.export_id);
        cancelExport();
    }

    return {
        isExportInProgress,
        startExport,
        activeExport,
        cancelExport
    }
}