import React, {useRef, useState} from "react";
import {Button} from "@mui/material";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import HiddenColumnList from "./HiddenColumnList";

const HiddenColumnSelector = ({columns, onChange, displayedColumnIds, ...props}) => {
    const [open, setOpen] = useState(false);
    const buttonRef = useRef();

    return <>
        {columns.length > 0 ?
        <><Button
            color={"secondary"}
            onClick={() => setOpen(true)}
            {...props}
            ref={buttonRef}
            data-test={"select-columns"}
        >Select Columns</Button>
        <Popover
            open={open}
            onClose={() => setOpen(false)}
            anchorEl={buttonRef.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Box p={2} data-test={"select-columns-modal"}>
                <HiddenColumnList
                    columns={columns}
                    onChange={onChange}
                    displayedColumnIds={displayedColumnIds}
                />
            </Box>
        </Popover></>: null}
    </>;
}

export default HiddenColumnSelector;