import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {Box, CircularProgress, Collapse, LinearProgress, MenuItem, TextField, Typography} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import {useTableExport} from "../../../table/useTableExport";
import {LoadingButton} from "@mui/lab";
import ReportContext from "../../../../context/ReportContext";

const ExportSettingsPage = () => {
    const {report} = useContext(ReportContext)
    const tables = report.tables.filter(table => table.is_export_table);
    const [selectedTable, setSelectedTable] = useState<number>(tables.length > 0 ? tables[0].id : 0);
    const [error, setError] = useState<string | null>(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const ref = useRef();

    const {
        isExportInProgress,
        startExport,
        activeExport
    } = useTableExport({
        ref,
        table: tables.find(table => table.id === selectedTable),
        reportId: report.id,
        onError: (error) => setError(error),
        onExportCompleted: async (downloadExport) => {
            setIsDownloading(true);
            await downloadExport();
            setIsDownloading(false);
        }
    });

    const currentProgress = isDownloading ?
        100 :
        activeExport ?
            Math.min(activeExport.rows_exported / activeExport.total_rows * 100)
            : 0;

    useEffect(() => {
        if (isExportInProgress) {
            setError(null);
            setIsDownloading(false);
        }
    }, [isExportInProgress]);

    return <div ref={ref}>
        <Typography>You can download reports from your site audit as a CSV using the button below.</Typography>
        <Box
            sx={{mt: 2}}
            display={'flex'}
            justifyContent={'space-between'}
            width={'100%'}
            alignItems={'center'}
            maxWidth={'500px'}
        >
            <TextField
                select
                label={"Report"}
                value={selectedTable}
                onChange={event => setSelectedTable(parseInt(event.target.value))}
                sx={{flex: 1}}
                disabled={isExportInProgress || isDownloading || tables.length === 0}
            >
                {tables.length === 0 ? <MenuItem value={0}>None Available</MenuItem> : null}
                {tables.map(table => (
                    <MenuItem key={table.id} value={table.id}>{table.label}</MenuItem>
                ))}
            </TextField>
            <Box ml={1}>
                <LoadingButton
                    variant={"contained"}
                    endIcon={<DownloadIcon/>}
                    onClick={() => startExport(false, false)}
                    loading={isExportInProgress || isDownloading}
                    disabled={tables.length === 0}
                    loadingIndicator={<>
                        {isDownloading ? "Downloading..." : "Exporting..."}
                        <CircularProgress color="inherit" size={16} sx={{ml: 1, mt: 0.5}}/>
                    </>}
                >Download CSV</LoadingButton>
                <Box
                    visibility={isExportInProgress || isDownloading ? "visible" : "hidden"}
                >
                    <LinearProgress
                        variant={"determinate"}
                        value={currentProgress}
                        sx={{mt: 1.5}}
                    />
                </Box>
            </Box>
        </Box>
        <Collapse in={error !== null}>
            <Typography sx={{mt: 1}} color={'error'}>{error}</Typography>
        </Collapse>
    </div>
}

export default ExportSettingsPage;