import * as React from 'react';
import {
    Box,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField, Typography
} from "@mui/material";
import {useContext, useState} from "react";
import {useAppDispatch} from "../../../../store/store";
import {LoadingButton} from "@mui/lab";

import ReportContext from "../../../../context/ReportContext";
import {useInviteUserToReportMutation} from "../../../../store/slice/myContentDashAdminApi";

type InviteUserDialogProps = {
    open: boolean;
    onClose: () => void;
}

const InviteUserDialog = ({open, onClose}: InviteUserDialogProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState<null | string>(null);
    useAppDispatch();
    const {report} = useContext(ReportContext);
    const [inviteUserToReport] = useInviteUserToReportMutation();

    const closeDialog = () => {
        onClose();
        setError(null);
        setName("");
        setEmail("");
    }

    const inviteUser = async () => {
        setIsLoading(true);
        setError(null);

        try {
            await inviteUserToReport({name, email, reportId: report.id}).unwrap();
        } catch(error) {
            console.error(error);
            setError(error.data.message as string);
            setIsLoading(false);
            return;
        }

        closeDialog();
        setName("");
        setEmail("");
        setIsLoading(false);
    }

    return <Dialog
        open={open}
        onClose={closeDialog}
        maxWidth={'xs'}
        fullWidth
    >
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent>
            <DialogContentText sx={{mb: 2}}>
                You can share access to your report with members of your team.
            </DialogContentText>
            <Box display={'flex'} justifyContent={'space-between'}>
                <TextField
                    label={"Name"}
                    value={name}
                    onChange={event => setName(event.target.value)}
                    fullWidth
                    sx={{mr: 1}}
                />
                <TextField
                    label={"Email"}
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    fullWidth
                    sx={{ml: 1}}
                />
            </Box>
            <Collapse in={error !== null}>
                <Typography color={'error'} sx={{mt: 1}}>{error}</Typography>
            </Collapse>
        </DialogContent>
        <DialogActions>
            <LoadingButton
                variant={'contained'}
                disabled={email === "" || name === ""}
                onClick={inviteUser}
                loading={isLoading}
            >Send Invite</LoadingButton>
        </DialogActions>
    </Dialog>
}

export default InviteUserDialog;