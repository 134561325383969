import {useGetSettingQuery} from "../store/slice/myContentDashAdminApi";
import {useEffect} from "react";

const useBeacon = () => {
    const {data: beaconId, error} = useGetSettingQuery("hjr_helpscout_beacon_id", {skip: window.Beacon === undefined});

    useEffect(() => {
        window.Beacon('config', {
            display: {
                position: "left"
            }
        })

        const info = window.Beacon('info', undefined);
        if(info === undefined && Boolean(beaconId)) {
            window.Beacon("init", beaconId);
        }
    }, [beaconId]);

    useEffect(() => {
        if(error) {
            console.error("An error occurred trying to retrieve the HelpScout Beacon ID setting!", error);
        }
    }, [error]);
}

export default useBeacon;