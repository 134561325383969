import React from "react";
import {Navigate, useMatch} from "react-router-dom";
import {
    useGenerateSubscriptionCheckoutUrlMutation,
    useGetPlanQuery,
    useGetSessionQuery
} from "../store/slice/myContentDashAdminApi";
import DarkThemePaper from "../template/DarkThemePaper";
import {Box, Grid, List, ListItem, Paper, Typography} from "@mui/material";
import FullPageSpinner from "../components/FullPageSpinner";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {LoadingButton} from "@mui/lab";

const BillingRequiredPromoPlan = () => {
    const {params} = useMatch<"plan">("start/:plan");
    const {data: session} = useGetSessionQuery();
    const [getBillingUrl, {isLoading: isLoadingCheckoutUrl}] = useGenerateSubscriptionCheckoutUrlMutation();
    const {
        data: planResponse,
        isLoading,
        error
    } = useGetPlanQuery({planId: params.plan}, {skip: session.user === null});

    if (isLoading) {
        return <FullPageSpinner/>;
    }

    if ((error as FetchBaseQueryError)?.status === 404) {
        return <Navigate to={"/billing"}/>;
    }

    if (!session.user) {
        return <Navigate to={"/signup?redirectTo=" + encodeURIComponent("/start/" + params.plan)}/>;
    }

    const navigateToBilling = async () => {
        const billingUrl = await getBillingUrl({period: "month", plan: params.plan}).unwrap();
        window.location.href = billingUrl;
    }

    return <DarkThemePaper title={"Billing"}>
        <Box maxWidth={"600px"}>
            <Typography variant={'h5'} sx={{mb: 1}}>Hi {session.user.first_name}, welcome to MyContentDash.</Typography>
            <Typography sx={{mb: 1}}>
                We are pleased to offer you a free month of the MyContentDash tool, with access to our Content
                Audit
                page with Advanced Filtering Tools to provide detailed insights into the content of your site.
            </Typography>
            <Typography sx={{mb: 1}}>
                At the end of your month, you will be automatically upgraded to the full version of
                MyContentDash with access to all of our content improving features
                for ${planResponse?.plan.upgrade_price}/month.
            </Typography>
            <Typography sx={{mb: 1}}>
                You may cancel at any time during the month to avoid this upgrade.
            </Typography>
            <Typography sx={{mb: 1}}>
                Click the button below to get started.
            </Typography>
            <Box display={'flex'} justifyContent={'center'} sx={{my: 2}}>
                <LoadingButton
                    variant={'contained'}
                    color={'primary'}
                    size={'large'}
                    loading={isLoadingCheckoutUrl}
                    onClick={navigateToBilling}
                >Activate Free Month</LoadingButton>
            </Box>
            <Grid container spacing={2} sx={{mt: 2, pb: 4}}>
                <Grid item xs={6} alignItems={'stretch'}>
                    <Paper sx={{p: 2, height: '100%'}} elevation={4}>
                        <Typography variant={'h4'} textAlign={'center'}>Free Month Features</Typography>
                        <FeaturesList features={[
                            'Content Analysis',
                            'Advanced Filtering Tools',
                            'Automatic Site Data Updates'
                        ]}/>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Paper sx={{p: 2, height: '100%'}} elevation={4}>
                        <Typography variant={'h4'} textAlign={'center'}>Full Feature List After Month</Typography>
                        <FeaturesList features={[
                            'Content Analysis',
                            'Advanced Filtering Tools',
                            'Automatic Site Data Updates',
                            'Keyword Ranking Analytics',
                            'Missing Keyword Analytics',
                            'Low Hanging Improvement Opportunities',
                        ]}/>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    </DarkThemePaper>
}

const FeaturesList = ({features}: { features: string[] }) => (
    <List sx={{listStyleType: 'disc', pl: 4}}>
        {features.map(feature => <ListItem sx={{display: 'list-item', py: 0.5}} key={feature}>{feature}</ListItem>)}
    </List>
);

export default BillingRequiredPromoPlan;