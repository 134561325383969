import * as React from 'react';
import {FC, useContext, useRef} from 'react';
import {HeaderTableCell, PresetFilterOptionsProps} from "./PresetFilterOptions";
import {Table, TableBody, TableContainer, TableHead, TableRow} from "@mui/material";
import PresetFilterOptionsRadioRow from "./PresetFilterOptionsRadioRow";
import {AppIcon} from "hashtagjeff-shared-components";
import FilterIcon from "@mui/icons-material/FilterList";
import ReportTableContext from "../../../context/ReportTableContext";
import {useGetFilterOptionCountsQuery} from "../../../store/slice/myContentDashAdminApi";

const PresetFilterOptionsRadio: FC<PresetFilterOptionsProps> = ({
                                                                    value,
                                                                    onChange,
                                                                    featuredFilter
                                                                }) => {
    const {getFeaturedFilterCountParams} = useContext(ReportTableContext);
    const {data: featuredFilterCounts} = useGetFilterOptionCountsQuery(getFeaturedFilterCountParams(featuredFilter));
    const ref = useRef<HTMLTableElement & { filterOptionsCountAbortController?: AbortController }>();

    return <TableContainer>
        <Table size={"small"} ref={ref}>
            <TableHead>
                <TableRow>
                    <HeaderTableCell>
                        <AppIcon iconData={featuredFilter.start_icon} defaultIcon={FilterIcon}
                                 className={"valign-icon"}/> {featuredFilter.label}
                    </HeaderTableCell>
                    <HeaderTableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {featuredFilter.filter_options.map((option) => (
                    <PresetFilterOptionsRadioRow
                        key={option.id}
                        isActive={value.indexOf(option.id) !== -1}
                        filterOption={option}
                        optionCount={featuredFilterCounts.find(({optionId}) => optionId === option.id)}
                        onActivate={() => onChange([option.id])}
                        onToggleOff={() => onChange([])}
                    />
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}

export default PresetFilterOptionsRadio