import {combineReducers, configureStore, Middleware, PayloadAction} from '@reduxjs/toolkit';
import reportsReducer, {updateReportRoutePath} from "./slice/reportSlice";
import appReducer from "./slice/appSlice"
import filtersReducer from "./slice/filtersSlice"
import todoPanelReducer from "./slice/todoPanelSlice"
import {createBrowserHistory} from 'history'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {createReduxHistoryContext, LOCATION_CHANGE} from "redux-first-history";
import thunk, {ThunkMiddleware} from "redux-thunk";
import ReactGA from "react-ga";
import {User} from "../types/User";
import {myContentDashAdminApi} from "./slice/myContentDashAdminApi";

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({history: createBrowserHistory()});

const rootReducer = combineReducers({
    app: appReducer,
    router: routerReducer,
    report: reportsReducer,
    filters: filtersReducer,
    todoPanel: todoPanelReducer,
    [myContentDashAdminApi.reducerPath]: myContentDashAdminApi.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export const updateTitleMiddleware: Middleware<{}, RootState> = () => next => action => {
    if (action.type === "app/updatePageTitle") {
        document.title = action.payload + " - MyContentDash";
    }
    return next(action);
}

export const updateGACustomDimensionsMiddleware: Middleware<{}, RootState> = () => next => (action: PayloadAction<User>) => {
    if (action.type === "app/setUser" && action.payload !== null) {
        ReactGA.set({dimension2: action.payload.email_address});
    }

    return next(action);
}

export const updateLocationStateMiddleware: Middleware<{}, RootState> = (state) => next => action => {
    if (action.type === LOCATION_CHANGE) {
        const location = action.payload.location;
        const res = next(action);
        state.dispatch(updateReportRoutePath(location.pathname));
        return res;
    }
    return next(action);
}

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => (
        getDefaultMiddleware()
            .prepend(
                updateTitleMiddleware,
                updateLocationStateMiddleware,
                updateGACustomDimensionsMiddleware,
                routerMiddleware as Middleware<{}, RootState>,
                thunk as ThunkMiddleware,
                myContentDashAdminApi.middleware
            )
    )
});
export const history = createReduxHistory(store);
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export default store;