import {createContext} from "react";
import PostTabPageValue from "../types/PostTabPageValue";
import PostTabPage from "../types/PostTabPage";

const PostTabPageContext = createContext<{
    fieldValues: PostTabPageValue[],
    postTabPage: PostTabPage
}>({
    fieldValues: [],
    postTabPage: undefined
});

export default PostTabPageContext;
