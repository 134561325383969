import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {styled, Typography} from "@mui/material";

const AuthPaperHeader = styled(Paper)`
  position: relative;
  width: 70%;
  left: 15%;
  top: -20px;
  border-radius: 4px;
  background-color: #ee7d72;
  color: white;
  padding: ${({theme}) => theme.spacing(2, 3)};
  box-sizing: border-box;
`

const AuthPaper = ({title, children}) => {

    return <Grid container spacing={0} direction={"column"} alignItems="center">
        <Grid item xs={3} sx={{maxWidth: "450px"}}>
            <Paper
                elevation={3}
                sx={{
                    mt: "36px",
                    p: 3,
                    pt: 0,
                    boxSizing: "border-box"
                }}
            >
                <AuthPaperHeader elevation={1}>
                    <Typography
                        variant={"h6"}
                        textAlign={"center"}
                        fontWeight={300}
                        fontSize={"1.3rem"}
                    >{title}</Typography>
                </AuthPaperHeader>
                {children}
            </Paper>
        </Grid>
    </Grid>;
}

AuthPaper.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
};

export default AuthPaper;