import React from "react";
import {Paper} from "@mui/material";
import TrackedPagesTable from "./TrackedPagesTable";

const TrackedPages = () => {
    return <>
        <Paper sx={{p: 2}}>
            <TrackedPagesTable/>
        </Paper>
    </>
}

export default TrackedPages;