import * as React from "react";
import {Navigate} from "react-router-dom";
import {Box} from "@mui/material";
import {usePageTitle} from "../hooks/usePageTitle";
import {isUserSubscriptionActive} from "../util/isUserSubscriptionActive";
import NavigateToLogin from "../components/NavigateToLogin";
import PlanPicker from "../components/billing-required/PlanPicker";
import DarkThemePaper from "../template/DarkThemePaper";
import {useGetReportsQuery, useGetSessionQuery} from "../store/slice/myContentDashAdminApi";
import {useHeadScript} from "../hooks/useHeadScript";

const BillingRequired = () => {
    useHeadScript('/js/affiliateHit.js');
    useHeadScript('https://cdn.firstpromoter.com/fpr.js');
    usePageTitle("Billing");
    const {data: session} = useGetSessionQuery();
    const user = session.user;
    const {data: reportsList} = useGetReportsQuery();

    if (!user) {
        return <NavigateToLogin/>
    }

    if (reportsList.reports.length === 0 && isUserSubscriptionActive(user)) {
        return <Navigate to={"/report-setup"}/>
    }

    if (isUserSubscriptionActive(user)) {
        return <Navigate to={"/reports"}/>
    }

    return <DarkThemePaper title={"Billing"}>
        <Box maxWidth={"400px"}>
            <PlanPicker/>
        </Box>
    </DarkThemePaper>
}

export default BillingRequired;