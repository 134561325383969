import React, {useContext, useState} from "react";
import {
    Box,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, TextField,
    Typography
} from "@mui/material";
import {useGetNotesQuery} from "../../store/slice/myContentDashAdminApi";
import ReportContext from "../../context/ReportContext";
import NoteTableRow from "./NoteTableRow";
import Note from "../../types/Note";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";


type AllPageNotesPopoverProps = {
    open: boolean;
    onClose: () => void;
    anchorEl: HTMLElement;
}

const noteContains = (note: Note, search: string) => {
    return note.page_url.includes(search) || note.text.includes(search);
}

const AllPageNotesPopover = ({open, onClose, anchorEl}: AllPageNotesPopoverProps) => {
    const {report} = useContext(ReportContext);
    const {data: notesResponse} = useGetNotesQuery({reportId: report.id});
    const [pagination, setPagination] = useState({
        page: 0,
        search: ""
    });
    const [expandedNote, setExpandedNote] = useState<null | number>(null);

    const doClose = () => {
        onClose();
        setExpandedNote(null);
    }

    const setSearch = (search: string) => {
        setPagination({
            ...pagination,
            page: 0,
            search
        });
    }

    const sortedNotes = notesResponse?.notes ? [...notesResponse.notes].filter(note => pagination.search === '' || noteContains(note, pagination.search)) : [];
    sortedNotes.sort((a, b) => b.added_on - a.added_on);
    const displayNotes = sortedNotes.slice(pagination.page * 8, pagination.page * 8 + 8);

    const paginationElement = <TablePagination
        count={notesResponse?.notes?.length || 0}
        page={pagination.page}
        rowsPerPage={8}
        onPageChange={(event, newPage) => setPagination({...pagination, page: newPage})}
        component={'div'}
        rowsPerPageOptions={[8]}
    />

    return <Popover
        open={open}
        onClose={doClose}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
        PaperProps={{sx: {width: '800px', p: 2}, elevation: 12}}
    >
        <Typography variant={'h3'} textAlign={'center'}>Notes</Typography>
        <TableContainer>
            <Box display={'flex'} justifyContent={'space-between'} my={1}>
                <TextField
                    label={"Search..."}
                    sx={{flex: 1}}
                    value={pagination.search}
                    onChange={event => setSearch(event.target.value)}
                />
                {paginationElement}
            </Box>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell>Post</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayNotes?.map(note => (
                        <NoteTableRow
                            note={note}
                            key={note.id}
                            isExpanded={expandedNote === note.id}
                            setIsExpanded={(isExpanded) => setExpandedNote(isExpanded ? note.id : null)}
                            onUpdated={() => setPagination({...pagination, page: 0})}
                        />
                    ))}
                    {notesResponse !== undefined && notesResponse.notes.length === 0 ?
                        <TableRow>
                            <TableCell colSpan={2} sx={{py: 4}}>
                                <Typography variant={'h4'} textAlign={'center'}>
                                    You haven't saved any notes yet.
                                </Typography>
                                <Typography variant={'h5'} component={'p'} textAlign={'center'}>
                                    Click the <StickyNote2Icon fontSize={'small'}/> icon in a table to get started.
                                </Typography>
                            </TableCell>
                        </TableRow> : null
                    }
                </TableBody>
            </Table>
            {paginationElement}
        </TableContainer>
    </Popover>

}

export default AllPageNotesPopover;