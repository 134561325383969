import {Box, IconButton, TableCell, Tooltip} from "@mui/material";
import isSearchConsoleEnabled from "../../util/isSearchConsoleEnabled";
import {isSearchConsoleConfigured} from "../../util/isSearchConsoleConfigured";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import TrackPagePerformancePopover from "../page-performance-tracker/TrackPagePerformancePopover";
import React, {useContext, useRef, useState} from "react";
import ReportContext from "../../context/ReportContext";
import Table from "../../types/Table";
import Column from "../../types/Column";
import {Row} from "./useReportTable";
import PageNoteActionButton from "../page-notes/PageNoteActionButton";
import AddTodoActionButton from "../todo-items/AddTodoActionButton";
import {useAppDispatch} from "../../store/store";
import {openGscNotConnectedDialog} from "../../store/slice/appSlice";

type ReportTableRowActionsCellProps = {
    table: Table;
    row: Row;
    columns: Column[]
    onRankingsClick: (row: Row, columns: Column[]) => void;
}

const ReportTableRowActionsCell = ({table, row, columns, onRankingsClick}: ReportTableRowActionsCellProps) => {
    const {report} = useContext(ReportContext);
    const [trackPopoverOpen, setTrackPopoverOpen] = useState(false);
    const trackPageButtonRef = useRef();
    const pageUrl = row["field_" + table.url_field] as string | undefined;
    const dispatch = useAppDispatch();

    const maybeOpenRankings = (row: Row, columns: Column[]) => {
        console.log("Opening modal");
        if (!isSearchConsoleConfigured(report)) {
            console.log("Opening modal");
            dispatch(openGscNotConnectedDialog())
        } else {
            onRankingsClick(row, columns)
        }
    }

    return <TableCell>
        <Box display={'flex'}>
            {report.enable_todo && pageUrl ?
                <Tooltip title={"Add todo item"}>
                    <AddTodoActionButton
                        size={'small'}
                        pageUrl={pageUrl}
                    />
                </Tooltip> : null
            }
            {report.enable_notes && pageUrl ?
                <Tooltip title={"Add note"}>
                    <PageNoteActionButton
                        size={'small'}
                        pageUrl={pageUrl}
                        />
                </Tooltip> : null
            }
            {isSearchConsoleEnabled(table) ?
                (<Tooltip title={"View Page Performance"}>
                    <IconButton size={'small'} onClick={() => maybeOpenRankings(row, columns)}>
                        <AssessmentIcon/>
                    </IconButton>
                </Tooltip>) : null}
            {report.enable_page_trackers && pageUrl ?
                (<Tooltip title={"Add Page to Tracker..."}>
                    <IconButton size={'small'} sx={{ml: 0}} ref={trackPageButtonRef}
                                onClick={() => setTrackPopoverOpen(true)}>
                        <TroubleshootIcon/>
                    </IconButton>
                </Tooltip>) : null}
            {pageUrl ?
                <TrackPagePerformancePopover
                    pageUrl={row["field_" + table.url_field] as string}
                    onClose={() => setTrackPopoverOpen(false)}
                    open={trackPopoverOpen}
                    anchorEl={trackPageButtonRef.current}
                /> : null}
        </Box>
    </TableCell>;
}

export default ReportTableRowActionsCell;