import React, {useContext} from "react";
import ReportTable from "../../components/table/ReportTable";
import ReportWpPage from "../../components/report/ReportWpPage";
import SearchBar from "../../components/report/SearchBar";
import Grid, {GridSize} from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {ReportPage as ReportPageType} from "../../types/ReportPage";
import ReportContext from "../../context/ReportContext";
import {Box} from "@mui/material";
import SavedUserFiltersButton from "../../components/report/saved_filters/SavedUserFiltersButton";

type ReportPageProps = {
    page: ReportPageType
}

const ReportPage = ({page}: ReportPageProps) => {
    const {report} = useContext(ReportContext);

    let currentTable;

    const getComponentXs = (tableDefinition) => {
        if (!tableDefinition.hasOwnProperty('xs'))
            return 12;

        return parseInt(tableDefinition.xs) as GridSize;
    }

    const getComponentType = tableDefinition => {
        if (!tableDefinition.hasOwnProperty('type'))
            return 'table';

        return tableDefinition.type;
    }

    const getComponent = tableDefinition => {
        let searchbarCount = 0;
        switch (getComponentType(tableDefinition)) {
            case 'wp_page':
                return <Grid item xs={getComponentXs(tableDefinition)} key={'wp_page_' + tableDefinition.id}>
                    <div key={'wp_page_' + tableDefinition.id} className="report-wp-page">
                        <ReportWpPage
                            wpPageId={tableDefinition.id}
                        />
                    </div>
                </Grid>;

            case 'searchbar':
                searchbarCount++;
                return <Grid item xs={getComponentXs(tableDefinition)} key={'searchbar_' + searchbarCount}>
                    <div key={'searchbar_' + searchbarCount} className="search-bar">
                        <SearchBar key={'searchbar_' + page.id}/>
                    </div>
                </Grid>;

            default:
                return <Grid item xs={getComponentXs(tableDefinition)} key={'table_' + tableDefinition.id}>
                    <div key={'table_' + tableDefinition.id} className="report-table">
                        <ReportTable
                            tableData={currentTable = report.tables.find(table => table.id === tableDefinition.id)}
                            dataSource={report.data_sources.find(dataSource => dataSource.id === currentTable.data_source)}
                            report={report}
                            label={tableDefinition.label}
                        />
                    </div>
                </Grid>;

        }
    }

    return <Paper sx={{p: 2}}>
        {page.table_data.some(table => table.type === 'table') &&
            <Box sx={{m: -2}} display={"flex"} justifyContent={"right"}>
                <SavedUserFiltersButton/>
            </Box>}
        <Grid container spacing={2}>
            {page.table_data.map(tableDefinition =>
                getComponent(tableDefinition)
            )}</Grid>
    </Paper>;
};

export default ReportPage;