import {myContentDashAdminApi, useGetRowsQuery} from "../store/slice/myContentDashAdminApi";
import {GetRowsParams} from "../api/ReportsApi";
import {useEffect, useMemo, useState} from "react";
import localforage from "localforage";
import {skipToken} from "@reduxjs/toolkit/query";
import {TableContentsResponse} from "../components/table/useReportTable";
import useEffectWhen from "../util/useEffectWhen";
import store from "../store/store";
import hash from "object-hash";

const useGetRowsQueryWithLocalstorage = ({cacheExpirationTime, requestParameters, skip}: {requestParameters: GetRowsParams, cacheExpirationTime: number, skip: boolean}) => {
    const [isCheckingCache, setIsCheckingCache] = useState(false);
    const [useCachedValue, setUseCachedValue] = useState(true);
    const [apiRequestParameters, setApiRequestParameters] = useState<typeof skipToken | GetRowsParams>(skipToken);
    const [cachedData, setCachedData] = useState<TableContentsResponse | undefined>(undefined);
    const {data: tableContentsResponse, isFetching: isRetrievingTableRows, error} = useGetRowsQuery(apiRequestParameters);
    const cacheKey = useMemo(() => hash(requestParameters, {unorderedObjects: true, unorderedArrays: true}), [requestParameters]);
    const [lastKnownRowCount, setLastKnownRowCount] = useState(50);

    useEffect(() => {
        if(useCachedValue && cachedData && cachedData.totalRows > 0) {
            setLastKnownRowCount(cachedData.totalRows);
        } else if(tableContentsResponse && tableContentsResponse.totalRows > 0) {
            setLastKnownRowCount(tableContentsResponse.totalRows);
        }
    }, [tableContentsResponse, cachedData, useCachedValue]);

    useEffect(() => {
        if(skip) {
            return;
        }

        // @ts-ignore
        const {status} = myContentDashAdminApi.endpoints.getRows.select(requestParameters)(store.getState());
        if(status === "fulfilled") {
            setUseCachedValue(false);
            setApiRequestParameters(requestParameters);
        }

        setIsCheckingCache(true);
        (async () => {
            let localStorageRows = JSON.parse(await localforage.getItem(cacheKey));
            const currentTime = new Date().getTime();
            const requiredInterval = 8.64e+7; // 24 hours in milliseconds

            let useCache = false;
            if (localStorageRows && (currentTime - localStorageRows.cache_date < requiredInterval)) {
                if ((localStorageRows.cache_date / 1000) < cacheExpirationTime) {
                    await localforage.removeItem(cacheKey);
                    console.log("Removed old cache item")
                } else {
                    useCache = true;
                }
            }

            if(useCache) {
                setCachedData(localStorageRows.response);
                setUseCachedValue(true);
            } else {
                setApiRequestParameters(requestParameters);
                setUseCachedValue(false);
            }

            setIsCheckingCache(false);
        })();
    }, [requestParameters, cacheExpirationTime, cacheKey, skip]);

    useEffectWhen(() => {
        if(useCachedValue || tableContentsResponse === undefined) {
            return;
        }
        const cacheKey = hash(apiRequestParameters, {unorderedObjects: true, unorderedArrays: true});

        localforage.setItem(cacheKey, JSON.stringify({
            response: tableContentsResponse,
            cache_date: new Date().getTime()
        }));
    }, [tableContentsResponse, apiRequestParameters, useCachedValue, cacheKey], [tableContentsResponse]);

    if(skip) {
        return {
            data: undefined,
            error: undefined,
            isLoading: false,
            lastKnownRowCount: 50
        }
    }

    return {
        data: useCachedValue ? cachedData : tableContentsResponse,
        error,
        isLoading: useCachedValue ? isCheckingCache : isRetrievingTableRows,
        lastKnownRowCount
    }
}

export default useGetRowsQueryWithLocalstorage;