import { useContext } from "react";
import {PageComponentProps} from "../PageComponent";
import PostTabPageContext from "../../../../context/PostTabPageContext";
import ReportContext from "../../../../context/ReportContext";
import {PageComponentField} from "../../../../types/PostTabPage";
import {Box, FormHelperText, Skeleton, Typography} from "@mui/material";
import React from "react";
import moment from "moment";

const formatTimestamp = timestamp => {
    return moment.unix(timestamp).format('MMMM Do YYYY, h:mm a');
};

const formatValue = (type, value) => {
    switch (type) {
        case 'float':
            return parseInt(value).toLocaleString();
        case 'date':
            return formatTimestamp(value);
        case 'string':
            return value;
    }
}

const PageComponentFieldValue = ({component}: PageComponentProps) => {
    const {fieldValues, postTabPage} = useContext(PostTabPageContext);
    const {report} = useContext(ReportContext);
    const dataSource = report.data_sources.find(dataSource => dataSource.id === postTabPage.data_source)
    const field = dataSource.fields.find(field => field.id === (component as PageComponentField).field);

    return <Box sx={{p: 2}}>
        <FormHelperText>{field.label}</FormHelperText>
        <Typography
            component={'p'}
        >
            {fieldValues === undefined ? <Skeleton/> : formatValue(field.type, fieldValues.find(fv => fv.field === field.id).value)}
        </Typography>
    </Box>
}

export default PageComponentFieldValue;