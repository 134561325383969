import * as React from 'react';
import {Box} from "@mui/material";
import {Navigate} from "react-router-dom";
import {isUserSubscriptionActive} from "../util/isUserSubscriptionActive";
import SetupReportOwner from "../components/setup-report/SetupReportOwner";
import DarkThemePaper from "../template/DarkThemePaper";
import {useGetReportsQuery, useGetSessionQuery} from "../store/slice/myContentDashAdminApi";

const SetupReport = () => {
    const {data: session} = useGetSessionQuery();
    const {data: reportsList} = useGetReportsQuery();
    const user = session.user;
    const reports = reportsList.reports;
    if (user === null || reports.length > 0) {
        return <Navigate to={"/"}/>
    }
    if (!isUserSubscriptionActive(user)) {
        return <Navigate to={"/billing"}/>
    }

    return <DarkThemePaper title={"Setup your Website"}>
        <Box maxWidth={"450px"}>
            <SetupReportOwner/>
        </Box>
    </DarkThemePaper>
}

export default SetupReport;