import './App.css';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import "fontsource-roboto";
import "./app/styles/css/app.css";
import "./app/styles/css/wp.css";
import FullPageSpinner from "./app/components/FullPageSpinner";
import ReactGA from "react-ga";
import ConnectWordPress from "./app/views/ConnectWordPress";
import Signup from "./app/views/Signup";
import BillingRequired from "./app/views/BillingRequired";
import SetupReport from "./app/views/SetupReport";
import BillingConfirm from "./app/views/BillingConfirm";
import Login from "./app/views/Login";
import SelectReport from "./app/views/SelectReport";
import ResetPassword from "./app/views/ResetPassword";
import SendPasswordReset from "./app/views/SendPasswordReset";
import AddNewReport from "./app/views/AddNewReport";
import GoToMyReportPage from "./app/views/GoToMyReportPage";
import {
    useGetReportsQuery,
    useGetSessionQuery,
    useGetSettingQuery,
    useLogOutMutation
} from "./app/store/slice/myContentDashAdminApi";
import useBeacon from "./app/hooks/useBeacon";
import ReportContainer from "./app/views/ReportContainer";
import AccountModal from "./app/components/account/AccountModal";
import {useAppDispatch, useAppSelector} from "./app/store/store";
import {closeAccountModal} from "./app/store/slice/appSlice";
import BillingRequiredPromoPlan from "./app/views/BillingRequiredPromoPlan";

const App = () => {
    const [, {isLoading: isLoggingOut}] = useLogOutMutation({fixedCacheKey: 'appLogout'});
    const {pathname} = useLocation();
    const {data: session, error: sessionError, isLoading: isRetrievingUser} = useGetSessionQuery();
    const {
        data: reportsList,
        error: reportsListError
    } = useGetReportsQuery(undefined, {skip: session === undefined || session.user === null});
    useBeacon();
    const {data: gaTrackingId} = useGetSettingQuery("hjr_ga_tracking_id");
    const checkedForUser = !isRetrievingUser && session !== undefined;
    const accountModalOpen = useAppSelector(state => state.app.accountModalOpen);
    const dispatch = useAppDispatch();

    useEffect(() => {
        ReactGA.pageview(pathname);
    }, [pathname]);

    useEffect(() => {
        if (gaTrackingId) {
            ReactGA.initialize(gaTrackingId);
        }
    }, [gaTrackingId]);
    if (!checkedForUser || isLoggingOut || (reportsList === undefined && session?.user)) {
        return <FullPageSpinner
            error={sessionError || reportsListError ? "An internal server error occurred!" : null}/>;
    }

    return (
        <>
            <Routes>
                <Route path={"report/:reportId/*"} element={<ReportContainer/>}/>
                <Route path={"myreport/*"} element={<GoToMyReportPage/>}/>
                <Route path={"connect"} element={<ConnectWordPress/>}/>
                <Route path={"signup"} element={<Signup/>}/>
                <Route path={"billing"} element={<BillingRequired/>}/>
                <Route path={"billing/confirm"} element={<BillingConfirm/>}/>
                <Route path={"start/:plan"} element={<BillingRequiredPromoPlan/>}/>
                <Route path={"report-setup"} element={<SetupReport/>}/>
                <Route path={"login"} element={<Login/>}/>
                <Route path={"reports"} element={<SelectReport/>}/>
                <Route path={"reports/add"} element={<AddNewReport/>}/>
                <Route path={"password-reset"} element={<ResetPassword/>}/>
                <Route path={"send-password-reset"} element={<SendPasswordReset/>}/>
                <Route path={"*"} element={<Navigate to={"/login"}/>}/>
            </Routes>
            {session && session.user ?
                <AccountModal open={accountModalOpen} onClose={() => dispatch(closeAccountModal())}/> : null}
        </>
    );
}
export default App;
