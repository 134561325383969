import * as React from 'react';
import {useAppSelector} from "../store/store";
import WordPressIcon from "../components/icon/WordPressIcon";
import {Box, Typography} from "@mui/material";
import ConnectWordPressContent from "../components/connect-wordpress/ConnectWordPressContent";
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import MyContentDashLogo from "../components/MyContentDashLogo";
import NavigateToLogin from "../components/NavigateToLogin";
import GetHelpButton from "../components/GetHelpButton";
import DarkThemePaper from "../template/DarkThemePaper";
import WebsiteAddonRequired from "../components/setup-report/WebsiteAddonRequired";
import {useGetSessionQuery} from "../store/slice/myContentDashAdminApi";

const ConnectWordPress = () => {
    const searchParams = useAppSelector(state => new URLSearchParams(state.router.location.search));
    const {data: session} = useGetSessionQuery();
    const user = session.user;

    if (!user) {
        return <NavigateToLogin/>
    }
    let hasValidParams = searchParams.has("code") && searchParams.has("website") && searchParams.has("redirect_url");
    if (hasValidParams) {
        if (searchParams.get("redirect_url").indexOf(searchParams.get("website")) !== 0) {
            hasValidParams = false;
        }
    }

    let error;
    if (!hasValidParams) {
        error = `Oops, it looks like something went wrong. Try going back and clicking the "Connect" button
                            in the WordPress plugin again.`;
    }

    let reports_connected = user.reports_connected;

    if (user.reports_website_urls_connected.indexOf(searchParams.get("website")) !== -1) {
        reports_connected--;
    }

    let content;

    if (error) {
        content = <>
            <Typography color={'error'}>
                {error}
            </Typography>
            <Box display={'flex'} justifyContent={'center'}>
                <GetHelpButton sx={{mt: 1}} variant={'outlined'}/>
            </Box>
        </>;
    } else if (reports_connected >= user.report_connection_limit) {
        content = <WebsiteAddonRequired/>
    } else {
        content = <ConnectWordPressContent
            redirectUrl={searchParams.get("redirect_url")}
            code={searchParams.get("code")}
            websiteUrl={searchParams.get("website")}
        />
    }

    return <DarkThemePaper title={"Connect to WordPress"}>
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} mb={2}>
            <WordPressIcon sx={{fontSize: "60px"}}/>
            <SettingsEthernetIcon
                fontSize={'large'}
                htmlColor={'gray'}
                sx={{mx: 2}}
            />
            <Box width={"64px"}>
                <MyContentDashLogo variant={'logoSquare'}/>
            </Box>
        </Box>
        <Box maxWidth={'500px'}>
            {content}
        </Box>
    </DarkThemePaper>
}

export default ConnectWordPress;