import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import {Box, Button, Collapse, TextField, TextFieldProps, Typography} from "@mui/material";
import {Link, Navigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import {isEmail} from "../util/isEmail";
import {usePageTitle} from "../hooks/usePageTitle";
import DarkThemePaper from "../template/DarkThemePaper";
import {useGetSessionQuery, useSignupMutation} from "../store/slice/myContentDashAdminApi";
import {useAppSelector} from "../store/store";
import {useHeadScript} from "../hooks/useHeadScript";
import {parseQueryArgs} from "../util/parseQueryArgs";

export type UserSignupForm = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

const validateParams = (userInfo: UserSignupForm): boolean => {
    if (Object.values(userInfo).some((value) => value.length < 1)) {
        return false;
    }
    return isEmail(userInfo.email);
}

const Signup = () => {
    useHeadScript('/js/affiliateHit.js');
    useHeadScript('https://cdn.firstpromoter.com/fpr.js');
    usePageTitle("Signup");
    const [error, setError] = useState<string | null>(null);
    const [isSigningUp, setIsSigningUp] = useState(false);
    const [isRedirectingToChargeBee, setRedirectingToChargeBee] = useState(false);
    const [userExists, setUserExists] = useState(false);
    const redirectTo = useAppSelector(state => parseQueryArgs(state.router.location.search).redirectTo);
    const [userInfo, setUserInfo] = useState<UserSignupForm>({
        firstName: "",
        lastName: "",
        email: "",
        password: ""
    })
    const [signup] = useSignupMutation();
    const {data: session} = useGetSessionQuery();
    const user = session.user;
    if (user && !isRedirectingToChargeBee) {
        if (redirectTo) {
            console.log("Redirecting to", redirectTo)
            return <Navigate to={redirectTo}/>;
        } else {
            console.log("Redirecting home");
            return <Navigate to={"/"}/>
        }
    }

    const onFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUserInfo({
            ...userInfo,
            [event.target.name]: event.target.value
        })
    }

    const onConfirm = async () => {
        setError(null);
        setIsSigningUp(true);
        setUserExists(false);
        try {
            setRedirectingToChargeBee(true);
            const response = await signup(userInfo).unwrap();
            if (response.checkout_url) {
                window.location.href = response.checkout_url;
            }
        } catch (error) {
            console.error((error));
            if (error.data.reason && error.data.reason === "user_exists") {
                setUserExists(true);
            }
            setError(error.message);
            setRedirectingToChargeBee(false);
            setIsSigningUp(false);
        }
    }

    const signupFieldInputProps = (field: string): TextFieldProps => {
        return {
            onChange: onFieldChange,
            value: userInfo[field],
            name: field,
            onKeyDown: (ev) => {
                if (ev.key === "Enter" && validateParams(userInfo) && !isSigningUp) {
                    // noinspection JSIgnoredPromiseFromCall
                    onConfirm();
                    ev.preventDefault();
                }
            }
        }
    }

    return <DarkThemePaper title={"Signup"}>
        <Box width={"400px"}>
            <Typography variant={'h5'} sx={{mb: 2}} textAlign={'center'}>Let's get your account set up.</Typography>
            <Box display={'flex'} mb={1.5}>
                <TextField
                    label={"First Name"}
                    sx={{mr: 0.75}}
                    {...signupFieldInputProps("firstName")}
                />
                <TextField
                    label={"Last Name"}
                    sx={{ml: 0.75}}
                    {...signupFieldInputProps("lastName")}
                />
            </Box>
            <TextField
                label={"Email"}
                fullWidth
                sx={{mb: 1.5}}
                {...signupFieldInputProps("email")}
            />
            <TextField
                label={"Password"}
                type={"password"}
                fullWidth
                {...signupFieldInputProps("password")}
            />
            <Collapse in={error !== null}>
                <Typography color={'error'} sx={{mt: 1.75}}>
                    {!userExists ? error : <>
                        A user already exists with that email address.
                        Did you mean to <Link to={"/login"} style={{textDecoration: "underline"}}>log
                        in</Link>?
                    </>}
                </Typography>
            </Collapse>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                mt={1.75}
                alignItems={'center'}
            >
                <Link to={"/login"}><Button size={'small'}>Already have an
                    account?</Button></Link>
                <LoadingButton
                    variant={"contained"}
                    onClick={onConfirm}
                    loading={isSigningUp}
                    disabled={!validateParams(userInfo)}
                >Next</LoadingButton>
            </Box>
        </Box>
    </DarkThemePaper>
}

export default Signup;