import * as React from 'react';
import {useState} from 'react';
import {SearchConsoleResultRow, SearchConsoleResults} from "../../../types/SearchConsoleResults";
import {
    Box,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import NumberDifferencePercentage from "./NumberDifferencePercentage";
import formatNumber from "../../../util/formatNumber";
import {format} from "date-fns";
import {SearchConsoleHistoryDifference} from "../../../hooks/useSearchConsolePerformanceHistory";

export type SearchConsoleQueryRankingTableProps = {
    searchConsoleHistory: SearchConsoleResults | null;
    searchConsoleHistoryDifference: SearchConsoleHistoryDifference;
}

type QueryRanking = {
    clicks: number;
    impressions: number;
    position: number;
    ctr: number;
}

type CombinedBeforeAfterRow = {
    query: string;
    before: QueryRanking;
    after: QueryRanking;
};
const defaultRow = {
    impressions: 0,
    clicks: 0,
    position: 0,
    ctr: 0
}

type RankingDifferencePercentageProps = {
    previousValue: number;
    newValue: number;
    reverseDirection?: boolean
}

const RankingDifferencePercentage = ({previousValue, newValue, reverseDirection}: RankingDifferencePercentageProps) => {
    return <Box ml={0.5} display={"inline"}>
        <NumberDifferencePercentage
            previousValue={previousValue}
            newValue={newValue}
            reverseDirection={reverseDirection}
            TypographyProps={{fontSize: "0.85em"}}
            SvgIconProps={{sx: {fontSize: "0.85em"}}}
        />
    </Box>
}

const SearchConsoleQueryRankingTable = ({
                                            searchConsoleHistory,
                                            searchConsoleHistoryDifference
                                        }: SearchConsoleQueryRankingTableProps) => {
    const [page, setPage] = useState(0);

    let combinedBeforeAfter: CombinedBeforeAfterRow[] | null = null;
    let beforeUpdateStart = null;
    let beforeUpdateEnd = null;
    let afterUpdateStart = null;
    let afterUpdateEnd = null;


    if (searchConsoleHistory && searchConsoleHistory.rows.length > 0) {
        const uniqueQueries = [
            ...searchConsoleHistory.query_rankings_before.map(row => row.keys[0]),
            ...searchConsoleHistory.query_rankings_after.map(row => row.keys[0])
        ].filter((query, index, startArray) => startArray.indexOf(query) === index);
        combinedBeforeAfter = uniqueQueries.map(query => {
            let before = {...(searchConsoleHistory.query_rankings_before.find(row => row.keys[0] === query) || defaultRow)} as SearchConsoleResultRow;
            let after = {...(searchConsoleHistory.query_rankings_after.find(row => row.keys[0] === query) || defaultRow)} as SearchConsoleResultRow;
            if (before.keys) {
                delete before.keys;
            }
            if (after.keys) {
                delete after.keys;
            }

            return {
                query,
                before: before || defaultRow,
                after: after || defaultRow
            }
        });
        combinedBeforeAfter.sort((a, b) => (b.before.clicks + b.after.clicks) - (a.before.clicks + a.after.clicks));

        beforeUpdateStart = format(searchConsoleHistoryDifference.beforeRangeStart, "MMM do");
        beforeUpdateEnd = format(searchConsoleHistoryDifference.beforeRangeEnd, "MMM do");
        afterUpdateStart = format(searchConsoleHistoryDifference.afterRangeStart, "MMM do");
        afterUpdateEnd = format(searchConsoleHistoryDifference.afterRangeEnd, "MMM do");
    }

    return <TableContainer>
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography variant={"h6"} fontSize={"1.1rem"}>
                            Search Query Performance
                        </Typography>
                        <Typography fontSize={"0.75rem"}
                                    sx={{visibility: searchConsoleHistory === null ? "hidden" : "inherit"}}>
                            {`${afterUpdateStart} - ${afterUpdateEnd} compared to ${beforeUpdateStart} - ${beforeUpdateEnd}`}
                        </Typography>
                    </TableCell>
                    <TablePagination
                        count={combinedBeforeAfter === null ? 10 : combinedBeforeAfter.length}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                        rowsPerPage={10}
                        rowsPerPageOptions={[10]}
                    />
                </TableRow>
                <TableRow>
                    <TableCell>Query</TableCell>
                    <TableCell>Position</TableCell>
                    <TableCell>Impressions</TableCell>
                    <TableCell>Clicks</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {combinedBeforeAfter ? combinedBeforeAfter.slice(page * 10, page * 10 + 10).map(row => (
                    <TableRow key={row.query}>
                        <TableCell>{row.query}</TableCell>
                        <TableCell>
                            {Math.round(row.after.position * 100) / 100}
                            <RankingDifferencePercentage
                                previousValue={row.before.position}
                                newValue={row.after.position}
                                reverseDirection={true}
                            />
                        </TableCell>
                        <TableCell>
                            {formatNumber(row.after.impressions, 2)}
                            <RankingDifferencePercentage
                                previousValue={row.before.impressions}
                                newValue={row.after.impressions}
                            />
                        </TableCell>
                        <TableCell>
                            {formatNumber(row.after.clicks, 2)}
                            <RankingDifferencePercentage
                                previousValue={row.before.clicks}
                                newValue={row.after.clicks}
                            />
                        </TableCell>
                    </TableRow>
                )) : Array.from(new Array(10)).map((_, index) => (
                    <TableRow key={index + 1}>
                        <TableCell><Skeleton/></TableCell>
                        <TableCell><Skeleton/></TableCell>
                        <TableCell><Skeleton/></TableCell>
                        <TableCell><Skeleton/></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}

export default SearchConsoleQueryRankingTable;