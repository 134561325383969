import React from "react";
import {makeStyles} from '@mui/styles'
import ReportTableCell from "./ReportTableCell";
import {TableRow, TableCell, Checkbox} from "@mui/material";
import {Row} from "./useReportTable";
import Column from "../../types/Column";
import {SelectedColumnFieldOptions} from "./ReportTable";
import Table from "../../types/Table";

import ReportTableRowActionsCell from "./ReportTableRowActionsCell";

const useStyles = makeStyles(() => ({
    root: {
        "&:nth-child(odd)": {
            background: "#efefef"
        }
    }
}));

type ReportTableRowProps = {
    row: Row,
    columns: Column[],
    fields: any,
    selectedColumnFieldOptions: SelectedColumnFieldOptions,
    onRankingsClick?: (row: Row, columns: Column[]) => void,
    isChecked : boolean,
    onChecked: (row: Row) => void,
    table: Table,
    showActionsCell: boolean,
}

const ReportTableRow = ({
                            row,
                            columns,
                            fields,
                            selectedColumnFieldOptions,
                            onRankingsClick,
                            onChecked,
                            isChecked,
                            table,
                            showActionsCell
                        }: ReportTableRowProps) => {
    const classes = useStyles();
    const onCheckedClick = () => {
        onChecked(row);
    };
    return <TableRow className={classes.root} selected={isChecked}>
        {showActionsCell ?
            <TableCell padding="checkbox">
                <Checkbox color="primary" onClick={onCheckedClick} checked={isChecked}/>
            </TableCell> : null
        }
        {columns.map(column => (
            <ReportTableCell
                key={column.id}
                row={row}
                column={column}
                fields={fields}
                selectedColumnFieldOptions={selectedColumnFieldOptions}
            />
        ))}
        {showActionsCell ?
            <ReportTableRowActionsCell
                table={table}
                row={row}
                columns={columns}
                onRankingsClick={onRankingsClick}
            /> : null
        }
    </TableRow>;
}

export default ReportTableRow;