import * as React from "react";
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {ButtonProps} from "@mui/material";
import {useLogOutMutation} from "../store/slice/myContentDashAdminApi";

const LogoutButton = (props: ButtonProps) => {
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [logOut] = useLogOutMutation({fixedCacheKey: 'appLogout'});

    const onLogOut = async () => {
        setIsLoggingOut(true);
        await logOut();
    }

    return <LoadingButton
        onClick={onLogOut}
        loading={isLoggingOut}
        {...props}
    >
        Log out
    </LoadingButton>
}

export default LogoutButton;