import React from "react";
import {TableSortLabel, TableCell} from "@mui/material";
import {makeStyles} from '@mui/styles'
import HtmlTooltip from "../HtmlTooltip";
import Column from "../../types/Column";
import {Field} from "../../types/Field";

const sortDirections = ["asc", "desc", null];
const useStyles = makeStyles(() => ({
    root: {
        fontWeight: "bold"
    }
}))

export type ReportTableHeaderCellProps = {
    column: Column;
    sortDirection: "DESC" | "ASC";
    onSortDirectionChange: (newSortDirection: "ASC" | "DESC" | null) => void;
    fieldType: Field["type"]
}

const ReportTableHeaderCell = ({
                                   column,
                                   sortDirection,
                                   onSortDirectionChange,
                                   fieldType
                               }: ReportTableHeaderCellProps) => {
    const nextSortDirectionIndex = (sortDirections.indexOf(sortDirection) + 1) % sortDirections.length;
    const onSortClick = () => {
        onSortDirectionChange((sortDirections[nextSortDirectionIndex]?.toLowerCase() as "DESC" | "ASC" | undefined) || null);
    }

    const classes = useStyles();

    return <TableCell>
        <TableSortLabel
            active={Boolean(sortDirection)}
            direction={sortDirection === null ? undefined : sortDirection?.toLowerCase() as "desc" | "asc"}
            onClick={onSortClick}
            className={classes.root}
            classes={{root: "column-type-" + fieldType}}
            data-test={Boolean(sortDirection)}
        >
            <HtmlTooltip
                help={column.help}
                placement={"top"}
                arrow
            >
                <span>{column.label}</span>
            </HtmlTooltip>
        </TableSortLabel>
    </TableCell>

};

export default ReportTableHeaderCell;