import {useEffect, useState} from "react";
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from "dayjs";

dayjs.extend(relativeTime);

const useRelativeFormattedDate = (timestamp: number | null | undefined) => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        if (!timestamp) {
            return;
        }
        setCurrentTime(new Date());
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 5000);

        return () => {
            clearInterval(intervalId);
        }
    }, [timestamp]);

    return timestamp ? dayjs(new Date(timestamp)).from(dayjs(currentTime)) : "";
}

export default useRelativeFormattedDate;