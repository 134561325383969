import React, {Fragment, useContext, useEffect, useState} from "react";
import {makeStyles} from '@mui/styles'
import ReportSidebar from "./ReportSidebar";
import DashboardHeader from "./DashboardHeader";
import cx from "classnames";
import {Navigate, useMatch} from "react-router-dom";
import FullPageSpinner from "../FullPageSpinner";
import ReactGA from "react-ga";
import SettingsPanel from "./settings/SettingsPanel";
import {useSelector} from "react-redux";
import store, {useAppDispatch, useAppSelector} from "../../store/store";
import {Box} from "@mui/material";
import globalVars from "../../styles/globalVars";
import {
    reportPagePathnameSelector
} from "../../store/slice/reportSelector";
import {usePageTitle} from "../../hooks/usePageTitle";
import StatusPageSettingUp from "./status_page/StatusPageSettingUp";
import {initializeTableFilterInputs} from "../../store/slice/filtersSlice";
import {isUserSubscriptionActive} from "../../util/isUserSubscriptionActive";
import ReportOwnerAccount from "../../types/ReportOwnerAccount";
import {
    useGetNotesQuery,
    useGetReportsQuery, useGetReportTodoItemsQuery
} from "../../store/slice/myContentDashAdminApi";

import ReportRoutes from "./ReportRoutes";
import ReportContext from "../../context/ReportContext";
import TodoItemsPanel from "../todo-items/TodoItemsPanel";
import {closeTodoPanel} from "../../store/slice/todoPanelSlice";
import GSCNotConnectedDialog from "./settings/integrations/GSCNotConnectedDialog";
import {closeGscNotConnectedDialog} from "../../store/slice/appSlice";

const useStyles = makeStyles(() => ({
    content: {
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        overflow: "auto",
        position: "relative",
        float: "right",
        overflowScrolling: "touch",
        width: `calc(100% - ${globalVars.drawerWidthExpanded})`,
        minHeight: "100%",
        backgroundColor: globalVars.colors.pageBackground
    },
    contentSidebarMinimized: {
        width: `calc(100% - ${globalVars.drawerWidthMinimized})`,
    },
}));

const Report = () => {
    const {report} = useContext(ReportContext);
    const [isPageLoading, setPageLoading] = useState(true);
    const [sidebarMinimized, setSidebarMinimized] = useState(false);
    const [settingsPanelOpen, setSettingsPanelOpen] = useState(false);
    const todoPanelOpen = useAppSelector(state => state.todoPanel.open);
    const dispatch = useAppDispatch();
    const toggleSidebarMinimized = () => setSidebarMinimized(!sidebarMinimized);
    const {params} = useMatch<"domain">("report/:domain/*");
    const reportDomain = params.domain;
    const pagePath = useSelector(reportPagePathnameSelector);
    const {data: reportsList} = useGetReportsQuery();
    const accountOwner: ReportOwnerAccount = reportsList.accounts.find(account => account.id === report.owner)
    useGetNotesQuery({reportId: report.id});
    const {data: todoItemsResponse} = useGetReportTodoItemsQuery({reportId: report.id});
    usePageTitle(report.domain);
    const gscNotConnectedDialogOpen = useAppSelector(state => state.app.gscNotConnectedDialogOpen);

    useEffect(() => {
        ReactGA.set({dimension1: reportDomain});
    }, [reportDomain]);

    const classes = useStyles();

    useEffect(() => {
        if (pagePath !== "" && isPageLoading && report.status === "ready") {
            // TODO use saved user filters
            initializeTableFilterInputs(report, store);
            setPageLoading(false);
        } else if (report.status !== "ready") {
            setPageLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagePath]);

    if (accountOwner && !isUserSubscriptionActive(accountOwner)) {
        return <Navigate to={"/billing"}/>;
    }

    if (isPageLoading) {
        return <FullPageSpinner/>;
    }

    if (report.status === "setting_up") {
        return <StatusPageSettingUp/>;
    }

    if (!pagePath) {
        return <Navigate to={'home'}/>;
    }

    return <Fragment>
        {todoItemsResponse ? <TodoItemsPanel open={todoPanelOpen} onClose={() => dispatch(closeTodoPanel())}/> : null}
        <ReportSidebar minimized={sidebarMinimized}/>
        <div className={cx(
            classes.content,
            {
                [classes.contentSidebarMinimized]: sidebarMinimized
            })}>
            <DashboardHeader
                sidebarMinimized={sidebarMinimized}
                toggleSidebarMinimized={toggleSidebarMinimized}
                openSettingsPanel={() => setSettingsPanelOpen(true)}
            />
            <Box p={2}>
                <ReportRoutes/>
            </Box>
        </div>
        <SettingsPanel open={settingsPanelOpen} onClose={() => setSettingsPanelOpen(false)}/>
        <GSCNotConnectedDialog
            open={gscNotConnectedDialogOpen}
            onClose={() => dispatch(closeGscNotConnectedDialog())}
        />
    </Fragment>
}


export default Report;