import * as React from 'react';
import {useContext, useState} from 'react';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonIcon from '@mui/icons-material/Person';
import {Button, Collapse, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import InviteUserDialog from "../user/InviteUserDialog";
import {LoadingButton} from "@mui/lab";
import doesUserOwnReport from "../../../../util/doesUserOwnReport";

import ReportContext from "../../../../context/ReportContext";
import {useRemoveUserFromReportMutation} from "../../../../store/slice/myContentDashAdminApi";

type RemoveUserButtonProps = {
    userId: number;
    reportId: number;
    onError: (error: string) => void;
}

const RemoveUserButton = ({userId, onError, reportId}: RemoveUserButtonProps) => {
    const [isRemoving, setIsRemoving] = useState(false);
    const [doRemoveUser] = useRemoveUserFromReportMutation();

    const removeUser = async () => {
        setIsRemoving(true);
        onError(null);
        try {
            await doRemoveUser({userId, reportId});
        } catch(error) {
            onError(error.data.message);
            setIsRemoving(false);
        }
    }

    return <LoadingButton
        loading={isRemoving}
        onClick={removeUser}
        color={'error'}
    >Remove</LoadingButton>
}

const ReportUsersSettingsPage = () => {
    const [isInviteUserDialogOpen, setInviteUserDialogOpen] = useState(false);
    const [userRemovalError, setUserRemovalError] = useState<null | string>(null);
    const {report} = useContext(ReportContext);
    const users = report.access_users;

    const hasReachedLimit = users.length >= report.report_users_limit;

    return <>
        <Button
            endIcon={<PersonAddAltIcon/>}
            onClick={() => setInviteUserDialogOpen(true)}
            disabled={hasReachedLimit}
        >Invite User</Button>
        {hasReachedLimit ?
            <Typography
                color={'warning.main'} sx={{my: 1}}
            >You've reached the maximum number of users for your report.</Typography>
            : null
        }
        <Collapse in={userRemovalError !== null}>
            <Typography color={'error'} sx={{my: 1}}>{userRemovalError}</Typography>
        </Collapse>
        <List sx={{mt: 1}}>
            {users.map(user => (
                <ListItem
                    key={user.id}
                    secondaryAction={
                        !doesUserOwnReport(user, report) ?
                            <RemoveUserButton
                                userId={user.id}
                                reportId={report.id}
                                onError={setUserRemovalError}
                            />
                            :
                            <Button color={'error'} disabled>Remove</Button>
                    }
                >
                    <ListItemIcon>
                        <PersonIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={`${user.first_name} ${user.last_name}`}
                        secondary={user.email}
                    />
                </ListItem>
            ))}
        </List>
        <InviteUserDialog
            open={isInviteUserDialogOpen}
            onClose={() => setInviteUserDialogOpen(false)}
        />
    </>;
}

export default ReportUsersSettingsPage;