import {Box, Fade, Paper, Typography} from "@mui/material";
import PostTabPage from "../../../types/PostTabPage";
import React, {useContext, useRef} from "react";
import ReportContext from "../../../context/ReportContext";
import {useGetPostTabPageFieldValuesQuery} from "../../../store/slice/myContentDashAdminApi";
import PostTabPageContext from "../../../context/PostTabPageContext";
import ComponentsList from "./ComponentsList";
import {styled} from "@mui/material/styles";
import extractErrorMessageFromError from "../../../util/extractErrorMessageFromError";

type PostTabPageContentsProps = {
    postTabPage: PostTabPage,
    pageUrl: string,
    isActive: boolean
}

const ErrorContainer = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(52, 52, 52, 0.7);
  justify-content: center;
  align-items: center;
  top: 0;
`;

const PostTabPageContents = ({pageUrl, postTabPage, isActive}: PostTabPageContentsProps) => {
    const {report} = useContext(ReportContext);
    const {data: fieldValuesResponse, error} = useGetPostTabPageFieldValuesQuery({
        report: report.id,
        postTabPage: postTabPage.id,
        url: pageUrl
    }, {skip: !isActive});
    const ref = useRef({
        requestedFieldValues: false
    });

    return <>
        <Box p={2} ref={ref}>
            <Typography variant={'h3'} sx={{mb: 2}}>{postTabPage.label}</Typography>
            <PostTabPageContext.Provider value={{fieldValues: fieldValuesResponse?.fields, postTabPage: postTabPage}}>
                <ComponentsList components={postTabPage.content}/>
            </PostTabPageContext.Provider>
        </Box>
        <Fade in={error !== undefined}>
            <ErrorContainer>
                <Paper variant={'outlined'} sx={{p: 3}}>
                    <Typography variant={'h2'} color={'#f44336'}>{error === undefined ? null : extractErrorMessageFromError(error)}</Typography>
                </Paper>
            </ErrorContainer>
        </Fade>
    </>
}

export default PostTabPageContents;