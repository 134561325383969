type ParsedQueryArgs = {
    [key: string]: string
}

export const parseQueryArgs = (queryString: string): ParsedQueryArgs => {
    const urlParams = new URLSearchParams(queryString);
    const object = {};
    for (let key of urlParams.keys()) {
        object[key] = urlParams.get(key);
    }
    return object;
}