import {
    FeaturedFilter,
    FeaturedFilterInput,
    FilterData, getApplicableTableFeaturedFilters
} from "../../components/table/useTableFilters";
import {createSlice, PayloadAction, Store} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {parseQueryArgs} from "../../util/parseQueryArgs";
import FieldFeaturedFilter from "../../components/report/filters/FieldFeaturedFilter";
import {push} from "redux-first-history";
import {SortByData} from "../../components/table/useReportTable";
import SavedUserFilter from "../../types/SavedUserFilter";
import {myContentDashAdminApi} from "./myContentDashAdminApi";
import {Report} from "../../types/Report";
import {enqueueSnackbar} from "notistack";
import {updateSearchBar} from "./reportSlice";

export type TableUserFilters = {
    tableId: number;
    featuredFilters: FeaturedFilterInput[];
    advancedFilters: FilterData;
    sortByData: SortByData;
    enabledHiddenColumns: number[];
}

type FiltersState = {
    tableUserFilters: TableUserFilters[]
}

const initialState: FiltersState = {
    tableUserFilters: []
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        initializeTableUserFilters: (state, {payload}: PayloadAction<TableUserFilters[]>) => {
            state.tableUserFilters = payload;
        },
        updateTableInput: (state, {payload}: PayloadAction<{ tableId: number, input: Partial<Omit<TableUserFilters, "tableId">> }>) => {
            state.tableUserFilters = state.tableUserFilters.map(userFilters => userFilters.tableId !== payload.tableId ? userFilters : {
                ...userFilters,
                ...payload.input
            })
        },
    }
});

export const resetFeaturedFilterInputInUrl = (store: Store<RootState>) => {
    const state = store.getState();
    const existingQuery = parseQueryArgs(state.router.location.search);

    const newQuery = {};
    Object.keys(existingQuery).forEach(key => {
        if (key.substring(0, 3) !== "ff_") {
            newQuery[key] = existingQuery[key];
        }
    })

    const parsedParams = new URLSearchParams(newQuery).toString();

    store.dispatch(push((state.router.location as unknown as Location).pathname + (parsedParams.length > 0 ? "?" + parsedParams : "")));
}

export const getDefaultFeaturedFilterInputs = (featuredFilters: FeaturedFilter[], store?: Store<RootState>): FeaturedFilterInput[] => {
    if (store) {
        const queryArgs = parseQueryArgs(store.getState().router.location.search);
        const queryFeaturedFilterIds = Object.keys(queryArgs)
            .filter(key => key.substring(0, 3) === "ff_")
            .map(key => parseInt(key.substring(3)))
            .filter(key => !isNaN(key) && featuredFilters.some(({id}) => id === key))

        return featuredFilters
            .filter(featuredFilter => featuredFilter !== undefined)
            .filter(({id}) => !queryFeaturedFilterIds.includes(id))
            .map(featuredFilter => ({
                featuredFilterId: featuredFilter.id,
                input: FieldFeaturedFilter.getDefaultValue(featuredFilter),
                type: featuredFilter.type
            }))
            .concat(
                queryFeaturedFilterIds.map(id => ({
                    featuredFilterId: id,
                    input: JSON.parse(decodeURIComponent(queryArgs["ff_" + id])),
                    type: featuredFilters.find(({id: featuredFilterId}) => featuredFilterId === id).type
                }))
            )
    }

    return featuredFilters.map(featuredFilter => ({
        featuredFilterId: featuredFilter.id,
        input: FieldFeaturedFilter.getDefaultValue(featuredFilter),
        type: featuredFilter.type
    }));
}

const getSavedUserFilterFromUrl = (store: Store<RootState>, reportId: number): SavedUserFilter | undefined => {
    const state = store.getState();
    const existingQuery = parseQueryArgs(state.router.location.search);

    if (existingQuery.savedFilter === undefined) {
        return;
    }

    const filterId = parseInt(existingQuery.savedFilter);

    // @ts-ignore
    const result = myContentDashAdminApi.endpoints.getSavedUserFilters.select({reportId})(state);
    const {data} = result;
    if (data === undefined) {
        console.warn("A saved user filter was specified in the URL, but the list of user filters is not yet available!");
        return;
    }
    const userFilter = data.saved_user_filters.find((savedFilter) => savedFilter.id === filterId);
    if (!userFilter) {
        console.warn("A saved user filter ID was specified in the URL, but no filter could be found for it. It might have been deleted.");
    }

    return userFilter;
}

export const applySavedUserFilter = (report: Report, store: Store<RootState>, savedFilterId: number) => {
    const state = store.getState();
    store.dispatch(push(
        state.router.location.pathname + "?savedFilter=" + savedFilterId
    ));
    initializeTableFilterInputs(report, store);
}

export const initializeTableFilterInputs = (report: Report, store: Store<RootState>) => {
    const userFilter = getSavedUserFilterFromUrl(store, report.id);
    const tables = report.tables;
    const dataSources = report.data_sources;
    const tableFilterInputs: TableUserFilters[] = tables.map(table => {
        const userFilterTableInput = {...userFilter?.table_inputs?.find((input) => input.table === table.id)};
        const dataSource = dataSources.find(({id}) => table.data_source === id);
        const enabledHiddenColumns = table.columns.filter(column => column.formatting_options.allow_hiding === true).map(({id}) => id)

        const defaultFeaturedFilterInputs = getDefaultFeaturedFilterInputs(
            getApplicableTableFeaturedFilters(table, dataSource, []),
            store
        );

        if (userFilterTableInput?.featured_filter_input) {
            userFilterTableInput.featured_filter_input = userFilterTableInput.featured_filter_input.map(input => {
                const defaultValue = defaultFeaturedFilterInputs.find(defaultInput => defaultInput.featuredFilterId === input.featuredFilterId);
                if (!Array.isArray(defaultValue.input) && Array.isArray(input.input)) {
                    return {
                        ...input,
                        input: {}
                    }
                }
                return input;
            });

            let existingQuery = parseQueryArgs(store.getState().router.location.search)
            const newQuery = {};
            Object.keys(existingQuery).forEach(key => {
                if (key !== "savedFilter") {
                    newQuery[key] = existingQuery[key];
                }
            })
            const parsedParams = new URLSearchParams(newQuery).toString();
            store.dispatch(push((store.getState().router.location as unknown as Location).pathname + (parsedParams.length > 0 ? "?" + parsedParams : "")));
            enqueueSnackbar("Applied saved filter: " + userFilter?.name, {variant: "success"});
        }

        return {
            featuredFilters: userFilterTableInput?.featured_filter_input || getDefaultFeaturedFilterInputs(
                getApplicableTableFeaturedFilters(table, dataSource, []),
                store
            ),
            advancedFilters: userFilterTableInput?.advanced_filters || [],
            tableId: table.id,
            sortByData: userFilterTableInput?.column_sorting || {},
            enabledHiddenColumns: userFilterTableInput?.enabled_hidden_columns || enabledHiddenColumns
        }
    });
    console.log("User filter", userFilter);
    if(userFilter?.search) {
        store.dispatch(updateSearchBar(userFilter.search))
    }
    store.dispatch(initializeTableUserFilters(tableFilterInputs));
}

export const tableInputSelector = (tableId: number) => (state: RootState) => {
    return state.filters.tableUserFilters.find((userFilters) => userFilters.tableId === tableId) as TableUserFilters;
}

export const {
    initializeTableUserFilters,
    updateTableInput
} = filtersSlice.actions;


export default filtersSlice.reducer;