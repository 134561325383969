import React, {useContext, useRef, useState} from "react";
import {
    Button,
    ButtonProps,
    List,
    Popover, TextField, Tooltip, Typography
} from "@mui/material";
import {
    Help,
    StarBorder
} from "@mui/icons-material";
import SavedFilterListItem from "./SavedFilterListItem";
import ReportContext from "../../../context/ReportContext";
import {
    useCreateSavedUserFilterMutation,
    useGetSavedUserFiltersQuery
} from "../../../store/slice/myContentDashAdminApi";
import AddIcon from '@mui/icons-material/Add';
import {LoadingButton} from "@mui/lab";
import store from "../../../store/store";
import useCurrentPage from "../../../hooks/useCurrentPage";
import {ReportPage} from "../../../types/ReportPage";
import {tableInputSelector} from "../../../store/slice/filtersSlice";

type SavedUserFiltersButtonProps = ButtonProps;

const SavedUserFiltersButton = ({...buttonProps}: SavedUserFiltersButtonProps) => {
    const {report} = useContext(ReportContext);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const currentPage = useCurrentPage() as ReportPage;
    const {data: savedUserFiltersResponse} = useGetSavedUserFiltersQuery({reportId: report.id});
    const [createNewSavedUserFilterQueryMutation, {isLoading}] = useCreateSavedUserFilterMutation();
    const buttonRef = useRef();
    const [newFilterName, setNewFilterName] = useState("");

    const createSavedUserFilter = () => {
        const tables = currentPage.table_data.filter(({type}) => type === "table");
        const storeState = store.getState();
        const tableInputs = tables.map(table => tableInputSelector(table.id)(storeState));
        const searchBarInput = storeState.report.searchBarValues.find(({pageId}) => pageId === currentPage.id);

        createNewSavedUserFilterQueryMutation({
            pageId: currentPage.id,
            reportId: report.id,
            name: newFilterName,
            tableInputs: tableInputs.map(tableInput => ({
                table: tableInput.tableId,
                advanced_filters: tableInput.advancedFilters,
                column_sorting: tableInput.sortByData,
                featured_filter_input: tableInput.featuredFilters,
                enabled_hidden_columns: tableInput.enabledHiddenColumns
            })),
            search: searchBarInput || {}
        })
        setNewFilterName("");
    }

    const sortedUserFilters = [...savedUserFiltersResponse.saved_user_filters].filter(({page_id}) => page_id === currentPage.id);
    sortedUserFilters.sort((a, b) => b.created_at - a.created_at);

    return <>
        <Button
            ref={buttonRef}
            startIcon={<StarBorder/>}
            variant={"contained"}
            size={"small"}
            color={"secondary"}
            onClick={() => setPopoverOpen(true)}
            disabled={popoverOpen}
            {...buttonProps}
        >Saved Filters</Button>
        <Popover
            open={popoverOpen}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            anchorEl={buttonRef.current}
            PaperProps={{sx: {p: 1, width: "340px"}}}
            onClose={() => setPopoverOpen(false)}
        >
            <Typography variant={"h4"} textAlign={"center"}>
                Saved Filters
                <Tooltip
                    title={"You can save all active filters on the current page to share with your team or load later."}>
                    <Help sx={{fontSize: "1rem", ml: 1}}/>
                </Tooltip>
            </Typography>
            {sortedUserFilters.length === 0 ?
                <Typography textAlign={"center"} sx={{my: 3}} color={"gray"}>No saved filters added</Typography> : null
            }
            <List sx={{maxHeight: '500px', overflowY: "auto"}}>
                {sortedUserFilters
                    .map(savedFilter => (
                        <SavedFilterListItem
                            savedFilterListItem={savedFilter}
                            key={savedFilter.id}
                            onApplied={() => setPopoverOpen(false)}
                        />))
                }
            </List>
            <TextField
                size={"small"}
                fullWidth
                value={newFilterName}
                onChange={event => setNewFilterName(event.target.value)}
                label={"New name (optional)"}
                disabled={isLoading}
                onKeyDown={event => {
                    if (event.key === "Enter") {
                        createSavedUserFilter();
                    }
                }}
            />
            <LoadingButton
                variant="outlined"
                color={"secondary"}
                endIcon={<AddIcon/>}
                fullWidth
                loading={isLoading}
                onClick={() => createSavedUserFilter()}
            >Save Current Filters</LoadingButton>
        </Popover>
    </>
}

export default SavedUserFiltersButton;