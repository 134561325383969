import React from "react";
import {Table} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import HiddenColumnRow from "./HiddenColumnRow";

const HiddenColumnList = ({displayedColumnIds, columns, onChange}) => {

    const setSelected = (columnId, selected) => {
        if (selected) {
            onChange([...displayedColumnIds, columnId]);
        } else {
            onChange(displayedColumnIds.filter(existingColumnId => existingColumnId !== columnId));
        }
    }

    return <>
        <Table size={"small"}>
            <TableBody>
                {columns.map(column => (
                    <HiddenColumnRow
                        key={column.id}
                        column={column}
                        isSelected={displayedColumnIds.indexOf(column.id) !== -1}
                        onChange={checked => setSelected(column.id, checked)}
                    />
                ))}
            </TableBody>
        </Table>
    </>;
}

export default HiddenColumnList;