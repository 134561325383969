import * as React from "react";
import {Navigate} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import SwitchReportsButton from "../../SwitchReportsButton";
import GetHelpButton from "../../GetHelpButton";
import DarkThemePaper from "../../../template/DarkThemePaper";

import {useContext} from "react";
import ReportContext from "../../../context/ReportContext";

const StatusPageSettingUp = () => {
    const {report} = useContext(ReportContext);
    if (!report) {
        return <Navigate to={"/reports"}/>
    }

    return <DarkThemePaper title={report.domain}>
        <Box maxWidth={"400px"}>
            <Typography>MyContentDash is connected and will be ready for you shortly.
                We'll send you an email as soon as things are ready for you.</Typography>
            <Box
                display={'flex'}
                mt={1}
                justifyContent={'center'}
            >
                <GetHelpButton/>
                <SwitchReportsButton sx={{mt: 1, ml: 1}} variant={"outlined"}/>
            </Box>
        </Box>
    </DarkThemePaper>
}

export default StatusPageSettingUp;