import React, {useContext, useState} from "react";
import {Drawer, Tab, Tabs} from "@mui/material";
import Typography from "@mui/material/Typography";
import {makeStyles, withStyles} from '@mui/styles'
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import ReportUsersSettingsPage from "./page/ReportUsersSettingsPage";
import ExportSettingsPage from "./page/ExportSettingsPage";
import IntegrationsSettingsPage from "./page/IntegrationsSettingsPage";
import doesUserOwnReport from "../../../util/doesUserOwnReport";
import {useGetSessionQuery} from "../../../store/slice/myContentDashAdminApi";

import ReportContext from "../../../context/ReportContext";


const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'row'
    },
    sidebar: {
        width: '180px'
    },
    googleAnalyticsLogo: {
        width: '250px'
    }
}));

const SettingsPanelTab = withStyles(({
    wrapper: {
        display: 'block',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        maxWidth: '180px',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}))((props) => <Tab {...props}/>) as typeof Tab;

export type SettingsPanelProps = {
    open: boolean;
    onClose: () => void;
}

const SettingsPanel = ({open, onClose}: SettingsPanelProps) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = useState<0 | 1 | 2>(0);
    const {data: session} = useGetSessionQuery();
    const user = session.user;
    const {report} = useContext(ReportContext);

    return <Drawer
        open={open}
        onClose={onClose}
        anchor={"right"}
        PaperProps={{
            sx: {
                width: "50vw",
                p: 2
            },
            className: classes.root
        }}
    >
        <div className={classes.sidebar}>
            <Paper elevation={2}>
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    aria-label="Vertical tabs example"
                    value={selectedTab}
                    onChange={(event, newValue) => setSelectedTab(newValue)}
                >
                    <SettingsPanelTab label={"Integrations"}/>
                    {doesUserOwnReport(user, report) ? <SettingsPanelTab label={"Users"}/> : null}
                    {doesUserOwnReport(user, report) ? <SettingsPanelTab label={"Export"}/> : null}
                </Tabs>
            </Paper>
        </div>
        <Box pl={4} width={"100%"}>
            <Typography variant={"h2"} textAlign={"center"} width={"100%"} sx={{mb: 2}}>
                Manage {report.domain}
            </Typography>
            <Box display={selectedTab === 0 ? "block" : "none"}>
                <IntegrationsSettingsPage/>
            </Box>
            <Box display={selectedTab === 1 ? "block" : "none"}>
                <ReportUsersSettingsPage/>
            </Box>
            <Box display={selectedTab === 2 ? "block" : "none"}>
                <ExportSettingsPage/>
            </Box>
        </Box>
    </Drawer>
}

export default SettingsPanel;