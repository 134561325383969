import * as React from 'react';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {Box, SvgIconProps, Typography, TypographyProps} from "@mui/material";
import theme from "../../../styles/themeOverrides";

export type NumberDifferencePercentageProps = {
    previousValue: number;
    newValue: number;
    reverseDirection?: boolean;
    TypographyProps?: TypographyProps;
    SvgIconProps?: SvgIconProps;
}

const NumberDifferencePercentage = ({
                                        previousValue,
                                        newValue,
                                        reverseDirection = false,
                                        TypographyProps = {},
                                        SvgIconProps = {}
                                    }: NumberDifferencePercentageProps) => {

    const isLower = reverseDirection ? newValue > previousValue : previousValue > newValue;
    const difference = Math.round((Math.abs(newValue - previousValue) / previousValue) * 1000) / 10;

    const isValidNumber = !isNaN(difference) && isFinite(difference) && difference !== 0;
    const fontColor = isValidNumber ? (isLower ? theme.palette.error.main : theme.palette.success.main) : theme.palette.grey.A400;

    return <Box display={'inline-flex'} justifyContent={"center"} color={fontColor} alignItems={"center"}>
        {!isValidNumber ? null : (isLower ? <ArrowDropDownIcon {...SvgIconProps}/> :
            <ArrowDropUpIcon {...SvgIconProps}/>)}
        <Typography
            sx={{display: "inline", ...(TypographyProps.sx || {})}}
            {...TypographyProps}
        >{!isValidNumber ? "--" : ((isLower ? "-" : "+") + (difference) + "%")}</Typography>
    </Box>;
}

export default NumberDifferencePercentage;