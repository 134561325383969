import React from "react";
import {Box} from "@mui/material";

const CenteredElement = ({children}: { children: JSX.Element | JSX.Element[] | string }) => {

    return <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
        height={'100%'}
    >
        <div>
            {children}
        </div>
    </Box>;
}


export default CenteredElement;