import * as React from 'react';
import {ButtonProps, Collapse, Typography} from "@mui/material";
import useGoogleAuthorization from "../../../../hooks/useGoogleAuthorization";
import {LoadingButton} from "@mui/lab";
import {useContext} from "react";
import ReportContext from "../../../../context/ReportContext";

type ConnectGoogleButtonProps = {} & ButtonProps;

const ConnectGoogleButton = ({...buttonProps}: ConnectGoogleButtonProps) => {
    const {report} = useContext(ReportContext);
    const {
        isAuthenticating,
        openAuthorizationDialog,
        error
    } = useGoogleAuthorization({
        report
    });


    return <>
        <LoadingButton
            loading={isAuthenticating}
            onClick={() => openAuthorizationDialog()}
            variant={"outlined"}
            disabled={report.integrations.google.lock_configuration}
            {...buttonProps}
        >Connect Google</LoadingButton>
        <Collapse in={error !== null}>
            <Typography color={'error.main'}>{error}</Typography>
        </Collapse>
    </>
}

export default ConnectGoogleButton;