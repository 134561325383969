import Column from "../../types/Column";
import {MenuItem, TextField, TextFieldProps} from "@mui/material";
import React from "react";

type ColumnFieldOptionsSelectProps = {
    column: Column,
    value: number,
    onChange: (newFieldId: number) => void
} & TextFieldProps;

const ColumnFieldOptionSelect = ({column, value, onChange, ...props}: ColumnFieldOptionsSelectProps) => {
    return <TextField
        label={column.multiple_fields_select_label || column.label}
        value={value}
        onChange={event => onChange(Number(event.target.value))}
        select
        size={"small"}
        {...props}
    >
        {column.user_column_field_options.map(option => (
            <MenuItem key={option.id} value={option.field_id}>{option.label}</MenuItem>
        ))}
    </TextField>
}

export default ColumnFieldOptionSelect;