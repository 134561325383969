import {createContext} from "react";
import {FeaturedFilter, FeaturedFilterInput} from "../components/table/useTableFilters";
import {UseFilterHashProps} from "../components/table/useReportTable";
import {GetFilterOptionCountParams} from "../store/slice/myContentDashAdminApi";

export type ReportTableContextType = {
    tableId: number;
    featuredFilterInput: FeaturedFilterInput[];
    useFilterHash: (props: UseFilterHashProps) => string;
    getFeaturedFilterCountParams: (featuredFilter: FeaturedFilter) => GetFilterOptionCountParams;
}

const ReportTableContext = createContext<ReportTableContextType>(null);

export default ReportTableContext;