import React, {useState} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AssessmentIcon from '@mui/icons-material/Assessment';
import {ReportStatus} from "../types/Report";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItemText,
    Typography
} from "@mui/material";
import {useAppDispatch} from "../store/store";
import {push} from "redux-first-history";
import {useGetReportsQuery, useGetSessionQuery} from "../store/slice/myContentDashAdminApi";
import BasicReport from "../types/BasicReport";

const itemStatusMeta: { [key in ReportStatus]: { color: string; label: string } } = {
    ready: {
        color: "success.main",
        label: "Ready"
    },
    setting_up: {
        color: "warning.main",
        label: "Setting Up"
    },
    cancelled: {
        color: "error.main",
        label: "Subscription Expired"
    }
};

type ReportListItemProps = {
    report: BasicReport,
    onClick: () => void;
}

const ReportListItem = ({
                            report,
                            onClick = () => {
                            }
                        }: ReportListItemProps) => {
    const {data: session} = useGetSessionQuery();
    const {data: reportsList} = useGetReportsQuery();
    const reportOwnerUser = reportsList.accounts.find(account => account.id === report.owner);
    const [notReadyDialogOpen, setNotReadyDialogOpen] = useState(false);
    const [noSubscriptionDialogOpen, setNoSubscriptionDialogOpen] = useState(false);
    const dispatch = useAppDispatch();

    const hasActiveSubscription = report.status !== "cancelled";
    const isCurrentUserOwner = session?.user && session?.user.id === reportOwnerUser?.id;

    return <>
        <ListItem
            button
            onClick={() => {
                if (!hasActiveSubscription) {
                    if (isCurrentUserOwner) {
                        dispatch(push("/billing"));
                    } else {
                        setNoSubscriptionDialogOpen(true);
                    }
                } else if (report.status === "setting_up") {
                    setNotReadyDialogOpen(true);
                } else {
                    onClick();
                }
            }}
        >
            <ListItemIcon>
                <AssessmentIcon/>
            </ListItemIcon>
            <ListItemText
                primary={report.domain}
                secondaryTypographyProps={{color: itemStatusMeta[report.status].color, fontSize: "0.75rem"}}
                secondary={<>
                    {reportOwnerUser && !isCurrentUserOwner ? <>
                        <Typography
                            component={"span"}
                            color={"text.primary"}
                            fontSize={"0.75rem"}
                        >{`Shared by ` + reportOwnerUser.name}</Typography>
                        <br/>
                    </> : null}
                    {itemStatusMeta[report.status].label}
                </>}
            />
        </ListItem>
        {
            report.status === "setting_up" ?
                <Dialog
                    open={notReadyDialogOpen}
                    onClose={() => setNotReadyDialogOpen(false)}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogTitle>Your report will be ready soon</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            MyContentDash has been connected to your WordPress website, and we're currently in the
                            process of importing data. Your report will be available to view soon.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setNotReadyDialogOpen(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
                : null
        }
        {
            !hasActiveSubscription && reportOwnerUser ?
                <Dialog
                    open={noSubscriptionDialogOpen}
                    onClose={() => setNoSubscriptionDialogOpen(false)}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogTitle>Expired Subscription</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {`The subscription for the owner of this report (${reportOwnerUser.name}, ${reportOwnerUser.email}) has expired. 
                            They will need to log in and renew their subscription for this report to be accessed. `}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setNoSubscriptionDialogOpen(false)}>Close</Button>
                    </DialogActions>
                </Dialog>
                : null
        }
    </>;
}

export default ReportListItem;