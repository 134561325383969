import * as React from 'react';
import {ButtonProps, Collapse, Typography} from "@mui/material";
import {forwardRef, useState} from "react";
import ReportsApi from "../api/ReportsApi";
import WordPressIcon from "./icon/WordPressIcon";
import {LoadingButton} from "@mui/lab";

type DownloadWordPressPluginButtonProps = {
    onDownloaded?: () => void;
    onError?: (error: string | null) => void;
} & ButtonProps;

const DownloadWordPressPluginButton = forwardRef<HTMLButtonElement, DownloadWordPressPluginButtonProps>(
    ({onDownloaded, onError, ...props}: DownloadWordPressPluginButtonProps, ref) => {
    const [isDownloadingPlugin, setIsDownloadingPlugin] = useState(false);
    const [error, setError] = useState<null | string>(null);

    const downloadPlugin = async () => {
        if (onError !== undefined) {
            onError(null);
        }
        setError(null);
        setIsDownloadingPlugin(true);
        try {
            await ReportsApi.downloadWordPressCompanionPlugin();
            if (onDownloaded !== undefined) {
                onDownloaded();
            }
        } catch (error) {
            if (onError !== undefined) {
                onError(error.message);
            } else {
                setError(error.message);
            }
        } finally {
            setIsDownloadingPlugin(false);
        }
    }
    return <>
        <LoadingButton
            ref={ref}
            loading={isDownloadingPlugin}
            size={'large'}
            startIcon={<WordPressIcon sx={{fontSize: "24px"}}/>}
            onClick={downloadPlugin}
            {...props}
        >{props.children}</LoadingButton>
        <Collapse in={error !== null}>
            <Typography color={'error'}>{error}</Typography>
        </Collapse>
    </>
});

export default DownloadWordPressPluginButton;