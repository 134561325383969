import {Box, Button, ButtonProps} from "@mui/material";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import MultiTrackPagePerformancePopover from "../page-performance-tracker/MultiTrackPagePerformancePopover";
import React, {useContext, useRef, useState} from "react";
import ReportContext from "../../context/ReportContext";
import MultiPageNotePopover from "../page-notes/MultiPageNotePopover";
import MultiTodoPopover from "../todo-items/MultiTodoPopover";
import FormatListNumberedOutlinedIcon from "@mui/icons-material/FormatListNumberedOutlined";

type ReportTableMutiselectActionsProps = {
    urls: readonly string[];
    totalRows: number;
    clearSelection: () => void;
    selectAllAcrossPages: () => void;
} & Partial<ButtonProps>;

const ReportTableMutiselectActions = ({urls, totalRows, clearSelection, selectAllAcrossPages, ...props}: ReportTableMutiselectActionsProps) => {
    const {report} = useContext(ReportContext);
    const [trackPopoverOpen, setTrackPopoverOpen] = useState(false);
    const [pageNotePopoverOpen, setPageNotePopoverOpen] = useState(false);
    const [todoPopoverOpen, setTodoPopoverOpen] = useState(false);
    const trackPageButtonRef = useRef();
    const pageNoteButtonRef = useRef();
    const todoButtonRef = useRef();


    return <Box display={'flex'}>
            {report.enable_todo ?
                <>
                    <Button
                        variant="outlined"
                        ref={todoButtonRef}
                        {...props}
                        onClick={() => setTodoPopoverOpen(true)}
                    >
                        <FormatListNumberedOutlinedIcon/>
                        Add Todo Item
                    </Button>
                    <MultiTodoPopover
                        open={todoPopoverOpen}
                        onClose={() => setTodoPopoverOpen(false)}
                        anchorEl={todoButtonRef.current}
                        pageUrls={urls}
                    />
                </> : null
            }
            {report.enable_notes ?
                <>
                    <Button
                        onClick={() => setPageNotePopoverOpen(true)}
                        variant="outlined"
                        ref={pageNoteButtonRef}
                        {...props}
                    >
                        <StickyNote2Icon/>
                        Add note
                    </Button>
                    <MultiPageNotePopover
                        open={pageNotePopoverOpen}
                        onClose={() => setPageNotePopoverOpen(false)}
                        anchorEl={pageNoteButtonRef.current}
                        pageUrls={urls}
                    />
                </> : null
            }
            {report.enable_page_trackers ?
                <>
                    <Button
                        onClick={() => setTrackPopoverOpen(true)}
                        variant="outlined"
                        {...props}
                        ref={trackPageButtonRef}
                    >
                        <TroubleshootIcon/>
                        Add Pages to Trackers
                    </Button>
                    <MultiTrackPagePerformancePopover
                        pageUrls={urls as string[]}
                        onClose={() => setTrackPopoverOpen(false)}
                        open={trackPopoverOpen}
                        anchorEl={trackPageButtonRef.current}
                    />
                </> : null
            }
            <Button
                onClick={selectAllAcrossPages}
                variant="outlined"
                {...props}
            >
                Select all {totalRows}
            </Button>
            <Button onClick={clearSelection} variant="outlined" {...props}>Clear Selection</Button>
            <p>{urls.length} selected</p>
        </Box>;
}

export default ReportTableMutiselectActions;