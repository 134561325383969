import * as React from 'react';
import {useState} from 'react';
import {Box, Collapse, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import ReactGA from "react-ga";
import GAEventCategory from "../../types/GAEventCategory";
import {GAEventActionUser} from "../../types/GAEventAction";
import {useSetupWordpressIntegrationMutation} from "../../store/slice/myContentDashAdminApi";

export type ConnectWordPressContentProps = {
    code: string;
    websiteUrl: string;
    redirectUrl: string;
}

const ConnectWordPressContent = ({code, websiteUrl, redirectUrl}: ConnectWordPressContentProps) => {
    const [isConnecting, setIsConnecting] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [setupWordpressIntegration] = useSetupWordpressIntegrationMutation();

    const onConfirm = async () => {
        setIsConnecting(true);
        try {
            const response = await setupWordpressIntegration({code, websiteUrl}).unwrap();
            const url = new URL(redirectUrl);
            url.searchParams.append("state", "authorized");
            url.searchParams.append("mcd_code", response.code);
            url.searchParams.append("code", response.code); // Backwards compatibility
            ReactGA.event({
                category: GAEventCategory.USER,
                action: GAEventActionUser.CONNECTED_WORDPRESS,
                transport: 'beacon'
            });
            window.location.href = url.toString();
        } catch (error) {
            console.error(error);
            setError(error.data.message)
            setIsConnecting(false);
        }
    }

    return <>
        <Typography
            textAlign="center"
            variant={"h3"}
            sx={{mb: 2}}
        >
            {websiteUrl}
        </Typography>
        <Typography>
            By connecting MyContentDash to your WordPress website, we will be able to communicate with the plugin
            on your website to generate content reports.
        </Typography>
        <Box display={'flex'} justifyContent={'center'} mb={2}>
            <LoadingButton
                sx={{mt: 2}}
                variant={"contained"}
                size={'large'}
                loading={isConnecting}
                onClick={onConfirm}
                disabled={error !== null}
            >
                Confirm
            </LoadingButton>
        </Box>
        <Collapse in={error !== null}>
            <Typography color={'error'}>
                {error}
            </Typography>
        </Collapse>
    </>
}

export default ConnectWordPressContent;