import * as React from 'react';
import {useEffect, useState} from 'react';
import DarkThemePaper from "../template/DarkThemePaper";
import {Box, Collapse, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../store/store";
import {Navigate} from "react-router-dom";
import SetupReportOwner from "../components/setup-report/SetupReportOwner";
import NavigateToLogin from "../components/NavigateToLogin";
import WebsiteAddonRequired from "../components/setup-report/WebsiteAddonRequired";
import {usePageTitle} from "../hooks/usePageTitle";
import {isUserSubscriptionActive} from "../util/isUserSubscriptionActive";
import {removeUrlParams} from "../store/slice/appSlice";
import makePlural from "../util/makePlural";
import {push} from "redux-first-history";
import {useGetSessionQuery} from "../store/slice/myContentDashAdminApi";

const AddNewReport = () => {
    const {data: session} = useGetSessionQuery();
    const user = session.user;
    const [showPurchaseMessage, setShowPurchaseMessage] = useState(false);
    const location = useAppSelector(state => state.router.location) as unknown as Location;
    const dispatch = useAppDispatch();
    usePageTitle("Add Website");

    useEffect(() => {
        const search = new URLSearchParams(location.search);
        if (search.has("state") && search.has("id")) {
            dispatch(removeUrlParams(["state", "id"]));
            setShowPurchaseMessage(true);
        }
    }, [dispatch, location]);

    if (!user) {
        return <NavigateToLogin/>;
    }

    if (!isUserSubscriptionActive(user)) {
        return <Navigate to={"/billing"}/>;
    }

    const onReportConnected = () => {
        dispatch(push("/reports"));
    }

    return <DarkThemePaper
        title={"Add Another Site"}
    >
        <Box width={"400px"}>
            <Collapse in={showPurchaseMessage}>
                <Typography color={"success.main"} sx={{mb: 1}}>Thank you for your purchase!</Typography>
            </Collapse>
            {user.reports_connected < user.report_connection_limit ?
                (<SetupReportOwner onReportConnected={onReportConnected}>
                    {
                        `You can add your website to MyContentDash by following the steps below. ` +
                        ((user.report_connection_limit > 100) ?
                            `Your plan allows you to have an unlimited number of websites connected.` :
                            `Your plan allows you to connect up to ${user.report_connection_limit} ${makePlural(user.report_connection_limit, 'website')}.`)
                    }
                </SetupReportOwner>) : <WebsiteAddonRequired/>
            }
        </Box>
    </DarkThemePaper>
}

export default AddNewReport;