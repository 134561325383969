import * as React from "react";
import {LoadingButton} from "@mui/lab";
import {ButtonProps} from "@mui/material";
import {
    useGenerateUpgradeSubscriptionCheckoutUrlMutation,
} from "../../store/slice/myContentDashAdminApi";
import {useEffect} from "react";

const UpgradePlanButton = ({...props}: Omit<ButtonProps, "onClick">) => {
    const [mutate, {data: checkoutUrl, isLoading}] = useGenerateUpgradeSubscriptionCheckoutUrlMutation();

    useEffect(() => {
        if (checkoutUrl) {
            window.location.href = checkoutUrl;
        }
    }, [checkoutUrl]);

    return <LoadingButton
        size={"large"}
        variant={"contained"}
        loading={isLoading}
        onClick={() => mutate()}
        {...props}
    >
        {props?.children || "Upgrade"}
    </LoadingButton>
}

export default UpgradePlanButton;