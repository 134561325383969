import * as React from 'react';
import {FeaturedFilterCount, FeaturedFilterOption} from "../../table/useTableFilters";
import {Button, TableCell, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import formatNumber from "../../../util/formatNumber";
import Skeleton from "@mui/material/Skeleton";

export type PresetFilterOptionsRadioRowProps = {
    isActive: boolean;
    onActivate: () => void;
    onToggleOff: () => void;
    optionCount: FeaturedFilterCount | null;
    filterOption: FeaturedFilterOption;
}

const PresetFilterOptionsRadioRow = ({
                                         isActive,
                                         onActivate,
                                         onToggleOff,
                                         optionCount,
                                         filterOption
                                     }: PresetFilterOptionsRadioRowProps) => {

    return <TableRow>
        <TableCell>
            <Button
                variant={isActive ? 'contained' : 'text'}
                onClick={() => isActive ? onToggleOff() : onActivate()}
                size={"small"}
            >{filterOption.label}</Button>
        </TableCell>
        <TableCell style={{minWidth: 70, textAlign: "right"}}>
            {optionCount ?
                <Typography
                    variant={"caption"}
                    sx={{ml: 2}}
                >{formatNumber(optionCount.count, 1)}</Typography> : <Skeleton variant="text" width={40}/>
            }
        </TableCell>
    </TableRow>
}

export default PresetFilterOptionsRadioRow;