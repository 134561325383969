import {Navigate, Route, Routes} from "react-router-dom";
import React, {useContext} from "react";
import {ReportPage as ReportPageType} from "../../types/ReportPage";
import ReportPage from "../../pages/report/ReportPage";
import {LinearProgress} from "@mui/material";
import ReportContext from "../../context/ReportContext";
import TrackedPages from "./page_tracking/TrackedPages";

const ReportRoutes = () => {
    const {report} = useContext(ReportContext);

    if(!report.pages) {
        return <LinearProgress sx={{m: 4}}/>
    }

    return <Routes>
        {getRoutes(report.pages)}
        <Route path={"/tracked-pages"} element={<TrackedPages/>}/>
        <Route path="*" element={<Navigate to={report.pages[0].route}/>}/>
    </Routes>
}


const getRoutes = (pagesData: ReportPageType[]) => {
    let routes = [];

    for (let pageData of pagesData) {
        routes.push(
            <Route key={pageData.route} path={pageData.route} element={<ReportPage page={pageData}/>}/>
        )
    }
    return routes;
}


export default ReportRoutes;