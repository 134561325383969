import React, {FC, useContext, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import {styled, Table, TableCellProps} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import PresetFilterOptionRow from "./PresetFilterOptionRow";
import FilterIcon from "@mui/icons-material/FilterList";
import {AppIcon} from "hashtagjeff-shared-components";
import {FeaturedFilter, FeaturedFilterInputValue} from "../../table/useTableFilters";
import ReportTableContext from "../../../context/ReportTableContext";
import {useGetFilterOptionCountsQuery} from "../../../store/slice/myContentDashAdminApi";

export const HeaderTableCell = styled(TableCell)`
  border-bottom: 3px solid;
  border-bottom-color: #808080;
` as FC<TableCellProps>;

export type PresetFilterOptionsProps = {
    value: number[],
    onChange: (newValue: FeaturedFilterInputValue) => void,
    featuredFilter: FeaturedFilter,
    closePopover: () => void
}

const PresetFilterOptions: FC<PresetFilterOptionsProps> = ({value, onChange, featuredFilter}) => {
    const [showCountIndex, setShowCountIndex] = useState(-1);
    const {getFeaturedFilterCountParams} = useContext(ReportTableContext);
    const {data: featuredFilterCounts} = useGetFilterOptionCountsQuery(getFeaturedFilterCountParams(featuredFilter));

    const setSelected = (optionId, selected) => {
        if (selected) {
            onChange([...value, optionId]);
        } else {
            onChange(value.filter(existingOptionId => existingOptionId !== optionId));
        }
    }

    const selectOnly = (optionId) => {
        onChange([optionId]);
    }

    const allSelected = featuredFilter.filter_options.every(({id}) => value && value.includes(id));
    const noneSelected = featuredFilter.filter_options.every(({id}) => value && !value.includes(id));
    const someSelected = !allSelected && !noneSelected;

    const selectAll = () => {
        onChange(featuredFilter.filter_options.map(({id}) => id));
    }

    const selectNone = () => {
        onChange([]);
    }

    return <>
        <Table size={"small"} onMouseLeave={() => setShowCountIndex(-1)}>
            <TableHead>
                <TableRow>
                    <HeaderTableCell>
                        <Checkbox
                            size={"small"}
                            indeterminate={someSelected}
                            checked={allSelected}
                            onChange={() => allSelected ? selectNone() : selectAll()}
                            title={"Select All / None"}
                        />
                    </HeaderTableCell>
                    <HeaderTableCell>
                        <AppIcon iconData={featuredFilter.start_icon} defaultIcon={FilterIcon}
                                 className={"valign-icon"}/> {featuredFilter.label}
                    </HeaderTableCell>
                    <HeaderTableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {featuredFilter.filter_options.map((option, index) => (
                    <PresetFilterOptionRow
                        key={option.id}
                        isSelected={value.indexOf(option.id) !== -1}
                        onChange={checked => setSelected(option.id, checked)}
                        onOnlySelect={() => selectOnly(option.id)}
                        filterOption={option}
                        optionCount={featuredFilterCounts?.find(featuredFilterCount => featuredFilterCount.optionId === option.id)}
                        showCount={showCountIndex === index}
                        index={index}
                        setShowCountIndex={() => setShowCountIndex(index)}
                    />
                ))}
            </TableBody>
        </Table>
    </>;
}

export default PresetFilterOptions;