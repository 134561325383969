import React, {useState, useRef} from 'react';
import {Box, Button, ButtonProps, Checkbox, FormControlLabel, Popover} from '@mui/material';
import Column from '../../types/Column';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

type SearchBarColumnSelectProps = {
    selectedColumnIds: number[],
    setSelectedColumnIds: (newColumnIds: number[]) => void,
    availableColumns: Column[]
};

const SearchBarColumnSelect = ({
                                   setSelectedColumnIds,
                                   selectedColumnIds,
                                   availableColumns,
                                   ...props
                               }: SearchBarColumnSelectProps & ButtonProps) => {
    const [open, setOpen] = useState(false);
    const ref = useRef();

    const toggleColumnId = (columnId, checked) => {
        setSelectedColumnIds((checked) ? [...selectedColumnIds, columnId] : selectedColumnIds.filter(id => id !== columnId));
    }

    return (
        <>
            <Button
                {...props}
                onClick={() => setOpen(true)}
                ref={ref}
                startIcon={<ViewColumnIcon/>}
                color={"secondary"}
                data-test={"search-columns"}
            >Search Columns...</Button>
            <Popover
                onClose={() => setOpen(false)}
                anchorEl={ref.current}
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box p={2} data-test={"search-columns-modal"}>
                    {availableColumns.map(column => (
                        <FormControlLabel
                            key={column.id}
                            sx={{
                                display: 'block',
                            }}
                            control={
                                <Checkbox
                                    checked={(selectedColumnIds.includes(column.id))}
                                    onChange={
                                        (e, checked) => toggleColumnId(column.id, checked)
                                    }
                                />}
                            label={column.label}/>
                    ))}
                </Box>
            </Popover>
        </>
    )
}

export default SearchBarColumnSelect;
