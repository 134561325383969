import React, {ForwardedRef, forwardRef, useContext} from "react";
import ReportTodoItem from "../../types/ReportTodoItem";
import ReportContext from "../../context/ReportContext";
import {
    useDeleteReportTodoItemMutation,
    useUpdateReportTodoItemMutation
} from "../../store/slice/myContentDashAdminApi";
import {useSnackbar} from "notistack";
import extractErrorMessageFromError from "../../util/extractErrorMessageFromError";
import {
    Box,
    Checkbox,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const TodoListItem = forwardRef(({todo}: { todo: ReportTodoItem }, ref: ForwardedRef<HTMLDivElement>) => {
    const {report} = useContext(ReportContext);
    const [updateTodoItem] = useUpdateReportTodoItemMutation();
    const {enqueueSnackbar} = useSnackbar();
    const [apiDeleteTodoItem, {isLoading: isDeleting}] = useDeleteReportTodoItemMutation();

    const toggleComplete = async () => {
        try {
            await updateTodoItem({
                id: todo.id,
                status: todo.status === "done" ? "todo" : "done",
                reportId: report.id
            }).unwrap();
        } catch (error) {
            enqueueSnackbar(extractErrorMessageFromError(error), {variant: "error"});
        }
    }

    const deleteTodoItem = async () => {
        try {
            await apiDeleteTodoItem({id: todo.id, reportId: report.id});
        } catch (error) {
            enqueueSnackbar(extractErrorMessageFromError(error), {variant: "error"});
        }
    }

    return <Box ref={ref} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'}>
            <Tooltip title={`Mark ${todo.status === "done" ? "not completed" : "completed"}`}>
                <Checkbox
                    checked={todo.status === "done"}
                    onChange={toggleComplete}
                    sx={{mr: 1, p: 1}}
                    size={"small"}
                />
            </Tooltip>
            <Box flex={1}>
                <Typography
                    sx={{textDecoration: todo.status === "done" ? "line-through" : undefined, mb: -0.5}}
                    variant={'h5'}
                    fontSize={'1rem'}
                >{todo.text}</Typography>
            </Box>
        </Box>
        <IconButton
            size={"small"}
            disabled={isDeleting}
            onClick={deleteTodoItem}
        >
            <DeleteIcon fontSize={"small"}/>
        </IconButton>
    </Box>
});

export default TodoListItem;