import React, {useState} from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import {AppIcon} from "hashtagjeff-shared-components";
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';
import formatNumber from "../../../util/formatNumber";
import Skeleton from "@mui/material/Skeleton";
import {FeaturedFilterCount, FeaturedFilterOption} from "../../table/useTableFilters";

const useStyles = makeStyles(() => ({
    label: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    filterRowHover: {
        backgroundColor: "#F5F5F5"
    }
}));

export type PresetFilterOptionRowProps = {
    isSelected: boolean;
    filterOption: FeaturedFilterOption;
    optionCount: FeaturedFilterCount | null;
    onChange: (checked: boolean) => void;
    onOnlySelect: () => void;
    showCount: boolean;
    index: number;
    setShowCountIndex: (showCountIndex: number | false) => void;
}

const PresetFilterOptionRow = ({
                                   isSelected,
                                   filterOption,
                                   optionCount,
                                   onChange,
                                   onOnlySelect,
                                   showCount,
                                   index,
                                   setShowCountIndex
                               }: PresetFilterOptionRowProps) => {
    const classes = useStyles();
    const [rowClassName, setRowClassName] = useState('');

    const onMouseEnter = () => {
        setRowClassName(classes.filterRowHover);
        setShowCountIndex(index);
    }

    const onMouseExit = () => {
        setRowClassName('');
        setShowCountIndex(false);
    }

    return <TableRow key={index} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseExit()}
                     className={rowClassName}>
        <TableCell>
            <Checkbox size={"small"} checked={isSelected} onChange={event => onChange(event.target.checked)}/>
        </TableCell>
        <TableCell>
            <div className={classes.label}>
                <div>
                    {filterOption.start_icon.name ? <AppIcon iconData={filterOption.start_icon}/> : null}
                    {filterOption.label}
                    {filterOption.end_icon.name ? <AppIcon iconData={filterOption.end_icon}/> : null}
                </div>
                <div>

                </div>
            </div>
        </TableCell>
        <TableCell style={{minWidth: 70, textAlign: "right"}}>
            {showCount ? <Button onClick={onOnlySelect} size={"small"} title={"Apply only this filter value"}
                                 data-test={"filter-only"}>Only</Button> :
                optionCount ?
                    <Typography
                        variant={"caption"}
                        sx={{ml: 2}}
                    >{formatNumber(optionCount.count, 1)}</Typography> : <Skeleton variant="text" width={40}/>
            }
        </TableCell>
    </TableRow>
}

export default PresetFilterOptionRow;