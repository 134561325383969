import * as React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import GoogleSearchConsoleIntegrationCard from "./GoogleSearchConsoleIntegrationCard";

const GSCNotConnectedDialog = ({open, onClose}) => {
    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>
            Connect your Google Account to see Historical Performance
        </DialogTitle>
        <DialogContent>
            <Typography sx={{mb: 2}}>
                By connecting your Google Search Console account, you can see historical performance data for your
                website. This will allow you to see how changes to your content affect real SEO performance.
            </Typography>
            <GoogleSearchConsoleIntegrationCard defaultExpanded/>
        </DialogContent>
        <DialogActions>
            <Button variant={"text"} onClick={onClose}>Close</Button>
        </DialogActions>
    </Dialog>
}

export default GSCNotConnectedDialog;