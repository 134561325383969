import React, {useState} from "react";
import {
    useGenerateSubscriptionCheckoutUrlMutation
} from "../../store/slice/myContentDashAdminApi";
import {Chip, Grid, Paper, Typography} from "@mui/material";
import {BillingPeriod} from "../../types/BillingPeriod";
import {LoadingButton} from "@mui/lab";

type SelectBillingPeriodProps = {
    periods: BillingPeriod[];
    showYearly: boolean;
}


type BillingPeriodCardProps = {
    price: number;
    period: string;
    tagline?: string;
    onSelect: () => void;
    primary?: boolean;
    loading: boolean;
    disabled: boolean;
    buttonText?: string;
}
const BillingPeriodCard = ({price, period, tagline, onSelect, primary = false, loading, disabled, buttonText = "Pick Plan"}: BillingPeriodCardProps) => {

    return <Paper sx={{p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}} elevation={3}>
        <div>
            <Typography variant={'h3'} sx={{display: 'inline'}}>{`$${price / 100}`}</Typography>
            <Typography variant={'caption'}>{` /${period}`}</Typography>
        </div>
        <Chip sx={{mt: 1, visibility: !tagline ? "hidden" : undefined}} size={'small'} label={tagline}/>
        <LoadingButton
            sx={{mt: 1}}
            variant={primary ? 'contained' : 'outlined'}
            size={'small'}
            disabled={disabled}
            loading={loading}
            onClick={() => onSelect()}
        >{buttonText}</LoadingButton>
    </Paper>
}

const SelectBillingPeriod = ({periods, showYearly}: SelectBillingPeriodProps) => {
    const yearlyPlan = periods.find(({period}) => period === "year");
    const monthlyPlan = periods.find(({period}) => period === "month");
    const [generateSubscriptionCheckoutUrl, {isLoading: isRetrievingCheckoutPage}] = useGenerateSubscriptionCheckoutUrlMutation();
    const [selectedPeriod, setSelectedPeriod] = useState<null | "month" | "year">(null);

    const goToCheckout = async (period: "month" | "year") => {
        try {
            setSelectedPeriod(period);
            window.location.href = await generateSubscriptionCheckoutUrl({period}).unwrap();
        } catch (error) {
            console.error(error);
        }
    }

    return <>
        <Typography variant={'h5'} sx={{mb: 2}}> To get started, pick your preferred billing period below. </Typography>
        <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
                <BillingPeriodCard
                    price={monthlyPlan.price}
                    period={"month"}
                    onSelect={() => goToCheckout("month")}
                    disabled={isRetrievingCheckoutPage}
                    loading={isRetrievingCheckoutPage && selectedPeriod === "month"}
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <BillingPeriodCard
                    price={yearlyPlan.price}
                    period={"year"}
                    onSelect={() => goToCheckout("year")}
                    primary
                    tagline={yearlyPlan.tagline}
                    disabled={isRetrievingCheckoutPage}
                    loading={isRetrievingCheckoutPage && selectedPeriod === "year"}
                />
            </Grid>
        </Grid>
    </>
}


export default SelectBillingPeriod;