import React from "react";
import {PageComponent as PageComponentType} from "../../../types/PostTabPage";
import PageComponent from "./PageComponent";

type ComponentsListProps = {
    components: PageComponentType[];
}

const ComponentsList = ({components}: ComponentsListProps) => {
    return <>
        {components.map(component => (
            <PageComponent component={component} key={component.key}/>
        ))}
    </>
}

export default ComponentsList;