import {useAppDispatch, useAppSelector} from "../store/store";
import {Navigate} from "react-router-dom";
import * as React from "react";
import {setJustLoggedOut} from "../store/slice/appSlice";

const NavigateToLogin = () => {
    const location = useAppSelector(state => state.router.location);
    const justLoggedOut = useAppSelector(state => state.app.justLoggedOut);
    const dispatch = useAppDispatch();

    if (justLoggedOut) {
        dispatch(setJustLoggedOut(false));
        return <Navigate to={"/login"}/>
    }

    return <Navigate
        to={"/login?redirectTo=" + encodeURIComponent(location.pathname + location.search)}
    />;
}

export default NavigateToLogin;