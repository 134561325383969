import {Box, IconButton, Popover, Typography} from "@mui/material";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {LoadingButton} from "@mui/lab";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    useCreatePageNoteMutation, useDeletePageNoteMutation,
    useGetNotesQuery, useGetSessionQuery,
    useUpdatePageNoteMutation
} from "../../store/slice/myContentDashAdminApi";
import ReportContext from "../../context/ReportContext";
import {useSnackbar} from "notistack";
import extractErrorMessageFromError from "../../util/extractErrorMessageFromError";
import useRelativeFormattedDate from "../../hooks/useRelativeFormattedDate";
import extractPathFromUrl from "../../util/extractPathFromUrl";
import TextFieldNote from "./TextFieldNote";

type PageNotePopoverProps = {
    open: boolean;
    onClose: () => void;
    pageUrl: string;
    anchorEl: HTMLElement;
}

const SinglePageNotePopover = ({open, onClose, pageUrl, anchorEl}: PageNotePopoverProps) => {
    const {report} = useContext(ReportContext);
    const {data: getNotesResponse} = useGetNotesQuery({reportId: report.id});
    const existingNote = useMemo(() => getNotesResponse?.notes?.find(note => note.page_url === pageUrl), [getNotesResponse?.notes, pageUrl]);
    const [noteText, setNoteText] = useState(existingNote === undefined ? "" : existingNote.text);
    const [updateExistingNote, {isLoading: isUpdatingNote}] = useUpdatePageNoteMutation();
    const [createNewNote, {isLoading: isCreatingNote}] = useCreatePageNoteMutation();
    const [doDeletePageNote, {isLoading: isDeleting}] = useDeletePageNoteMutation();
    const timestamp = useRelativeFormattedDate(existingNote && open ? existingNote.added_on * 1000 : null);
    const textFieldRef = useRef<HTMLTextAreaElement>();
    const {data: session} = useGetSessionQuery();
    const {enqueueSnackbar} = useSnackbar();

    const editedByUser = existingNote ? report.access_users.find(user => user.id === existingNote.added_by) : null;
    const editedByUserName = editedByUser ? (editedByUser.id === session.user.id ? 'you' : editedByUser.first_name) : null;

    useEffect(() => {
        if (existingNote !== undefined && textFieldRef && textFieldRef.current !== document.activeElement) {
            setNoteText(existingNote.text);
        }
    }, [existingNote, textFieldRef]);

    const saveNote = async () => {
        try {
            if (existingNote !== undefined) {
                await updateExistingNote({
                    noteId: existingNote.id,
                    text: noteText
                }).unwrap();
            } else {
                await createNewNote({
                    pageUrl,
                    reportId: report.id,
                    text: noteText
                }).unwrap();
            }
        } catch (error) {
            enqueueSnackbar(extractErrorMessageFromError(error), {variant: 'error'});
        }
    }

    const deleteNote = async () => {
        try {
            await doDeletePageNote({noteId: existingNote.id, reportId: report.id});
            setNoteText('');
        } catch (error) {
            enqueueSnackbar(extractErrorMessageFromError(error), {variant: 'error'})
        }
    }

    const saveBeforeClosing = () => {
        if (noteText !== '' && existingNote?.text !== noteText && !isDeleting && !isCreatingNote && !isUpdatingNote) {
            saveNote();
        }
        onClose();
    }

    return <Popover
        open={open}
        onClose={saveBeforeClosing}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right"
        }}
    >
        <TextFieldNote
            sx={{width: '360px', m: 1, mt: 2}}
            label={'Note'}
            rows={6}
            multiline
            value={noteText}
            onChange={(event) => setNoteText(event.target.value)}
            inputProps={{ref: textFieldRef}}
            disabled={isDeleting}
            helperText={extractPathFromUrl(pageUrl)}
            autoFocus
        />
        <Box display={'flex'} justifyContent={'space-between'} p={1} alignItems={'center'}>
            <div>
                <Typography variant={'caption'} sx={{maxWidth: '230px'}}>
                    {timestamp ? `Edited ${timestamp}${editedByUserName ? ' by ' + editedByUserName : ''}` : ''}
                </Typography>
            </div>
            <div>
                {existingNote !== undefined ?
                    <IconButton onClick={deleteNote} disabled={isDeleting}>
                        <DeleteIcon/>
                    </IconButton> : null}
                <LoadingButton
                    sx={{ml: 1}}
                    disabled={noteText === '' || (existingNote !== undefined && existingNote.text === noteText) || isDeleting}
                    onClick={saveNote}
                    loading={isCreatingNote || isUpdatingNote}
                >Save</LoadingButton>
            </div>
        </Box>
    </Popover>
}

export default SinglePageNotePopover;