import * as React from "react";
import {Box, Typography} from "@mui/material";
import GetHelpButton from "../GetHelpButton";
import SwitchReportsButton from "../SwitchReportsButton";
import DarkThemePaper from "../../template/DarkThemePaper";

const ReportNotFound = ({reportDomain}: { reportDomain: string }) => {
    return <DarkThemePaper title={"Report not found"}>
        <Box maxWidth={'400px'}>
            <Typography color={'error.main'}>
                {`We couldn't find the report for ${reportDomain}. Either it doesn't exist, or you don't have access to it.`}
            </Typography>
            <Box mt={2} display={'flex'} justifyContent={'center'}>
                <GetHelpButton sx={{mr: 1}}/>
                <SwitchReportsButton sx={{ml: 1}}/>
            </Box>
        </Box>
    </DarkThemePaper>
}

export default ReportNotFound;