import React, {forwardRef, useContext, useState} from 'react';
import {IconButton, IconButtonProps} from "@mui/material";
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import useForwardedRef from "../../hooks/useForwardedRef";
import ReportContext from "../../context/ReportContext";
import SinglePageNotePopover from "./SinglePageNotePopover";
import {useGetNotesQuery} from "../../store/slice/myContentDashAdminApi";


const PageNoteActionButton = forwardRef<HTMLButtonElement, Omit<IconButtonProps, 'onClick'> & { pageUrl: string }>((
    ({
         pageUrl,
         ...props
     }, forwardedRef) => {
        const ref = useForwardedRef(forwardedRef);
        const [pageNotePopoverOpen, setPageNotePopoverOpen] = useState(false);
        const {report} = useContext(ReportContext);
        const {data: getNotesResponse} = useGetNotesQuery({reportId: report.id});
        const hasNote = getNotesResponse?.notes?.some(note => note.page_url === pageUrl);
        return <>
            <IconButton
                disabled={getNotesResponse === undefined}
                color={hasNote ? 'primary' : undefined}
                ref={ref}
                onClick={() => setPageNotePopoverOpen(true)}
                {...props}
            >
                <StickyNote2Icon/>
            </IconButton>
            <SinglePageNotePopover
                open={pageNotePopoverOpen}
                onClose={() => setPageNotePopoverOpen(false)}
                anchorEl={ref.current}
                pageUrl={pageUrl as string}/>
        </>
    })
);

export default PageNoteActionButton;