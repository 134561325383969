import React, {useState} from "react";
import AuthPaper from "../template/AuthPaper";
import {Typography} from "@mui/material";
import {Link, Navigate} from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LeftRightAlign from "../components/LeftRightAlign";
import LoadingButton from "@mui/lab/LoadingButton";
import Collapse from "@mui/material/Collapse";
import DarkThemePaper from "../template/DarkThemePaper";
import {useResetUserPasswordMutation} from "../store/slice/myContentDashAdminApi";

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false);
    const [doPasswordReset] = useResetUserPasswordMutation();

    if (passwordResetSuccessful) {
        return <Navigate to={"/login?passwordReset=1"}/>
    }

    const params = new URLSearchParams(window.location.search);
    if (!params.has("token") || !params.has("email")) {
        return <AuthPaper title={"Reset Password"}>
            <Typography>Invalid password reset link.</Typography>
            <Link to={"/login"}><Button variant={"contained"} sx={{mt: 2}}>Back to login page</Button></Link>
        </AuthPaper>
    }

    const resetPassword = async () => {
        setIsLoading(true);
        setError(null);
        try {
            await doPasswordReset({
                email: params.get("email"),
                resetToken: params.get("token"),
                password: newPassword
            });
            setPasswordResetSuccessful(true);
        } catch (error) {
            setError(error.data.message ? error.data.message : "An unexpected error occurred, try sending the password reset email again.")
        }
        setIsLoading(false);
    }

    return <DarkThemePaper title={"Reset Password"}>
        <Box sx={{width: "300px"}}>
            <Typography>Enter your new password below.</Typography>
            <TextField
                label={"New Password"}
                type={"password"}
                fullWidth
                sx={{mt: 2}}
                value={newPassword}
                onChange={event => setNewPassword(event.target.value)}
            />
            <TextField
                label={"Confirm Password"}
                type={"password"}
                fullWidth
                sx={{mt: 2}}
                value={confirmPassword}
                onChange={event => setConfirmPassword(event.target.value)}
            />
            <LeftRightAlign
                right={<LoadingButton
                    variant={"contained"}
                    sx={{mt: 2}}
                    disabled={newPassword === "" || newPassword !== confirmPassword}
                    onClick={resetPassword}
                    loading={isLoading}
                >Reset Password</LoadingButton>}
            />
            <Collapse in={error !== null}>
                <Typography color={"error"} mt={2}>{error}</Typography>
            </Collapse>
        </Box>
    </DarkThemePaper>;
}

export default ResetPassword;