import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import PropTypes from "prop-types";
import CenteredElement from "./CenteredElement";
import MyContentDashLogo from "./MyContentDashLogo";
import {styled, Typography} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import themeOverrides from "../styles/themeOverrides";
import {ThemeProvider} from "@mui/styles";

const theme = createTheme({
    ...themeOverrides,
    palette: {
        ...themeOverrides.palette,
        mode: "dark"
    }
});

const FullPageLogo = styled(MyContentDashLogo)`
  width: 500px;
`

const FullPageSpinner = ({error}: {error?: string | null}) => {

    return <ThemeProvider theme={theme}>
        <div style={{width: "100%", height: "100%", position: "absolute", top: "0", backgroundColor: "#29333c"}}>
            <div style={{width: "100%", height: "50%"}}>
                <LinearProgress/>
                <CenteredElement>
                    <FullPageLogo variant={"banner"}/>
                    {error ?
                        (<>
                            <Typography variant={"h4"} color={"error"}>An error occurred</Typography>
                            <pre style={{color: "#F44336"}}>{error}</pre>
                        </>)
                        : null}
                </CenteredElement>
            </div>
        </div>
    </ThemeProvider>;
}

FullPageSpinner.propTypes = {
    error: PropTypes.string
}

export default FullPageSpinner;