import {useAppSelector} from "../store/store";
import {reportPagePathnameSelector} from "../store/slice/reportSelector";

import {useContext} from "react";
import ReportContext from "../context/ReportContext";
import {ReportPage} from "../types/ReportPage";

type TrackedPagesPage = {
    id: 'tracked-pages',
    label: 'Tracked Pages',
}

const useCurrentPage = (): ReportPage | TrackedPagesPage => {
    const {report} = useContext(ReportContext);
    const pages = report.pages;
    const currentPathname = useAppSelector(reportPagePathnameSelector);

    if(currentPathname === "tracked-pages") {
        return {
            id: 'tracked-pages',
            label: 'Tracked Pages'
        }
    }

    if (pages) {
        for (let pageData of pages) {
            if (pageData.route === "/" + currentPathname) {
                return pageData;
            }
        }
    }
    return null;
}

export default useCurrentPage;