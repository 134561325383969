import {FC, useContext, useState} from "react";
import globalVars from "../../styles/globalVars";
import {Box, Drawer, SvgIconTypeMap} from "@mui/material";
import RankingsPanelContents from "./search-console-rankings/RankingsPanelContents";
import React from "react";
import PostDetailsTabs from "./PostDetailsTabs";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import PostTabPageContents from "./post-info/PostTabPageContents";
import ReportContext from "../../context/ReportContext";
import {isSearchConsoleConfigured} from "../../util/isSearchConsoleConfigured";

export type WebsitePageMeta = {
    url: string;
    published: number;
    modified: number;
}

export type PostDetailsPanelProps = {
    open: boolean;
    onClose: () => void;
    page: WebsitePageMeta | undefined;
    searchConsoleDayRanges: number[];
    searchConsole?: boolean;
}

export type PostDetailsTabType = {
    id: string;
    label: string;
    icon: OverridableComponent<SvgIconTypeMap>;
    component: FC<PostDetailsPanelContentsProps>;
}

export type PostDetailsPanelContentsProps = {
    dayRanges: number[];
    page: WebsitePageMeta | undefined;
}

const PostDetailsPanel = ({
                              open,
                              onClose,
                              page,
                              searchConsoleDayRanges,
                              searchConsole = undefined
                          }: PostDetailsPanelProps) => {
    const {report} = useContext(ReportContext);
    const enableSearchConsole = searchConsole === undefined ? isSearchConsoleConfigured(report) : searchConsole;
    const [activeTab, setActiveTab] = useState<string | number>(
        enableSearchConsole ? "searchConsole" : report.post_tab_pages[0]?.id || 0
    );

    return <Drawer
        open={open}
        onClose={onClose}
        anchor={'right'}
        transitionDuration={150}
        PaperProps={{
            sx: {width: "calc(100% - " + globalVars.drawerWidthExpanded + ")", overflow: "inherit"}
        }}
    >
        {page === undefined ? null : <>
            <Box sx={{overflowY: "auto"}}>
                <Box display={activeTab === "searchConsole" ? undefined : "none"} width='100%'>
                    <RankingsPanelContents page={page} dayRanges={searchConsoleDayRanges}/>
                </Box>
                {report.post_tab_pages.map(postTabPage => (
                    <Box display={activeTab === postTabPage.id ? undefined : "none"} width='100%' key={postTabPage.id}>
                        <PostTabPageContents
                            postTabPage={postTabPage}
                            pageUrl={page.url}
                            key={postTabPage.id}
                            isActive={activeTab === postTabPage.id}
                        />
                    </Box>
                ))}
            </Box>
            <PostDetailsTabs
                activeTab={activeTab}
                onChange={setActiveTab}
                onClose={onClose}
            />
        </>}
    </Drawer>
}

export default PostDetailsPanel;