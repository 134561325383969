import {Box, Popover} from "@mui/material";
import React, {useContext, useMemo, useRef, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {
    useCreatePageNoteMutation,
    useGetNotesQuery,
    useUpdatePageNoteMutation
} from "../../store/slice/myContentDashAdminApi";
import ReportContext from "../../context/ReportContext";
import {useSnackbar} from "notistack";
import extractErrorMessageFromError from "../../util/extractErrorMessageFromError";
import TextFieldNote from "./TextFieldNote";

type MultiPageNotePopoverProps = {
    open: boolean;
    onClose: () => void;
    pageUrls: readonly string[];
    anchorEl: HTMLElement;
}

const MultiPageNotePopover = ({open, onClose, pageUrls, anchorEl}: MultiPageNotePopoverProps) => {
    const {report} = useContext(ReportContext);
    const {data: getNotesResponse} = useGetNotesQuery({reportId: report.id});
    const existingNotes = useMemo(() => getNotesResponse?.notes?.filter(note =>  pageUrls.indexOf(note.page_url) !== -1), [getNotesResponse?.notes, pageUrls]);
    const [noteText, setNoteText] = useState("");
    const [updateExistingNote, {isLoading: isUpdatingNote}] = useUpdatePageNoteMutation();
    const [createNewNote, {isLoading: isCreatingNote}] = useCreatePageNoteMutation();
    const textFieldRef = useRef<HTMLTextAreaElement>();
    const {enqueueSnackbar} = useSnackbar();

    const saveNotes = () => {
        pageUrls.forEach(saveNote);
        setNoteText("");
        onClose();
    }

    const saveNote = async (url, index) => {
        try {
            if (existingNotes[index] !== undefined) {
                await updateExistingNote({
                    noteId: existingNotes[index].id,
                    text: existingNotes[index].text + "\n" + noteText
                }).unwrap();
            } else {
                await createNewNote({
                    pageUrl: url,
                    reportId: report.id,
                    text: noteText
                }).unwrap();
            }
        } catch (error) {
            enqueueSnackbar(extractErrorMessageFromError(error), {variant: 'error'});
        }
    }

    const saveBeforeClosing = () => {
        if (noteText !== '' && !isCreatingNote && !isUpdatingNote) {
            saveNotes();
        }
        setNoteText("");
        onClose();
    }

    return <Popover
        open={open}
        onClose={saveBeforeClosing}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
    >
        <TextFieldNote
            sx={{width: '360px', m: 1, mt: 2}}
            label={'Note'}
            rows={6}
            multiline
            value={noteText}
            onChange={(event) => setNoteText(event.target.value)}
            inputProps={{ref: textFieldRef}}
            helperText={"Will be added to " + pageUrls.length + " pages"}
            autoFocus
        />
        <Box display={'flex'} justifyContent={'space-between'} p={1} alignItems={'center'}>
            <div>
                <LoadingButton
                    sx={{ml: 1}}
                    disabled={noteText === ''}
                    onClick={saveNotes}
                    loading={isCreatingNote || isUpdatingNote}
                >Save</LoadingButton>
            </div>
        </Box>
    </Popover>
}

export default MultiPageNotePopover;