import React from "react";
import PropTypes from "prop-types";
import {Box} from "@mui/material";

const LeftRightAlign = ({left, right}) => {

    return <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <div>
            {left}
        </div>
        <div>
            {right}
        </div>
    </Box>;
}

LeftRightAlign.propTypes = {
    left: PropTypes.node,
    right: PropTypes.node
}

export default LeftRightAlign;