import {useState} from "react";
import {useStartBillingPortalSessionMutation} from "../store/slice/myContentDashAdminApi";
import extractErrorMessageFromError from "../util/extractErrorMessageFromError";

const useBillingPortal = () => {
    const [error, setError] = useState<null | string>(null);
    const [startBillingPortalSession, {isLoading}] = useStartBillingPortalSessionMutation();

    const navigateToBillingPortal = async () => {
        setError(null);
        try {
            window.location.href = await startBillingPortalSession().unwrap();
        } catch (error) {
            console.error(error);
            setError(extractErrorMessageFromError(error));
        }
    }

    return {
        error,
        navigateToBillingPortal,
        isLoadingSession: isLoading
    }
}

export default useBillingPortal;