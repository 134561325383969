import React, {useState} from "react";
import Box from "@mui/material/Box";
import {TextField} from "@mui/material";
import LeftRightAlign from "../components/LeftRightAlign";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import {Navigate} from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import DarkThemePaper from "../template/DarkThemePaper";
import {useSendPasswordResetEmailMutation} from "../store/slice/myContentDashAdminApi";

const SendPasswordReset = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState<null | string>(null);
    const [success, setSuccess] = useState(false);
    const [doSendPasswordReset] = useSendPasswordResetEmailMutation();

    if (success) {
        return <Navigate to={"/login?passwordResetSent=1"}/>
    }

    const sendPasswordResetEmail = async () => {
        setIsLoading(true);
        setError(null);
        try {
            await doSendPasswordReset({email});
            setIsLoading(false);
            setSuccess(true);
        } catch (error) {
            setError(error.data.message ? error.data.message : "An unexpected error occurred.")
            setIsLoading(false);
        }
    }

    return <DarkThemePaper title={"Reset Password"}>
        <Box sx={{width: "300px"}}>
            <Typography>Enter your email to send a one time use password reset link to your inbox.</Typography>
            <TextField
                label={"Email Address"}
                fullWidth
                sx={{mt: 2}}
                value={email}
                onChange={event => setEmail(event.target.value)}
                autoComplete={"email"}
            />
            <Collapse in={error !== null}>
                <Typography color={"error"}>{error}</Typography>
            </Collapse>
            <LeftRightAlign
                right={<LoadingButton
                    variant={"contained"}
                    loading={isLoading}
                    sx={{mt: 2}}
                    onClick={sendPasswordResetEmail}
                >Send Password Reset Email</LoadingButton>}
            />
        </Box>
    </DarkThemePaper>;
}

SendPasswordReset.propTypes = {}

export default SendPasswordReset;