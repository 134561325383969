import React from 'react';
import {Divider, Drawer, Typography} from "@mui/material";
import AddTodoItemTextField from "./AddTodoItemTextField";
import TodoItemsList from "./TodoItemsList";
import {useAppDispatch} from "../../store/store";
import {setSearch} from "../../store/slice/todoPanelSlice";

const TodoItemsPanel = ({open, onClose}: { open: boolean, onClose: () => void }) => {
    const dispatch = useAppDispatch();
    return <Drawer
        open={open}
        onClose={onClose}
        anchor={"right"}
        PaperProps={{sx: {width: "600px", p: 2}}}
        onTransitionEnd={() => !open ? dispatch(setSearch('')) : null}
    >
        <Typography variant={"h3"} textAlign={"center"}>Todo</Typography>
        <AddTodoItemTextField sx={{mt: 2}}/>
        <Divider sx={{my: 2}}/>
        <TodoItemsList sx={{pb: 2}}/>
    </Drawer>
}

export default TodoItemsPanel;