import * as React from 'react';
import {useEffect, useMemo, useRef, useState} from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    Plugin,
    PointElement,
    Title,
    Tooltip
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {SearchConsoleResults} from "../../../types/SearchConsoleResults";
import {format} from "date-fns";
import {Box, Checkbox, FormControlLabel} from "@mui/material";
import formatNumber from "../../../util/formatNumber";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const defaultChartOptions: ChartOptions<"line"> = {
    responsive: true,
    plugins: {
        legend: {
            display: false
        },
        title: {
            display: false
        },
    },
    scales: {
        Impressions: {
            type: 'linear',
            position: 'right',
            ticks: {
                count: 6
            }
        },
        Clicks: {
            type: 'linear',
            position: 'right',
            ticks: {
                count: 6
            }
        }
    },
    aspectRatio: 5
};

const datasetColors = [
    {
        borderColor: '#FB9039',
        backgroundColor: 'rgba(251,144,57,0.3)',
    },
    {
        borderColor: '#588DC9',
        backgroundColor: 'rgba(88,141,201,0.3)',
    }
]

const dataset = {
    pointHitRadius: 10,
    tension: 0.1,
    ticks: {
        autoSkip: true,
        maxTicksLimit: 5
    }
}

const datasetScale = {
    type: 'linear',
    ticks: {
        count: 6
    }
}
type PageRankingsProps = {
    rankingHistory: SearchConsoleResults;
    centerDate: number;
    verticalBarLabel: string;
}

const availableDatasets = {
    "Clicks": "clicks",
    "Clickthrough Rate": "ctr",
    "Impressions": "impressions",
    "Position": "position"
}

const PageRankingsChart = ({rankingHistory, centerDate, verticalBarLabel = "Updated"}: PageRankingsProps) => {
    const [datasetsToShow, setDatasetsToShow] = useState(["Clicks"]);
    const chartRef = useRef(null);
    const centerDateFormatted = format(new Date(centerDate), "yyyy-LL-dd")
    const plugins: Plugin[] = useMemo(() => [
        {
            id: "drawDateLine",
            afterDraw: (chart) => {
                // eslint-disable-next-line no-underscore-dangle
                // find coordinates of tooltip
                const {ctx} = chart;
                const scale = chart.scales[Object.keys(chart.scales).filter(scaleId => scaleId !== "x")[0]]
                const topY = scale.top;
                const bottomY = scale.bottom;

                const points = chart.getDatasetMeta(0).data;
                const pointIndex = (chartRef.current.rankingHistory as SearchConsoleResults).rows.findIndex(row => row.keys[0] === chartRef.current.centerDateFormatted);
                if (pointIndex === -1) {
                    return;
                }

                const point = points[pointIndex + 1];
                if(!point) {
                    return;
                }
                // @ts-ignore
                const x = point.x;

                // draw vertical line
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, topY);
                ctx.lineTo(x, bottomY);
                ctx.lineWidth = 3;
                ctx.strokeStyle = 'rgba(251,144,57,0.5)';
                ctx.setLineDash([5, 5]);
                ctx.stroke();
                ctx.restore();

                ctx.save();
                ctx.translate(x + 7, bottomY - 7);
                ctx.rotate(-45 * (Math.PI / 180));
                ctx.font = "12px Montserrat";
                ctx.fillText(chartRef.current.verticalBarLabel, 0, 0)
                ctx.restore();
            },
        },
    ], []);

    useEffect(() => {
        if (!chartRef.current) {
            return;
        }
        chartRef.current.rankingHistory = rankingHistory;
        chartRef.current.centerDateFormatted = centerDateFormatted;
        chartRef.current.verticalBarLabel = verticalBarLabel;
        chartRef.current.update();
    }, [centerDateFormatted, rankingHistory, verticalBarLabel]);


    const chartOptions = {
        ...defaultChartOptions,
        scales: {
            x: {
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 26
                }
            },
            ...Object.fromEntries(datasetsToShow.map((datasetName, index) => [
                datasetName,
                {
                    ...datasetScale,
                    position: index === 0 ? "left" : "right",
                    ticks: {
                        ...datasetScale.ticks,
                        callback: function (value) {
                            if (datasetName !== "Clickthrough Rate") {
                                return formatNumber(value, 2);
                            }

                            return (Math.round(parseFloat(value) * 10000) / 100) + "%";
                        },
                    }
                }
            ]))
        }
    };

    const datasets = datasetsToShow.map((datasetName, index) => ({
        ...dataset,
        ...datasetColors[index],
        label: datasetName,
        yAxisID: datasetName,
        data: rankingHistory.rows.map(row => row[availableDatasets[datasetName]])
    }));

    const labels = rankingHistory.rows.map(row => format(new Date(row.keys[0]), "MMM do"))
    const data = {
        labels,
        datasets
    };

    // @ts-ignore
    return <>
        <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            {Object.keys(availableDatasets).map(datasetName => (
                <FormControlLabel
                    key={datasetName}
                    control={
                        <Checkbox
                            disabled={datasetsToShow.includes(datasetName) ? datasetsToShow.length === 1 : datasetsToShow.length === 2}
                            checked={datasetsToShow.includes(datasetName)}
                            onChange={((event, checked) => setDatasetsToShow(
                                checked ? [...datasetsToShow, datasetName] : datasetsToShow.filter(dataset => dataset !== datasetName)
                            ))}
                        />
                    }
                    componentsProps={{
                        typography: {
                            style: {
                                color: datasetsToShow.includes(datasetName) ? datasetColors[datasetsToShow.indexOf(datasetName)].borderColor : undefined
                            }
                        }
                    }}
                    label={datasetName}
                />
            ))}
        </Box>
        <Box mt={-2}>
            <Line ref={chartRef} options={chartOptions} data={data} height={null} width={null} plugins={plugins}/>
        </Box>
    </>
}

export default PageRankingsChart;