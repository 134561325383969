import * as React from 'react';
import {useEffect, useState} from 'react';
import {Report} from '../../../../types/Report';
import {GoogleIntegrationSetup} from '../../../../types/GoogleIntegration';
import {Box, Collapse, MenuItem, TextField, Typography} from '@mui/material';
import useEffectWhen from '../../../../util/useEffectWhen';
import {useConfigureAnalyticsConnectionMutation} from '../../../../store/slice/myContentDashAdminApi';

type GoogleAnalyticsConfigurationProps = {
    report: Report,
    googleIntegration: GoogleIntegrationSetup
}

const GoogleAnalyticsConfiguration = ({report, googleIntegration}: GoogleAnalyticsConfigurationProps) => {
    const [accountTypeOpen, setAccountTypeOpen] = useState(false);
    const [accountSelectOpen, setAccountSelectOpen] = useState(false);
    const [propertySelectOpen, setPropertySelectOpen] = useState(false);
    const [viewSelectOpen, setViewSelectOpen] = useState(false);
    const [selectedType, setSelectedType] = useState(googleIntegration.analytics_type);
    const [selectedAccount, setSelectedAccount] = useState(googleIntegration.analytics_type === 'UA' ? googleIntegration.analytics.account : googleIntegration.analytics4.account);
    const [selectedProperty, setSelectedProperty] = useState(googleIntegration.analytics_type === 'UA' ? googleIntegration.analytics.property : googleIntegration.analytics4.property);
    const [selectedView, setSelectedView] = useState(googleIntegration.analytics.view);
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState<null | string>(null);
    const [configureGoogleAnalytics] = useConfigureAnalyticsConnectionMutation();

    const availableProperties = selectedAccount ? (selectedType === 'UA' ? googleIntegration.analytics_accounts.find(account => account.id === selectedAccount).properties :
        googleIntegration.analytics_accounts.find(account => account.id === selectedAccount).properties_ga4) : [];

    const availableUAProperties = (selectedAccount && selectedType === 'UA') ? googleIntegration.analytics_accounts.find(account => account.id === selectedAccount).properties : [];
    const availableViews = (selectedProperty && selectedType === 'UA') ? availableUAProperties.find(property => property.id === selectedProperty).views : [];

    const textFieldProps = {
        select: true,
        fullWidth: true,
        sx: {my: 1}
    }

    const saveChanges = async () => {
        setIsSaving(true);
        setSaveError(null);

        try {
            await configureGoogleAnalytics({
                reportId: report.id,
                type: selectedType,
                accountId: selectedAccount,
                propertyId: selectedProperty,
                viewId: selectedView
            }).unwrap();
        } catch (error) {
            setSaveError(error.data.message);
        }

        setIsSaving(false);
    }

    useEffectWhen(() => {
        if ((selectedType === 'UA' && (selectedView === null || selectedView === googleIntegration.analytics.view)) ||
            (selectedType === 'GA4' && (selectedProperty === null || selectedProperty === googleIntegration.analytics4.property)) || selectedType === null) {
            return;
        }
        saveChanges();
    }, [selectedAccount, selectedProperty, selectedView, selectedType, googleIntegration], [selectedView, selectedProperty]);

    useEffect(() => {
        setSelectedAccount(googleIntegration.analytics_type === 'UA' ? googleIntegration.analytics.account : googleIntegration.analytics4.account)
        setSelectedProperty(googleIntegration.analytics_type === 'UA' ? googleIntegration.analytics.property : googleIntegration.analytics4.property)
        setSelectedView(googleIntegration.analytics.view)
        setSelectedType(googleIntegration.analytics_type)
    }, [googleIntegration.analytics, googleIntegration.analytics4, googleIntegration.analytics_type]);

    return <>
        <TextField
            disabled={googleIntegration.lock_configuration}
            label={'Analytics Type'}
            value={selectedType || 'GA4'}
            onChange={event => {
                setSelectedView(null);
                setSelectedProperty(null);
                setSelectedType(event.target.value);
            }}
            SelectProps={{
                open: accountTypeOpen,
                onClose: () => setAccountTypeOpen(false),
                onOpen: () => setAccountTypeOpen(true)
            }}
            {...textFieldProps}
        >
            <MenuItem value={'GA4'}>GA4</MenuItem>
            <MenuItem value={'UA'}>Universal Analytics</MenuItem>
        </TextField>
        <TextField
            disabled={googleIntegration.lock_configuration}
            label={'Account'}
            value={selectedAccount || ''}
            onChange={event => {
                setSelectedView(null);
                setSelectedProperty(null);
                setSelectedAccount(event.target.value === '' ? null : event.target.value);
                setAccountSelectOpen(false);
                setPropertySelectOpen(true);
            }}
            SelectProps={{
                open: accountSelectOpen,
                onClose: () => setAccountSelectOpen(false),
                onOpen: () => setAccountSelectOpen(true)
            }}
            {...textFieldProps}
        >
            <MenuItem value={''}>Select...</MenuItem>
            {googleIntegration.analytics_accounts.map(account => (
                <MenuItem value={account.id} key={account.id}>{account.name}</MenuItem>
            ))}
        </TextField>
        <TextField
            label={'Property'}
            value={selectedProperty || ''}
            onChange={event => {
                setSelectedView(null);
                setSelectedProperty(event.target.value === '' ? null : event.target.value)
                setPropertySelectOpen(false);
                setViewSelectOpen(selectedType === 'UA');
            }}
            disabled={selectedAccount === null || googleIntegration.lock_configuration}
            SelectProps={{
                open: propertySelectOpen,
                onClose: () => setPropertySelectOpen(false),
                onOpen: () => setPropertySelectOpen(true)
            }}
            {...textFieldProps}
        >
            <MenuItem value={''}>Select...</MenuItem>
            {availableProperties.map(property => (
                <MenuItem value={property.id} key={property.id}>{property.name}</MenuItem>
            ))}
        </TextField>
        <Collapse in={selectedType === 'UA'}>
            <TextField
                label={'View'}
                value={selectedView || ''}
                onChange={event => {
                    setSelectedView(event.target.value === '' ? null : event.target.value)
                }}
                disabled={selectedProperty === null || googleIntegration.lock_configuration}
                SelectProps={{
                    open: viewSelectOpen,
                    onClose: () => setViewSelectOpen(false),
                    onOpen: () => setViewSelectOpen(true)
                }}
                {...textFieldProps}
            >
                <MenuItem value={''}>Select...</MenuItem>
                {availableViews.map(view => (
                    <MenuItem value={view.id} key={view.id}>{view.name}</MenuItem>
                ))}
            </TextField>
        </Collapse>
        <Box visibility={isSaving ? 'visible' : 'hidden'}>
            <Typography color={'success.main'} fontSize={'0.8em'}>Saving...</Typography>
        </Box>
        <Collapse in={saveError !== null}>
            <Typography color={'error.main'} fontSize={'0.8em'}>{saveError}</Typography>
        </Collapse>
    </>
}


export default GoogleAnalyticsConfiguration;