import * as React from 'react';
import {forwardRef} from 'react';
import {Box, Skeleton, SxProps, Typography} from "@mui/material";
import {grey} from "@mui/material/colors";
import formatNumber from "../../../util/formatNumber";
import NumberDifferencePercentage from "./NumberDifferencePercentage";

export type FeaturedRankingStatProps = {
    label: string;
    value: number;
    displayValue?: number;
    previousValue: number;
    sx?: SxProps;
    reverseDifferencePercentage?: boolean;
}

export type FeaturedRankingStatTemplateProps = {
    label: React.ReactChild;
    value: React.ReactChild;
    diffPercentage: React.ReactChild;
    diffColor?: string;
    sx?: SxProps;
}

export const FeaturedRankingStatTemplate = forwardRef<HTMLDivElement, FeaturedRankingStatTemplateProps>(({
                                                                                                             label,
                                                                                                             value,
                                                                                                             diffPercentage,
                                                                                                             sx,
                                                                                                             ...props
                                                                                                         }, ref) => {
    return <Box
        sx={sx}
        display={'inline-flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        alignItems={'center'}
        ref={ref}
        {...props}
    >
        <Typography color={grey["800"]} fontSize={"18px"} sx={{mb: -1}}>{label}</Typography>
        <Typography color={'black'} fontSize={"36px"}>{value}</Typography>
        <Box
            display={'flex'}
            justifyContent={'center'}
            mt={-1}
        >
            {diffPercentage}
        </Box>
    </Box>
});

export const FeaturedRankingStatSkeleton = ({label, sx}: { label: string, sx?: SxProps }) => {
    return <FeaturedRankingStatTemplate
        sx={sx}
        label={label}
        value={<Skeleton width={"100px"} animation={"wave"}/>}
        diffPercentage={<Skeleton width={"39px"} animation={"wave"}/>}
    />
}

const FeaturedRankingStat = forwardRef<HTMLDivElement, FeaturedRankingStatProps>(({
                                                                                      label,
                                                                                      value,
                                                                                      sx,
                                                                                      previousValue,
                                                                                      displayValue = value,
                                                                                      reverseDifferencePercentage = false,
                                                                                      ...props
                                                                                  }, ref) => {

    return <FeaturedRankingStatTemplate
        sx={sx}
        label={label}
        ref={ref}
        value={displayValue < 1 ? "--" : formatNumber(displayValue, 1)}
        diffPercentage={<NumberDifferencePercentage
            newValue={value}
            previousValue={previousValue}
            reverseDirection={reverseDifferencePercentage}
        />}
        {...props}
    />
});

export default FeaturedRankingStat;