import * as React from 'react';
import {useState} from 'react';
import {Box, Collapse, Divider, IconButton, Paper} from "@mui/material";
import {styled} from "@mui/material/styles";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const IntegrationCardPaper = styled(Paper)`
  padding: ${({theme}) => theme.spacing(2)};
` as typeof Paper;

const StatusIndicator = styled("div")<{ $connected: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-bottom: 4px;
  display: inline-block;
  background-color: ${({$connected, theme}) => $connected ? theme.palette.success.light : theme.palette.grey['400']};
  position: absolute;
  margin-top: -10px;
  margin-left: -10px;
`

type IntegrationCardProps = {
    children: React.ReactNode;
    header: string | React.ReactNode | React.ReactNode[];
    isConnected: boolean;
    defaultExpanded?: boolean;
}

const IntegrationCard = ({children, header, isConnected, defaultExpanded = false}: IntegrationCardProps) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    return <IntegrationCardPaper
        elevation={3}
        sx={{display: "block", my: 2, width: "100%", boxSizing: "border-box"}}
    >
        <StatusIndicator $connected={isConnected}/>
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Box width={"100%"} onClick={() => setIsExpanded(!isExpanded)} style={{cursor: "pointer"}}>
                <Box height={"35px"} pl={2}>
                    {header}
                </Box>
            </Box>
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
            </IconButton>
        </Box>
        <Collapse in={isExpanded}>
            <Divider sx={{my: 2}}/>
            <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                <Box width={"350px"}>
                    {children}
                </Box>
            </Box>
        </Collapse>
    </IntegrationCardPaper>
}

export default IntegrationCard;