import {useGetTrackedPagesQuery} from "../../../store/slice/myContentDashAdminApi";
import {useContext, useState} from "react";
import ReportContext from "../../../context/ReportContext";
import {
    Box,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TextField
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import React from "react";
import TablePagination from "@mui/material/TablePagination";
import TrackedPageRow from "./TrackedPageRow";

const TrackedPagesTable = () => {
    const {report} = useContext(ReportContext);
    const [search, setSearch] = useState("");
    const [pagination, setPagination] = useState({
        page: 0,
        perPage: 10,
        search: search
    });

    const applySearch = () => {
        setPagination({...pagination, search});
    }

    const {data: pagesResponse, isFetching} = useGetTrackedPagesQuery({
        ...pagination,
        reportId: report.id,
        search: pagination.search
    });
    const searchedPages = pagesResponse?.tracked_pages.filter(page => page.page_url.includes(pagination.search));
    const displayPages = searchedPages?.slice(pagination.page * pagination.perPage, pagination.page * pagination.perPage + pagination.perPage);

    const tablePagination = <TablePagination
            count={searchedPages?.length || -1}
            page={pagination.page}
            rowsPerPage={pagination.perPage}
            onPageChange={(event, newPage) => setPagination({...pagination, page: newPage})}
            onRowsPerPageChange={(event) => {
                setPagination({...pagination, perPage: parseInt(event.target.value)})
            }}
            labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} ${count !== -1 ? `of ${count}` : ``}`; }}
        />

    return <>
        {isFetching ? <LinearProgress/> : null}
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TextField
                                value={search}
                                onChange={event => {
                                    setSearch(event.target.value)
                                    if(event.target.value === "") {
                                        setPagination({...pagination, search: event.target.value});
                                    }
                                }}
                                label={"Search..."}
                                size={'small'}
                                onKeyDown={event => event.key === "Enter" ? applySearch() : undefined}
                            />
                        </TableCell>
                        {tablePagination}
                    </TableRow>
                    <TableRow>
                        <TableCell>Page URL</TableCell>
                        <TableCell>Tracker Type</TableCell>
                        <TableCell>
                            <Box display={'flex'} justifyContent={'right'}>Actions</Box>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pagesResponse !== undefined ? displayPages.map(page => (
                        <TrackedPageRow trackedPage={page} key={page.id}/>
                    )) : null}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {tablePagination}
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    </>
}

export default TrackedPagesTable;