import {SerializedError} from "@reduxjs/toolkit";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

const extractErrorMessageFromError = (error: SerializedError | FetchBaseQueryError): string => {
    if('data' in error) {
        const errorData = (error.data as {error?: string, message?: string});
        return errorData.error ||errorData.message || "An unexpected error occurred";
    }
    if('message' in error && error.message) {
        return error.message;
    }

    return "An unexpected error occurred";
}

export default extractErrorMessageFromError;