import { styled } from "@mui/styles";
import {PageComponentProps} from "../PageComponent";
import {Box, Grid, gridClasses} from "@mui/material";
import React from "react";
import {PageComponentColumns as PageComponentColumnsType, PostComponentColumnsColumn} from "../../../../types/PostTabPage";
import ComponentsList from "../ComponentsList";

const ColumnsGridContainer = styled(Grid)(({theme}) => ({
    ['& > .' + gridClasses.root + ':not(:last-child)']: {
        borderRight: '1px solid ' + theme.palette.grey['400']
    }
}));

const PageComponentColumns = ({component}: PageComponentProps) => {
    return <ColumnsGridContainer container>
        {(component as PageComponentColumnsType).columns.map(column => (
            <ColumnGridItem key={column.key} column={column}/>
        ))}
    </ColumnsGridContainer>
}

const ColumnGridItem = ({column}: {column: PostComponentColumnsColumn}) => {
    return <Grid item xs={column.width} key={column.key}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} height={'100%'}>
            <Box flex={1}>
                <ComponentsList
                    components={column.children}
                />
            </Box>
        </Box>
    </Grid>
}

export default PageComponentColumns;