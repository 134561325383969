import * as React from "react";
import {Divider, Typography} from "@mui/material";
import BuyWebsiteAddons from "./BuyWebsiteAddons";
import makePlural from "../../util/makePlural";
import {useGetSessionQuery} from "../../store/slice/myContentDashAdminApi";

const WebsiteAddonRequired = () => {
    const {data: session} = useGetSessionQuery();
    const user = session.user;

    return <>
        <Typography color={"warning.main"}>You've connected the maximum number of websites for your plan. </Typography>
        <Typography>{`On your current plan, you can connect up to ${user.report_connection_limit} ${makePlural(user.report_connection_limit, "website")}. 
        To add more, you will need to purchase additional slots.`}</Typography>
        <Divider sx={{my: 2}}/>
        <BuyWebsiteAddons/>
    </>
}

export default WebsiteAddonRequired;