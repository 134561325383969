import {styled} from "@mui/material/styles";
import mcdTheme from "../../styles/themeOverrides";
import {Button, Paper, Tab, Tabs} from "@mui/material";
import React, {useContext} from "react";
import {isSearchConsoleConfigured} from "../../util/isSearchConsoleConfigured";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import {AppIcon} from "hashtagjeff-shared-components";
import ReportContext from "../../context/ReportContext";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

type PostDetailsTabsProps = {
    onChange: (tabId: string) => void;
    activeTab: string | number;
    onClose: () => void;
}

const TabsContainer = styled(Paper)(({theme}: {theme: typeof mcdTheme}) => ({
    position: 'absolute',
    width: '100px',
    left: '-100px',
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0
}));

const StyledTabs = styled(Tabs)(({theme}) => ({
    [`& .MuiTabs-indicator`]: {
        left: 0,
        width: "4px"
    }
}));

const PostDetailsTabs = ({activeTab, onChange, onClose}: PostDetailsTabsProps) => {
    const {report} = useContext(ReportContext);
    const tabs = report.post_tab_pages;

    const showSearchConsole = isSearchConsoleConfigured(report);

    return <TabsContainer>
        <StyledTabs
            orientation={"vertical"}
            value={activeTab}
            onChange={(event, newValue) => onChange(newValue)}
        >
            <Button
                variant={'text'}
                onClick={onClose}
                startIcon={<ArrowBackIosNewIcon/>}
                size={'medium'}
                color={'secondary'}
            >Back</Button>
            {showSearchConsole ? <Tab value={"searchConsole"} label={<>
                <AutoGraphIcon/>
                Rankings
            </>}/> : null}
            {tabs.map(tab => (<Tab key={tab.id} value={tab.id} label={<><AppIcon iconData={{name: tab.icon}}/>{tab.label}</>}/>))}
        </StyledTabs>
    </TabsContainer>
}

export default PostDetailsTabs;
