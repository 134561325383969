import React from "react";
import ReportTableHeaderCell from "./ReportTableHeaderCell";
import {SortByData} from "./useReportTable";
import {TableCell, TableRow, Checkbox} from "@mui/material";
import {DefaultSortColumn} from "../../types/Table";
import Column from "../../types/Column";

export type ReportTableHeaderRowProps = {
    displayColumns: Column[],
    sortByData: SortByData,
    updateSortByData: (sortByData: SortByData) => void,
    selectAllRows: () => void,
    isIndeterminate: boolean,
    isChecked: boolean,
    fields: any,
    defaultSortColumns: DefaultSortColumn[],
    showActionsCell: boolean
}

const ReportTableHeaderRow = (
    {
        displayColumns,
        sortByData,
        updateSortByData,
        selectAllRows,
        isIndeterminate,
        isChecked,
        fields,
        defaultSortColumns,
        showActionsCell
    }: ReportTableHeaderRowProps) => {
    const updateSortBy = (field, direction) => {
        const newSortByData = {};
        if (direction !== null)
            newSortByData[field] = direction;
        updateSortByData(newSortByData);
    };

    const supplementedSortData: DefaultSortColumn[] = [
        ...Object.keys(sortByData)
            .map((fieldId) => ({
                id: parseInt(fieldId),
                direction: sortByData[fieldId]
            })),
        ...defaultSortColumns
    ];

    return <TableRow>
        {showActionsCell ?
            <TableCell padding="checkbox">
                <Checkbox onClick={selectAllRows} checked={isChecked} indeterminate={isIndeterminate}/>
            </TableCell> : null
        }
        {displayColumns.map(column => <ReportTableHeaderCell
            column={column}
            fieldType={fields.find(field => field.id === column.field).type}
            sortDirection={supplementedSortData[0]?.id === column.field ? supplementedSortData[0].direction : null}
            onSortDirectionChange={direction => updateSortBy(column.field, direction)}
            key={column.id}
        />)}
        {
            showActionsCell ? <TableCell>Details</TableCell> : null
        }
    </TableRow>
}

export default ReportTableHeaderRow;