import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import ViewSlider from "react-view-slider";
import {LoadingButton} from "@mui/lab";
import Collapse from "@mui/material/Collapse";
import FormHelperText from "@mui/material/FormHelperText";
import LinearProgress from "@mui/material/LinearProgress";
import {UseTableExportHook} from "./table/useReportTable";

export type ExportTableDialogProps = {
    open: boolean,
    onClose: () => void,
    useTableExport: UseTableExportHook
}

const ExportTableDialog = ({open, onClose, useTableExport}: ExportTableDialogProps) => {
    const [shouldUseFilters, setShouldUseFilters] = useState(true);
    const [shouldUseSortOptions, setShouldUseSortOptions] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const onExportCompleted = (downloadExport: () => void) => {
        setError(null);
        onClose();
        downloadExport();
    }

    const {
        activeExport,
        isExportInProgress,
        startExport: doExport,
        cancelExport
    } = useTableExport(
        setError,
        onExportCompleted
    );

    const startExport = () => doExport(shouldUseFilters, shouldUseSortOptions);


    const PreExportContent = () => (
        <Grid container direction={"column"} spacing={1}>
            <Grid item xs={12}>
                <Typography>Table rows can be exported and downloaded as a .csv file, allowing you to view it in
                    your
                    own spreadsheet program.</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch checked={shouldUseFilters}
                                onChange={(event, checked) => setShouldUseFilters(checked)}
                        />
                    }
                    label={"Use Active Filters"}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={shouldUseSortOptions}
                            onChange={(event, checked) => setShouldUseSortOptions(checked)}
                        />
                    }
                    label={"Use Active Sort Options"}
                />
            </Grid>
        </Grid>
    )

    const ExportContent = () => {
        if(activeExport === null) {
            return null;
        }
        const {rows_exported: rowsExported, total_rows: totalRows} = activeExport;
        const progress = Math.ceil(rowsExported / totalRows * 100);

        return <>
            <LinearProgress variant={"determinate"} value={progress}/>
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: "column"}} mt={2}>
                <Typography variant={"h3"}>Exporting {rowsExported} / {totalRows}</Typography>
            </Box>
        </>
    };

    return <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        maxWidth={"xs"}
    >
        <DialogTitle>Export Table</DialogTitle>
        <DialogContent>
            <ViewSlider
                numViews={2}
                activeView={isExportInProgress && activeExport !== null ? 1 : 0}
                animateHeight
                renderView={({index}) => index === 0 ? <PreExportContent/> : <ExportContent/>}
            />
            <Collapse in={error !== null}>
                <FormHelperText error>{error}</FormHelperText>
            </Collapse>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                cancelExport();
                onClose();
            }}>Cancel</Button>
            <LoadingButton
                loading={isExportInProgress}
                variant={"contained"}
                onClick={startExport}
            >Export to CSV</LoadingButton>
        </DialogActions>
    </Dialog>
}

export default ExportTableDialog;