import {styled, TextField, TextFieldProps} from "@mui/material";
import {FC} from "react";

const TextFieldNote = styled(TextField)(() => ({
    [`& textarea`]: {
        letterSpacing: "-0.5px",
        fontSize: "0.9em",
        lineHeight: "1.15em",
        fontFamily: "monospace"
    }
})) as FC<TextFieldProps>;

export default TextFieldNote;