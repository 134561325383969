
export default class ReportsApiError extends Error {

    otherData: { [key: string]: unknown } | undefined;
    error: string | number;
    friendlyMessage: string;

    constructor(error: string | number, message: string, otherData: { [key: string]: unknown } | undefined = undefined) {
        super(message)
        this.error = error;
        this.friendlyMessage = message;
        this.otherData = otherData;
        if (Error.hasOwnProperty("captureStackTrace")) {
            Error.captureStackTrace(this, this.constructor);
        }
    }
}
ReportsApiError.prototype.name = "ReportsApiError";