import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Tooltip} from "@mui/material";
import {Link, MoreVert, OpenInBrowser} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import SavedUserFilter from "../../../types/SavedUserFilter";
import useRelativeFormattedDate from "../../../hooks/useRelativeFormattedDate";
import ReportContext from "../../../context/ReportContext";
import {applySavedUserFilter} from "../../../store/slice/filtersSlice";
import store from "../../../store/store";
import {enqueueSnackbar} from "notistack";
import {useLocation} from "react-router-dom";
import {useDeleteSavedUserFilterMutation} from "../../../store/slice/myContentDashAdminApi";
import extractErrorMessageFromError from "../../../util/extractErrorMessageFromError";

type SavedFilterListItemProps = {
    savedFilterListItem: SavedUserFilter;
    onApplied?: () => void;
}

const SavedFilterListItem = ({
                                 savedFilterListItem, onApplied = () => {
    }
                             }: SavedFilterListItemProps) => {
    const [moreActionsOpen, setMoreActionsOpen] = useState(false);
    const {report} = useContext(ReportContext);
    const moreActionsRef = useRef();
    const createdAtString = useRelativeFormattedDate(savedFilterListItem.created_at);
    const location = useLocation();
    const origin = window.location.origin;
    const [deleteSavedUserFilter, {isLoading, error}] = useDeleteSavedUserFilterMutation();

    useEffect(() => {
        if (error) {
            enqueueSnackbar(extractErrorMessageFromError(error), {variant: "error"});
        }
    }, [error]);

    const applyFilter = () => {
        applySavedUserFilter(report, store, savedFilterListItem.id);
        onApplied?.();
    }

    const shareableUrl = useMemo(() => {
        const url = new URL(origin + location.pathname)
        url.searchParams.set("filterId", savedFilterListItem.id + "");
        return url.toString();
    }, [location.pathname, origin, savedFilterListItem.id]);

    const copyShareableUrl = () => {
        navigator.clipboard.writeText(shareableUrl);
        enqueueSnackbar("Filter URL copied to clipboard.", {variant: "success"});
    }

    return <ListItem
        secondaryAction={<>
            <Tooltip title={"Apply Filters"}>
                <IconButton onClick={() => applyFilter()}>
                    <OpenInBrowser/>
                </IconButton>
            </Tooltip>
            <IconButton onClick={() => setMoreActionsOpen(true)} ref={moreActionsRef} sx={{ml: 0.5}}>
                <MoreVert/>
            </IconButton>
        </>}
    >
        <ListItemText primary={savedFilterListItem.name} secondary={`Created ${createdAtString}`}/>
        <Popover
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            anchorEl={moreActionsRef.current}
            open={moreActionsOpen}
            onClose={() => setMoreActionsOpen(false)}
        >
            <List dense>
                <ListItemButton>
                    <ListItemIcon>
                        <Link/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Copy Shareable URL"}
                        secondary={shareableUrl}
                        secondaryTypographyProps={{
                            sx: {
                                maxWidth: '240px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }
                        }}
                        onClick={() => copyShareableUrl()}
                    />
                </ListItemButton>
                <ListItemButton
                    disabled={isLoading}
                    onClick={() => {
                        setMoreActionsOpen(false);
                        deleteSavedUserFilter({
                            savedUserFilterId: savedFilterListItem.id,
                            reportId: report.id
                        });
                    }}
                >
                    <ListItemIcon>
                        <DeleteIcon color={"error"}/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Delete"}
                        primaryTypographyProps={{color: "error"}}
                    />
                </ListItemButton>
            </List>
        </Popover>
    </ListItem>
}

export default SavedFilterListItem;
