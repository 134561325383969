import {MutableRefObject, useContext, useMemo} from "react";
import {SearchConsoleResultRow, SearchConsoleResults} from "../types/SearchConsoleResults";
import {format, parse} from "date-fns";

import ReportContext from "../context/ReportContext";
import {useGetSearchConsoleRankingsQuery} from "../store/slice/myContentDashAdminApi";

type useSearchConsolePerformanceHistoryParams = {
    pageUrl: string | null;
    pageModifiedDate: number | null;
    dayRange: number,
    ref: MutableRefObject<any>;
}

export type SearchConsoleHistoryDifference = {
    beforeRows: SearchConsoleResultRow[],
    afterRows: SearchConsoleResultRow[],
    afterRangeStart: number;
    afterRangeEnd: number;
    beforeRangeStart: number;
    beforeRangeEnd: number;
}

const formatRowDate = (row: SearchConsoleResultRow) => {
    const date = parse(row.keys[0], "yyyy-LL-dd", new Date());
    return date.getTime() - 86400000;
}

type SearchConsolePerformanceHistoryState = {
    isLoading: boolean;
    error: null | string;
    searchConsoleHistory: null | SearchConsoleResults,
    searchConsoleHistoryDifference: null | SearchConsoleHistoryDifference
}

const useSearchConsolePerformanceHistory = ({
                                                pageUrl,
                                                pageModifiedDate,
                                                dayRange,
                                            }: useSearchConsolePerformanceHistoryParams): SearchConsolePerformanceHistoryState => {
    const {report} = useContext(ReportContext);
    const {data: searchConsoleHistory, isFetching, error} = useGetSearchConsoleRankingsQuery({
        pageUrl,
        pageModifiedDate: Math.ceil(pageModifiedDate / 1000), // PHP timestamps are in seconds, not milliseconds
        dayRange,
        reportId: report.id
    }, {skip: !Boolean(pageUrl) || !Boolean(dayRange)});
    
    const searchConsoleHistoryDifference = useMemo(() => {
        if(!searchConsoleHistory || isFetching) {
            return null;
        }
        if (pageModifiedDate && searchConsoleHistory.rows.length > 0) {
            const centerDateFormatted = format(new Date(pageModifiedDate + 86400000), "yyyy-LL-dd");
            const centerIndex = searchConsoleHistory.rows.findIndex(row => row.keys[0] === centerDateFormatted);
            if (centerIndex === -1) {
                return null;
            }
            const diff = searchConsoleHistory.rows.length - centerIndex;

            const beforeRows = searchConsoleHistory.rows.slice(centerIndex - diff, centerIndex);
            const afterRows = searchConsoleHistory.rows.slice(centerIndex);
            if(beforeRows.length === 0) {
                return null;
            }
            const beforeRangeStart = formatRowDate(beforeRows[0]);
            const beforeRangeEnd = formatRowDate(beforeRows[beforeRows.length - 1]);
            const afterRangeStart = formatRowDate(afterRows[0]);
            const afterRangeEnd = formatRowDate(afterRows[afterRows.length - 1]);

            return {
                beforeRows,
                afterRows,
                beforeRangeStart,
                beforeRangeEnd,
                afterRangeStart,
                afterRangeEnd
            }
        }
    }, [pageModifiedDate, searchConsoleHistory, isFetching]);

    let errorMessage: string | undefined;
    if(error) {
        if('data' in error && 'message' in (error.data as any)) {
            errorMessage = (error.data as any).message;
        } else {
            errorMessage = "An unexpected error occurred!";
        }
    }

    return {
        isLoading: isFetching || !Boolean(pageUrl),
        error: errorMessage,
        searchConsoleHistory: searchConsoleHistoryDifference === null ? null : searchConsoleHistory,
        searchConsoleHistoryDifference
    };
}

export default useSearchConsolePerformanceHistory;