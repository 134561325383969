import React, {useState} from "react";
import {styled, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import {useAppDispatch, useAppSelector} from "../store/store";
import {Navigate} from "react-router-dom";
import {usePageTitle} from "../hooks/usePageTitle";
import {push} from "redux-first-history";
import {parseQueryArgs} from "../util/parseQueryArgs";
import DarkThemePaper from "../template/DarkThemePaper";
import {useGetReportsQuery, useGetSessionQuery, useLogInMutation} from "../store/slice/myContentDashAdminApi";
import extractErrorMessageFromError from "../util/extractErrorMessageFromError";

const LoginLoader = styled(LinearProgress)`
  position: relative;
  bottom: -24px;
  width: calc(100% + ${({theme}) => theme.spacing(6)});
  left: ${({theme}) => theme.spacing(-3)};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 5px;
`

const Login = () => {
    usePageTitle("Login");
    const [isLoggingIn, setLoggingIn] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logIn] = useLogInMutation();

    const dispatch = useAppDispatch();
    const {data: session} = useGetSessionQuery();
    useGetReportsQuery(undefined, {skip: !Boolean(session?.user)});
    const user = session?.user;
    const redirectTo = useAppSelector(state => parseQueryArgs(state.router.location.search).redirectTo);

    const navigatePasswordReset = () => {
        dispatch(push("/send-password-reset"))
    }

    const urlParams = new URLSearchParams(window.location.search);

    if (user) {
        if (redirectTo) {
            return <Navigate to={redirectTo}/>;
        }

        return <Navigate to={"/reports"}/>
    }

    const onSubmit = async () => {
        if(email === "" || password === "") {
            return;
        }
        setLoggingIn(true);
        setLoginError(null);
        try {
            await logIn({email, password}).unwrap();
        } catch (error) {
            setLoginError(extractErrorMessageFromError(error));
            setLoggingIn(false);
        }
    }

    return <DarkThemePaper title={"Login"}>
        <form onSubmit={event => {
            event.preventDefault()
            onSubmit();
        }}>
            <Fade in={loginError !== null} timeout={loginError ? 500 : 0}>
                <Typography
                    variant={"body1"}
                    sx={{
                        color: "error.light",
                        mb: 1
                    }}
                >{loginError ? loginError : "|"}</Typography>
            </Fade>
            {urlParams.has("passwordReset") ?
                <Typography color={"success.dark"}>Password reset successfully, login using the form below.</Typography>
                : null}
            {urlParams.has("passwordResetSent") ?
                <Typography color={"success.dark"} maxWidth={"420px"} mb={2}>If a user exists with the given email, a password reset email will be
                    sent. Check your spam & Promotions folder if you don't see it within a few minutes.</Typography> : null
            }
            <TextField
                disabled={isLoggingIn}
                label={"Email"}
                name={"email"}
                InputLabelProps={{required: false}}
                autoComplete={"email"}
                required={true}
                value={email}
                onChange={event => setEmail(event.target.value)}
                fullWidth
                sx={{mb: 1}}
            />
            <TextField
                disabled={isLoggingIn}
                label={"Password"}
                name={"password"}
                type={"password"}
                autoComplete={"password"}
                InputLabelProps={{required: false}}
                required={true}
                onChange={event => setPassword(event.target.value)}
                value={password}
                fullWidth
                sx={{mb: 1}}
            />
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                mt={2}
            >
                <Button
                    disabled={isLoggingIn}
                    color={"primary"}
                    onClick={() => navigatePasswordReset()}
                >
                    Forgot Password?
                </Button>
                <Button disabled={isLoggingIn}
                        type={"submit"}
                        variant={"contained"}
                        color={"primary"}
                        onClick={onSubmit}
                >
                    Log In
                </Button>
            </Box>
            <Grow in={isLoggingIn} style={{transformOrigin: '0 0 0'}} {...(isLoggingIn ? {timeout: 500} : {})}>
                <LoginLoader/>
            </Grow>
        </form>
    </DarkThemePaper>;
}

export default Login;