import React, {forwardRef} from "react";
import cx from 'classnames';
import {useTheme} from "@mui/material/styles";
const bannerDark = require("../../image/mcd-logo-banner-color-dark.webp").default;
const bannerLight = require("../../image/mcd-logo-banner-color-light.webp").default;
const square = require("../../image/mcd-logo-square-color.webp").default;
const bannerVerticalLight = require("../../image/mcd-logo-vertical-banner-white.webp").default;

export type MyContentDashLogoProps = {
    className?: string;
    variant: "banner" | "bannerVerticalWhite" | "logoSquare";
    mode?: "light" | "dark" | null;
    onClick?: () => void;
}

export const ContentDashLogoImages = {
    bannerDark,
    bannerLight,
    square,
    bannerVerticalLight
}

const MyContentDashLogo = forwardRef<HTMLImageElement, MyContentDashLogoProps>(
    ({className, variant = "banner", mode = null, onClick = undefined}: MyContentDashLogoProps, ref) => {
    const theme = useTheme();
    const effectiveMode = mode || theme.palette.mode;
    let imageSrc;

    switch (variant) {
        default:
        case "banner":
            imageSrc = effectiveMode === "dark" ? ContentDashLogoImages.bannerDark : ContentDashLogoImages.bannerLight;
            break;
        case "bannerVerticalWhite":
            imageSrc = ContentDashLogoImages.bannerVerticalLight
            break;
        case "logoSquare":
            imageSrc = ContentDashLogoImages.square
            break;
    }

    return <img
        alt={"MyContentDash logo"}
        className={cx([className])}
        src={imageSrc}
        ref={ref}
        onClick={onClick}
    />
});

export default MyContentDashLogo;