import {useState} from "react";
import {Report} from "../types/Report";
import {GoogleIntegration} from "../types/GoogleIntegration";
import {useAuthenticateGoogleAuthCodeMutation} from "../store/slice/myContentDashAdminApi";

const openPopupWindow = ({url, width, height}: { url: string, width: number, height: number }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const screenWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
    const screenHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

    const left = (screenWidth - width) / 2 + dualScreenLeft
    const top = (screenHeight - height) / 2 + dualScreenTop
    const features = `
      scrollbars=yes,
      width=${width}, 
      height=${height}, 
      top=${top}, 
      left=${left}
      `;
    const newWindow = window.open(url, "_blank", features);

    if (window.focus) newWindow.focus();

    return newWindow;
}

type UseGoogleAuthorizationParams = {
    report: Report;
    onConnected?: (google: GoogleIntegration) => void;
}

const useGoogleAuthorization = ({
                                    report, onConnected = () => {
    }
                                }: UseGoogleAuthorizationParams) => {
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [authenticateGoogleCode] = useAuthenticateGoogleAuthCodeMutation();

    const openAuthorizationDialog = () => {
        setIsAuthenticating(true);
        setError(null);
        (async () => {
            const authWindow = openPopupWindow({
                url: report.integrations.google.authorization_url,
                height: 600,
                width: 450
            });
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const interval = setInterval(async () => {
                if (authWindow.closed || (authWindow?.opener?.hasClosed && authWindow.opener.hasClosed())) {
                    clearInterval(interval);
                }
                let windowUrl;
                try {
                    windowUrl = authWindow.location.href;
                } catch (error) {
                    return;
                }
                if (windowUrl === undefined) {
                    clearInterval(interval);
                    setIsAuthenticating(false);
                    return;
                }

                const params = new URL(windowUrl).searchParams;
                authWindow.close();
                clearInterval(interval);
                if (params.has("error")) {
                    setIsAuthenticating(false);
                    return;
                }
                const code = params.get("code");
                try {
                    const response = await authenticateGoogleCode({code, reportId: report.id}).unwrap();
                    onConnected(response);
                } catch (error) {
                    setError(error.message);
                    return;
                } finally {
                    setIsAuthenticating(false);
                }
            }, 1000);
        })();
    }

    return {
        openAuthorizationDialog,
        isAuthenticating,
        error
    }
}

export default useGoogleAuthorization;