import {useGenerateSubscriptionCheckoutUrlMutation} from "../../store/slice/myContentDashAdminApi";
import LoadingButton from "@mui/lab/LoadingButton";
import {ButtonProps} from "@mui/material";
import React from "react";

type SubscribeButtonProps = {
    period: "month" | "year";
} & ButtonProps;
const SubscribeButton = ({period, children, ...props}: SubscribeButtonProps) => {
    const [generateSubscriptionCheckoutUrl, {isLoading: isRetrievingCheckoutPage}] = useGenerateSubscriptionCheckoutUrlMutation();

    const subscribe = async () => {
        window.location.href = await generateSubscriptionCheckoutUrl({period}).unwrap();
    }

    return <LoadingButton
        onClick={subscribe}
        loading={isRetrievingCheckoutPage}
        {...props}
    >{children || "Subscribe"}</LoadingButton>
}

export default SubscribeButton;