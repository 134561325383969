import React from "react";
import MyContentDashLogo from "../MyContentDashLogo";
import {Box, Button, styled} from "@mui/material";
import LogoutButton from "../LogoutButton";
import {useGetSessionQuery} from "../../store/slice/myContentDashAdminApi";
import {isUserSubscriptionActive} from "../../util/isUserSubscriptionActive";
import {useAppDispatch} from "../../store/store";
import {openAccountModal} from "../../store/slice/appSlice";

const AuthenticationHeaderWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
  margin-top: ${({theme}) => theme.spacing(3)});
`

const AuthenticationHeaderLogo = styled(MyContentDashLogo)`
  height: 40px;
  margin: ${({theme}) => theme.spacing(3)};
  margin-left: ${({theme}) => theme.spacing(6)};
`

const AuthenticationHeader = () => {
    const {data: session} = useGetSessionQuery();
    const dispatch = useAppDispatch();
    const user = session.user;

    return <AuthenticationHeaderWrapper>
        <Box
            display={'flex'}
            justifyContent={user === null ? 'left' : 'space-between'}
            alignItems={'center'}
            width={'100%'}
        >
            <AuthenticationHeaderLogo variant={"banner"} mode={"dark"}/>
            <Box sx={{mr: 4}}>
                {
                    user !== null && isUserSubscriptionActive(user) &&
                    <Button
                        variant={'outlined'}
                        onClick={() => dispatch(openAccountModal())}
                        sx={{mr: 2}}
                    >Account</Button>
                }
                {
                    user !== null ? <LogoutButton variant={'outlined'}/> : null
                }
            </Box>
        </Box>
    </AuthenticationHeaderWrapper>;

}


export default AuthenticationHeader;