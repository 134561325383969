import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import store, {history} from "./app/store/store";
import theme from "./app/styles/themeOverrides";
import {ThemeProvider} from "@mui/material/styles";
import {HistoryRouter as Router} from "redux-first-history/rr6";
import FullPageSpinner from "./app/components/FullPageSpinner";
import {SnackbarProvider} from "notistack";
import {createRoot} from "react-dom/client";

declare global {
    interface Window {
        Beacon: (action: string, payload?: any) => any;
    }
}

if (window.location.pathname === "/connect-google") {
    ReactDOM.render(<ThemeProvider theme={theme}>
            <FullPageSpinner/>
        </ThemeProvider>,
        document.getElementById('root')
    );
} else {
    const container = document.getElementById('root');
    const root = createRoot(container!);
    root.render(// @ts-ignore
        <Provider store={store}>
            <Router history={history}>
                <ThemeProvider theme={theme}>
                    {/*@ts-ignore*/}
                    <SnackbarProvider>
                        <App/>
                    </SnackbarProvider>
                </ThemeProvider>
            </Router>
        </Provider>);
}