import * as React from "react";
import {useState} from "react";
import {Box, Collapse, IconButton, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import {LoadingButton} from "@mui/lab";
import {useGenerateAddSitesCheckoutUrlMutation} from "../../store/slice/myContentDashAdminApi";

const BuyWebsiteAddons = () => {
    const [quantity, setQuantity] = useState(1);
    const [error, setError] = useState<string | null>(null);
    const [generateAddSitesCheckoutUrl, {isLoading}] = useGenerateAddSitesCheckoutUrlMutation();

    const loadCheckout = async () => {
        setError(null);
        try {
            window.location.href = await generateAddSitesCheckoutUrl({quantity}).unwrap();
        } catch (error) {
            setError(error.data.message);
        }
    }

    return <Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mb={2}>
            <IconButton
                onClick={() => setQuantity(quantity - 1)}
                disabled={quantity === 1}
                sx={{mr: 1}}
            >
                <RemoveIcon/>
            </IconButton>
            <Typography variant={'h4'}>{"+" + quantity + " Site" + (quantity > 1 ? "s" : "")}</Typography>
            <IconButton
                onClick={() => setQuantity(quantity + 1)}
                sx={{ml: 1}}
            >
                <AddIcon/>
            </IconButton>
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
            <LoadingButton
                loading={isLoading}
                variant={"contained"}
                endIcon={<ShoppingCartCheckoutIcon/>}
                onClick={loadCheckout}
            >Go to Checkout</LoadingButton>
        </Box>
        <Collapse in={error !== null}>
            <Typography sx={{mt: 1}} color={'error'}>{error}</Typography>
        </Collapse>
    </Box>
}

export default BuyWebsiteAddons;