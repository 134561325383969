import React, {useState} from "react";
import {Box, Button, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Navigate, NavLink} from "react-router-dom";
import ReportListItem from "../components/ReportListItem";
import List from "@mui/material/List";
import {useAppDispatch, useAppSelector} from "../store/store";
import NavigateToLogin from "../components/NavigateToLogin";
import DarkThemePaper from "../template/DarkThemePaper";
import AddIcon from '@mui/icons-material/Add';
import {usePageTitle} from "../hooks/usePageTitle";
import {push} from "redux-first-history";
import {isUserSubscriptionActive} from "../util/isUserSubscriptionActive";
import {useGetReportsQuery, useGetSessionQuery} from "../store/slice/myContentDashAdminApi";
import FullPageSpinner from "../components/FullPageSpinner";
import BasicReport from "../types/BasicReport";

const useStyles = makeStyles(() => ({
    list: {
        maxHeight: "320px",
        overflowY: "auto"
    }
}))

const SelectReport = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const location = useAppSelector(state => state.router.location) as unknown as Location;
    const {data: session} = useGetSessionQuery();
    const {data: reportsList, isLoading} = useGetReportsQuery(undefined, {skip: !Boolean(session?.user)});
    const user = session.user;
    const dispatch = useAppDispatch();
    usePageTitle("Select Report");
    const classes = useStyles();

    if (!user) {
        return <NavigateToLogin/>;
    }

    if(isLoading) {
        return <FullPageSpinner/>;
    }

    const reports = reportsList.reports;
    const userAccounts = reportsList.accounts;
    if (reports.length === 0) {
        return <Navigate to={"/report-setup"}/>
    }

    const navigateToReport = async (report: BasicReport) => {
        if (report.status !== "ready") {
            return;
        }
        const urlSearchParams = new URLSearchParams(location.search);
        let page = "";
        if (urlSearchParams.has("page")) {
            page = "/" + urlSearchParams.get("page");
        }
        await dispatch(push("/report/" + report.domain + page))
    }

    if (reports.length === 1) {
        const accountOwner = reports[0].owner;
        if (!accountOwner || isUserSubscriptionActive(userAccounts.find(userAccount => userAccount.id === accountOwner))) {
            return <Navigate to={"/report/" + reports[0].domain}/>;
        }
    }

    const userReports = reports
        .filter(report => searchTerm === "" || report.domain.indexOf(searchTerm) !== -1)
        .sort((a, b) => a.domain.localeCompare(b.domain));


    return <DarkThemePaper title={"Select Report"}>
        <Box minWidth={'400px'}>
            <Box display={'flex'} justifyContent={'center'}>
                <NavLink to={"add"}>
                    <Button
                        startIcon={<AddIcon/>}
                        sx={{mb: 1}}
                    >Add Another Website</Button>
                </NavLink>
            </Box>
            {
                reports.length > 8 ?
                    <TextField
                        autoFocus
                        fullWidth
                        label={"Filter..."}
                        value={searchTerm}
                        onChange={event => setSearchTerm(event.target.value)}
                        size={"small"}
                        sx={{mb: 1.5}}
                        data-test={"report-filter"}
                    /> : null
            }
            <List className={classes.list}>
                {userReports.map(report => <ReportListItem
                    key={report.id}
                    report={report}
                    onClick={() => navigateToReport(report)}
                />)}
            </List>
        </Box>
    </DarkThemePaper>

};

export default SelectReport;