import React, {useContext} from "react";
import {ContentDashLogoImages} from "../MyContentDashLogo";
import {Divider} from "@mui/material";
import List from "@mui/material/List";
import {createTheme, ThemeProvider, styled} from "@mui/material/styles";
import NavigationItem from "./NavigationItem";
import globalVars from "../../styles/globalVars";
import {useSelector} from "react-redux";
import themeOverrides from "../../styles/themeOverrides";
import {ReportPage} from "../../types/ReportPage";
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import {reportPagePathnameSelector} from "../../store/slice/reportSelector";
import ReportContext from "../../context/ReportContext";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";

const sidebarBackground = require('../../../image/sidebarBackground.webp').default;

const theme = createTheme({
    ...themeOverrides,
    palette: {
        ...themeOverrides.palette,
        mode: "light",
        action: {
            active: "#F7F6F6"
        }
    }
});


// noinspection CssUnknownTarget
const SidebarBackgroundImage = styled("div")<{ $minimized: boolean }>`
  width: ${({$minimized}) => $minimized ? globalVars.drawerWidthMinimized : globalVars.drawerWidthExpanded};
  height: 100%;
  position: fixed;
  background-image: url(${sidebarBackground});
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
  z-index: 1031;
  transition-property: top, bottom, width;
  transition-duration: .2s, .2s, .35s;
  transition-timing-function: linear, linear, ease;
  background-size: cover;
  background-position: center center;
`

const ReportSidebarSurface = styled("div")<{ $minimized: boolean }>`
  width: ${(props) => props.$minimized ? globalVars.drawerWidthMinimized : globalVars.drawerWidthExpanded};
  height: 100%;
  position: fixed;
  border: none;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1032;
  transition-property: top, bottom, width;
  transition-duration: .2s, .2s, .35s;
  transition-timing-function: linear, linear, ease;
  background-color: rgb(44 49 65 / 80%);
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
  overflow: hidden;
`

const SidebarBannerLogoTransition = styled("div")<{ $minimized: boolean }>`
  width: ${({$minimized}) => $minimized ? "47px" : "100%"};
  height: 76px;
  overflow: visible;
  background-image: url('${({$minimized}) => $minimized ? ContentDashLogoImages.square : ContentDashLogoImages.bannerVerticalLight}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.fade-wait-leave {
    opacity: 1;
  }

  &.fade-wait-leave.fade-wait-leave-active {
    opacity: 0;
    transition: opacity 0.3s ease-in;
  }

  &.fade-wait-enter {
    opacity: 0;
  }

  &.fade-wait-enter.fade-wait-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }

  &.fade-wait-height {
    transition: height 0.3s ease-in-out;
  }
`;

const LogoContainer = styled("div")`
  margin: ${({theme}) => theme.spacing(2)};
  width: calc(100% - 32px);
`

type ReportSidebarProps = {
    minimized: boolean;
}

const ReportSidebar = ({minimized = false}: ReportSidebarProps) => {
    const {report} = useContext(ReportContext);
    const currentPath = useSelector(reportPagePathnameSelector);


    return <>
        <ThemeProvider theme={theme}>
            <SidebarBackgroundImage $minimized={minimized}/>
            <ReportSidebarSurface
                $minimized={minimized}
            >
                <LogoContainer>
                    {/* @ts-ignore */}
                    <ReactCSSTransitionReplace
                        //@ts-ignore
                        transitionName="fade-wait"
                        transitionEnterTimeout={300}
                        transitionLeaveTimeout={300}
                    >
                        <SidebarBannerLogoTransition
                            key={minimized ? "square" : "banner"}
                            $minimized={minimized}
                        />
                    </ReactCSSTransitionReplace>
                </LogoContainer>
                <Divider variant="middle" color={"gray"}/>
                <List component="nav" sx={{px: 1.5}}>
                    {getLinks(currentPath, report.pages)}
                    {report.enable_page_trackers ?
                    <NavigationItem
                        name={"Tracked Pages"}
                        url={"tracked-pages"}
                        selected={currentPath === "tracked-pages"}
                        iconData={<TroubleshootIcon/>}
                        help={{body: undefined}}
                    /> : null}
                </List>
            </ReportSidebarSurface>
        </ThemeProvider>
    </>
}

const getLinks = (currentPath: string, pagesData: ReportPage[]) => {
    let routes = [];

    for (let pageData of pagesData) {
        const pageUrl = pageData.route.replace(/^\//, "");
        routes.push(
            <NavigationItem
                key={pageData.route}
                name={pageData.label}
                url={pageUrl}
                selected={currentPath === pageUrl}
                iconData={pageData.icon}
                help={pageData.help}
                disabled={pageData.disabled}
            />
        )
    }
    return routes;
}

export default ReportSidebar;