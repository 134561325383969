import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type TodoState = {
    open: boolean;
    search: string;
    page: number;
    showCompleted: boolean;
}

const initialState: TodoState = {
    open: false,
    search: '',
    page: 0,
    showCompleted: false
}

const todoPanelSlice = createSlice({
    name: 'todoPanel',
    initialState,
    reducers: {
        setSearch: (state, action: PayloadAction<string>) => {
            state.page = 0;
            state.search = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        openTodoPanel: (state) => {
            state.open = true;
        },
        setShowCompleted: (state, action: PayloadAction<boolean>) => {
            state.showCompleted = action.payload;
        },
        closeTodoPanel: (state) => {
            state.open = false;
        }
    }
});

export const {setSearch, setPage, openTodoPanel, closeTodoPanel, setShowCompleted} = todoPanelSlice.actions;

export default todoPanelSlice.reducer;