import {
    useGetBillingPeriodsQuery,
    useGetSessionQuery
} from "../../store/slice/myContentDashAdminApi";
import SelectBillingPeriod from "./SelectBillingPeriod";
import {Box, LinearProgress, Typography} from "@mui/material";
import React from "react";
import SubscribeButton from "./SubscribeButton";

const PlanPicker = () => {
    const {data: getBillingPeriodsResponse} = useGetBillingPeriodsQuery();
    const {data: session} = useGetSessionQuery();
    const user = session.user;

    return <>
        <Typography variant={'h5'} sx={{mb: 1}}>Hi {user.first_name}, welcome to MyContentDash.</Typography>
        {getBillingPeriodsResponse ?
            <>
                {getBillingPeriodsResponse.show_yearly ?
                    <SelectBillingPeriod
                        periods={getBillingPeriodsResponse.periods}
                        showYearly={getBillingPeriodsResponse.show_yearly}
                    /> : (<>
                        <Typography variant={'h5'}>To get started, finish the signup process by setting up your billing
                            account using the button below.</Typography>
                        <Box display={'flex'} justifyContent={'center'} my={2}>
                            <SubscribeButton period={"month"} variant={'contained'} size={'large'}/>
                        </Box>
                    </>)
                }
            </>
            :
            <LinearProgress sx={{my: 2}}/>
        }
    </>;
}

export default PlanPicker;