import * as React from 'react';
import IntegrationCard from "./IntegrationCard";
import WordPressIcon from "../../../icon/WordPressIcon";
import {Box, Typography} from "@mui/material";
import DownloadWordPressPluginButton from "../../../DownloadWordPressPluginButton";

import {useContext} from "react";
import ReportContext from "../../../../context/ReportContext";

const WordPressIntegrationCard = () => {
    const {report} = useContext(ReportContext);


    return <IntegrationCard
        header={<Box display={'flex'} justifyContent={'left'} alignItems={'center'}>
            <WordPressIcon sx={{fontSize: "36px"}}/>
            <Typography sx={{display: "inline-block", ml: 2}} variant={"h3"}>WordPress</Typography>
        </Box>}
        isConnected={report.integrations.wordpress.is_connected}
    >
        <Typography
            variant={"h4"}
            color={report.integrations.wordpress.is_connected ? "success.main" : "error.light"}
            fontWeight={"500"}
            textAlign={"center"}
        >{report.integrations.wordpress.is_connected ? "Connected" : "Not Connected"}</Typography>
        <DownloadWordPressPluginButton
            variant={"contained"}
            size={"small"}
            sx={{mt: 2}}
        >Download Plugin</DownloadWordPressPluginButton>
    </IntegrationCard>
}

export default WordPressIntegrationCard;