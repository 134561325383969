import React, {useContext, useState} from 'react';
import {useCreateReportTodoItemMutation} from "../../store/slice/myContentDashAdminApi";
import ReportContext from "../../context/ReportContext";
import {Box, BoxProps, IconButton, TextField} from "@mui/material";
import ListAddIcon from '@mui/icons-material/PlaylistAdd';
import {useSnackbar} from "notistack";
import extractErrorMessageFromError from "../../util/extractErrorMessageFromError";

type AddTodoItemTextFieldProps = {
    sx: BoxProps;
    pageUrls?: readonly string[];
    onAdded?: () => void;
}

const AddTodoItemTextField = ({sx, pageUrls, onAdded}: AddTodoItemTextFieldProps) => {
    const [text, setText] = useState("");
    const {report} = useContext(ReportContext);
    const [createNewTodoItem, {isLoading: isAdding}] = useCreateReportTodoItemMutation();
    const {enqueueSnackbar} = useSnackbar();


    const addItem = async () => {
        try {
            await createNewTodoItem({
                text,
                reportId: report.id,
                pageUrls: pageUrls ? [...pageUrls] : undefined
            }).unwrap();
            setText('');
            if(onAdded) {
                onAdded();
            }
        } catch (error) {
            enqueueSnackbar(extractErrorMessageFromError(error), {variant: 'error'});
        }
    }

    return <Box display={'flex'} width={'100%'} alignItems={'center'} {...sx}>
        <TextField
            label={'Add new item'}
            sx={{flex: 1}}
            value={text}
            onChange={event => setText(event.target.value)}
            variant={"filled"}
            size={"small"}
            disabled={isAdding}
            onKeyDown={event => event.key === 'Enter' ? addItem() : null}
            autoFocus
        />
        <IconButton
            sx={{ml: 2}}
            disabled={text === '' || isAdding}
            onClick={() => addItem()}
        >
            <ListAddIcon/>
        </IconButton>
    </Box>
}

export default AddTodoItemTextField