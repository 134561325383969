import TrackedPage from "../../../types/TrackedPage";
import {Box, Chip, Stack, CircularProgress, IconButton, Link, TableCell, Typography, TableRow, Tooltip, TextField} from "@mui/material";
import React, {useContext, useMemo, useState} from "react";
import ReportContext from "../../../context/ReportContext";
import extractPathFromUrl from "../../../util/extractPathFromUrl";
import {format} from "date-fns";
import {LoadingButton} from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
    useGetSettingQuery,
    useGetTrackedPageMetaQuery,
    useRemovePageFromTrackerMutation,
    useUpdatePageTrackerMutation
} from "../../../store/slice/myContentDashAdminApi";
import {useSnackbar} from "notistack";
import AssessmentIcon from "@mui/icons-material/Assessment";
import {isSearchConsoleConfigured} from "../../../util/isSearchConsoleConfigured";
import PostDetailsPanel from "../../post-details-panel/PostDetailsPanel";
import extractErrorMessageFromError from "../../../util/extractErrorMessageFromError";
import useEffectWhen from "../../../util/useEffectWhen";
import {useAppDispatch} from "../../../store/store";
import {openGscNotConnectedDialog} from "../../../store/slice/appSlice";
import AddTodoActionButton from "../../todo-items/AddTodoActionButton";
import PageNoteActionButton from "../../page-notes/PageNoteActionButton";

type TrackedPageRowProps = {
    trackedPage: TrackedPage;
}

const TrackedPageRow = ({trackedPage: page}: TrackedPageRowProps) => {
    const {report} = useContext(ReportContext);
    const [removeTrackedPage, {isLoading: isDeleting}] = useRemovePageFromTrackerMutation();
    const [updateTrackedPage, {isLoading: isUpdating}] = useUpdatePageTrackerMutation();
    const [addedDate, setAddedDate] = useState(new Date(page.added_date * 1000));
    const addedUser = report.access_users.find(user => user.id === page.added_by);
    const [performancePanelOpen, setPerformancePanelOpen] = useState(false);
    const dispatch = useAppDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const {
        data: pageMetaResponse,
        isLoading: isRetrievingPageMeta,
        error
    } = useGetTrackedPageMetaQuery(page.id, {skip: !performancePanelOpen});
    const {
        data: dayRangesResponse,
        isLoading: isRetrievingDayRanges
    } = useGetSettingQuery("hjr_search_console_panel_day_ranges");

    useEffectWhen(() => {
        if (error !== undefined && !isRetrievingPageMeta) {
            enqueueSnackbar(extractErrorMessageFromError(error), {variant: 'error'});
            setPerformancePanelOpen(false);
        }
    }, [error, enqueueSnackbar, isRetrievingPageMeta], [isRetrievingPageMeta])

    const removePage = async () => {
        try {
            await removeTrackedPage({pageId: page.id});
            enqueueSnackbar("Removed page from tracker", {variant: 'success'});
        } catch (error) {
            console.error(error);
            enqueueSnackbar(error.data.message || "An unexpected error occurred");
        }
    };

    const updateTimeSet = async (setTime: number) => {
        try {
            await updateTrackedPage({trackerId: page.id, addedDate: setTime/1000});
            enqueueSnackbar("Updated the date to " + format(new Date(setTime), "MMM do"), {variant: 'success'});
            setAddedDate(new Date(setTime));
        } catch (error) {
            console.error(error);
            enqueueSnackbar(error.data.message || "An unexpected error occurred");
        }

    }

    const openPerformancePanel = () => {
        if (!isSearchConsoleConfigured(report)) {
            dispatch(openGscNotConnectedDialog());
        } else {
            setPerformancePanelOpen(true);
        }
    }

    const isLoading = (isRetrievingDayRanges || isRetrievingPageMeta) && performancePanelOpen;
    const pageMeta = useMemo(() => pageMetaResponse === undefined ? undefined : {
        url: page.page_url,
        modified: pageMetaResponse.tracked_page_meta.date_modified * 1000,
        published: pageMetaResponse.tracked_page_meta.date_published * 1000
    }, [page.page_url, pageMetaResponse]);

    return <TableRow key={page.id}>
        <TableCell>
            <Stack direction="row" spacing={1} sx={{mb: 1}}>
                <Typography>
                    <Link href={page.page_url} target={"_blank"}>{extractPathFromUrl(page.page_url)}</Link>
                </Typography>
                {addedUser ?
                    <Typography variant="caption"> Added by {addedUser.first_name} </Typography>
                : null}
            </Stack>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    value={addedDate}
                    onChange={(value) => {updateTimeSet(value.getTime())}}
                    renderInput={(props) => <TextField {...props} label="Tracking Date" size="small" sx={{minWidth: "140px", width: '20%'}}/>}
                    loading={isUpdating}
                    disableFuture>
                </DatePicker>
            </LocalizationProvider>
        </TableCell>
        <TableCell>
            <Chip label={report.page_trackers.find(tracker => tracker.id === page.tracker).label}/>
        </TableCell>
        <TableCell>
            <Box display={'flex'} justifyContent={'right'}>
                <LoadingButton loading={isDeleting} onClick={removePage}>Remove</LoadingButton>
                {report.enable_todo ?
                    <Tooltip title={"Add todo item"}>
                        <AddTodoActionButton
                            size={'large'}
                            pageUrl={page.page_url}
                        />
                    </Tooltip> : null}
                {report.enable_notes ?
                    <Tooltip title={"Add note"}>
                        <PageNoteActionButton
                            pageUrl={page.page_url}
                            size={'large'}
                        />
                    </Tooltip>: null}
                <IconButton size={'large'} onClick={openPerformancePanel} disabled={isLoading}>
                    {isLoading ? <CircularProgress size={24}/> : <AssessmentIcon/>}
                </IconButton>
            </Box>
        </TableCell>
        <PostDetailsPanel
            open={pageMetaResponse !== undefined && !isLoading && performancePanelOpen}
            onClose={() => setPerformancePanelOpen(false)}
            searchConsoleDayRanges={dayRangesResponse ? dayRangesResponse.split(",").filter(s => s !== "").map(s => parseInt(s)) : []}
            page={pageMeta}
        />
    </TableRow>;
}

export default TrackedPageRow;