import * as React from 'react';
import {styled} from "@mui/material";
import {ThemeProvider} from "@mui/styles";
import {createTheme} from "@mui/material/styles";
import themeOverrides from "../styles/themeOverrides";
import AuthenticationHeader from "../components/auth/AuthenticationHeader";
import AuthPaper from "./AuthPaper";

const mainTheme = createTheme(themeOverrides);
const heroBgImage = require("../../image/hero-bg.webp").default;

const AuthenticationWrapper = styled('div')`
  width: 100%;
  height: 100%;
  position: fixed;
  background: center center linear-gradient(rgb(0 0 0 / 70%), rgb(0 0 0 / 70%)), url(${heroBgImage});
  background-size: cover;
`

type DarkThemePaperProps = {
    children: React.ReactChild | React.ReactChild[];
    title: string;
}

const DarkThemePaper = ({children, title}: DarkThemePaperProps) => {
    return <ThemeProvider theme={mainTheme}>
        <AuthenticationWrapper>
            <AuthenticationHeader/>
            <AuthPaper title={title}>
                {children}
            </AuthPaper>
        </AuthenticationWrapper>
    </ThemeProvider>
}

export default DarkThemePaper;