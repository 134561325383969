import {Badge, ButtonProps} from "@mui/material";
import React, {useContext, useRef, useState} from "react";
import Button from "@mui/material/Button";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AllPageNotesPopover from "./AllPageNotesPopover";
import {useGetNotesQuery} from "../../store/slice/myContentDashAdminApi";
import ReportContext from "../../context/ReportContext";

type AllPageNotesButtonProps = Omit<ButtonProps, 'onClick' | 'ref' | 'startIcon'>;
const AllPageNotesButton = (props: AllPageNotesButtonProps) => {
    const [notesPopoverOpen, setNotesPopoverOpen] = useState(false);
    const notesButtonRef = useRef<HTMLButtonElement>();
    const {report} = useContext(ReportContext);
    const {data: notesResponse} = useGetNotesQuery({reportId: report.id})

    return <>
        <Button
            ref={notesButtonRef}
            onClick={() => setNotesPopoverOpen(true)}
            startIcon={
                notesResponse ?
                    <Badge badgeContent={notesResponse.notes.length} color={'primary'} sx={{fontSize: '8px'}}>
                        <AssignmentIcon/>
                    </Badge> : <AssignmentIcon/>
            }
            {...props}
        >
            {props.children || 'Page Notes'}
        </Button>
        <AllPageNotesPopover
            open={notesPopoverOpen}
            onClose={() => setNotesPopoverOpen(false)}
            anchorEl={notesButtonRef.current}
        />
    </>
}

export default AllPageNotesButton;