import * as React from 'react';
import {Navigate, useLocation} from "react-router-dom";
import {useGetReportsQuery} from "../store/slice/myContentDashAdminApi";

const GoToMyReportPage = () => {
    const {data: reportsList} = useGetReportsQuery();
    const reports = reportsList.reports;
    const location = useLocation();
    const route = location.pathname.replace("/myreport/", "");
    if (reports.length === 0) {
        return <Navigate to={"/reports"}/>
    }

    if (reports.length === 1) {
        return <Navigate to={"/report/" + reports[0].domain + "/" + route}/>
    }

    return <Navigate to={"/reports?page=" + encodeURIComponent(route)}/>
}

export default GoToMyReportPage;