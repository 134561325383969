import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AppDispatch, RootState} from "../store";
import {push} from "redux-first-history";

export type AppState = {
    pageTitle: string;
    justLoggedOut: boolean;
    accountModalOpen: boolean;
    gscNotConnectedDialogOpen: boolean;
}

const initialState: AppState = {
    pageTitle: "App",
    justLoggedOut: false,
    accountModalOpen: false,
    gscNotConnectedDialogOpen: false
}

export const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        updatePageTitle: (state, action: PayloadAction<string>) => {
            state.pageTitle = action.payload;
        },
        setJustLoggedOut: (state, action: PayloadAction<boolean>) => {
            state.justLoggedOut = action.payload;
        },
        openAccountModal: (state) => {
            state.accountModalOpen = true;
        },
        closeAccountModal: (state) => {
            state.accountModalOpen = false;
        },
        closeGscNotConnectedDialog: (state) => {
            state.gscNotConnectedDialogOpen = false;
        },
        openGscNotConnectedDialog: (state) => {
            state.gscNotConnectedDialogOpen = true;
        }
    },
});

export const removeUrlParams = (paramsToRemove: string[]) => (dispatch: AppDispatch, getState: () => RootState) => {
    const location: Location = getState().router.location as unknown as Location;

    const path = location.pathname;
    const search = new URLSearchParams(location.search);

    if (Array.from(search).length === 0) {
        return;
    }

    for (let param of paramsToRemove) {
        search.delete(param);
    }

    let newSearch = "?" + search.toString();

    if (Array.from(search).length === 0) {
        newSearch = "";
    }

    dispatch(push(path + newSearch));
}

// Action creators are generated for each case reducer function
export const {
    updatePageTitle,
    setJustLoggedOut,
    openAccountModal,
    closeAccountModal,
    closeGscNotConnectedDialog,
    openGscNotConnectedDialog
} = appSlice.actions

export default appSlice.reducer