import Popover from "@mui/material/Popover";
import React, {useContext, useState} from "react";
import {Box, Collapse, MenuItem, TextField, Typography} from "@mui/material";
import AddchartIcon from '@mui/icons-material/Addchart';
import extractPathFromUrl from "../../util/extractPathFromUrl";
import {grey} from "@mui/material/colors";
import {styled} from "@mui/material/styles";
import ReportContext from "../../context/ReportContext";
import {useAddPageToTrackerMutation} from "../../store/slice/myContentDashAdminApi";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";

type MultiTrackPagePerformancePopoverProps = {
    pageUrls: string[];
    onClose: () => void;
    open: boolean;
    anchorEl: Element;
}

const UrlSubtitle = styled(Typography)`
  color: ${grey['500']};
  font-size: 0.9rem;
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const MultiTrackPagePerformancePopover = ({pageUrls, onClose, open, anchorEl}: MultiTrackPagePerformancePopoverProps) => {
    const {report} = useContext(ReportContext);
    const [selectedTracker, setSelectedTracker] = useState(report.page_trackers[0].id);
    const [addToTracker, {isLoading, error}] = useAddPageToTrackerMutation();

    const {enqueueSnackbar} = useSnackbar();

    const addAllToSelectedTrcker = async () => {
        pageUrls.forEach(addToSelectedTracker);
    }

    const addToSelectedTracker = async (url) => {
        try {
            await addToTracker({trackerId: selectedTracker, pageUrl: url, reportId: report.id}).unwrap();
            enqueueSnackbar(`Added ${extractPathFromUrl(url)} to tracker ${report.page_trackers.find(tracker => tracker.id === selectedTracker).label}`, {variant: 'success'});
            onClose();
            setSelectedTracker(report.page_trackers[0].id);
        } catch(error) {

        }
    }

    return <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
        TransitionProps={{appear: true}}
        PaperProps={{sx: {p: 2, maxWidth: '350px'}}}
    >
        <Box display={'flex'} alignItems={'center'}>
            <AddchartIcon fontSize={'large'} color={'secondary'}/>
            <Box ml={1}>
                <Typography
                    variant={'h4'}
                >Add to Performance Tracker</Typography>
                <UrlSubtitle>{pageUrls.length} pages</UrlSubtitle>
            </Box>
        </Box>
        <TextField
            select
            label={"Select Tracker Type"}
            fullWidth
            sx={{mt: 2}}
            value={selectedTracker}
            onChange={event => setSelectedTracker(parseInt(event.target.value))}
        >
            {report.page_trackers.map(tracker => (
                <MenuItem
                    value={tracker.id}
                    key={tracker.id}
                >{tracker.label}</MenuItem>
            ))}
        </TextField>
        <Typography sx={{mt: 1}} variant={'caption'}>Save pages to track their performance over time.</Typography>
        <Collapse in={error !== undefined}>
            {error ?
            <Typography color={'error'} variant={'caption'}>
                {('data' in error && 'message' in (error.data as any)) ? (error.data as {message: string}).message : 'An unexpected error occurred'}
            </Typography> : null}
        </Collapse>
        <Box display={'flex'} justifyContent={'right'} mt={1}>
            <LoadingButton
                variant={'outlined'}
                color={'primary'}
                loading={isLoading}
                onClick={addAllToSelectedTrcker}
            >Add</LoadingButton>
        </Box>
    </Popover>
}

export default MultiTrackPagePerformancePopover;