import * as React from 'react';
import GoogleAnalyticsIntegrationCard from "../integrations/GoogleAnalyticsIntegrationCard";
import WordPressIntegrationCard from "../integrations/WordPressIntegrationCard";
import GoogleSearchConsoleIntegrationCard from "../integrations/GoogleSearchConsoleIntegrationCard";
import {Box} from "@mui/material";

import {useContext} from "react";
import ReportContext from "../../../../context/ReportContext";


const IntegrationsSettingsPage = () => {
    const {report} = useContext(ReportContext);

    return <Box display={"flex"} justifyContent={"center"} width={"100%"}>
        <Box width={"80%"}>
            <GoogleAnalyticsIntegrationCard/>
            {report.enable_gsc_rankings ? <GoogleSearchConsoleIntegrationCard/> : null}
            <WordPressIntegrationCard/>
        </Box>
    </Box>;
}

export default IntegrationsSettingsPage;