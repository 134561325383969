import React, {useContext} from 'react';
import {useGetReportTodoItemsQuery} from "../../store/slice/myContentDashAdminApi";
import ReportContext from "../../context/ReportContext";
import {TransitionGroup} from "react-transition-group";
import {
    Box,
    BoxProps,
    Collapse, FormControlLabel, Switch,
    TablePagination, TextField
} from "@mui/material";
import ReportTodoItem from "../../types/ReportTodoItem";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {setPage, setSearch, setShowCompleted} from "../../store/slice/todoPanelSlice";
import TodoPageItem from "./TodoPageItem";

const todoItemsPerPage = 5;

const TodoItemsList = ({sx}: { sx?: BoxProps }) => {
    const {report} = useContext(ReportContext);
    const {data: todoItemsResponse} = useGetReportTodoItemsQuery({reportId: report.id});
    const dispatch = useAppDispatch();
    const showCompleted = useAppSelector(state => state.todoPanel.showCompleted);
    const page = useAppSelector(state => state.todoPanel.page);
    const search = useAppSelector(state => state.todoPanel.search);

    const pageUrls = [...todoItemsResponse.todo_items]
        .map(todo => todo.page_url)
        .filter(pageUrl => pageUrl !== null);

    const filterCompleted = (todo: ReportTodoItem) => showCompleted === true || (todo.status !== "done");

    let todoPages = pageUrls
        .filter((pageUrl, index) => pageUrls.indexOf(pageUrl) === index)
        .map(pageUrl => ({
            pageUrl,
            todoItems: todoItemsResponse.todo_items.filter(todo => todo.page_url === pageUrl && filterCompleted(todo))
        }));

    todoPages.unshift({
        pageUrl: null,
        todoItems: todoItemsResponse.todo_items.filter(todo => todo.page_url === null && filterCompleted(todo))
    });

    todoPages = todoPages.filter(({todoItems}) => todoItems.length > 0)
        .filter(todoPage =>
            search === '' ||
            (
                todoPage.pageUrl?.toLowerCase()?.includes(search.toLowerCase()) ||
                todoPage.todoItems.some(({text}) => text.toLowerCase().includes(search.toLowerCase()))
            )
        );

    const displayTodoPages = todoPages.slice(page * todoItemsPerPage, page * todoItemsPerPage + todoItemsPerPage);

    return <Box {...sx}>
        <Box display={'flex'} justifyContent={'space-between'}>
            <FormControlLabel
                control={
                    <Switch
                        checked={showCompleted}
                        onChange={(event, checked) => dispatch(setShowCompleted(checked))}
                    />}
                label="Show Completed"
            />
            <TablePagination
                component={"div"}
                count={todoPages.length}
                page={page}
                rowsPerPage={todoItemsPerPage}
                onPageChange={(event, page) => dispatch(setPage(page))}
                rowsPerPageOptions={[todoItemsPerPage]}
            />
        </Box>
        <TextField
            size={"small"}
            value={search}
            onChange={event => dispatch(setSearch(event.target.value))}
            label={"Search..."}
            fullWidth
            sx={{mb: 1}}
        />
        {/*@ts-ignore*/}
        <TransitionGroup>
            {displayTodoPages.map(todoPage => (
                <Collapse key={todoPage.pageUrl}>
                    <TodoPageItem todoPage={todoPage}/>
                </Collapse>
            ))}
        </TransitionGroup>
    </Box>
}

export default TodoItemsList;