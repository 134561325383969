import {Box, CircularProgress, Collapse, IconButton, Link, TableCell, TableRow, Typography} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import extractPathFromUrl from "../../util/extractPathFromUrl";
import DeleteIcon from "@mui/icons-material/Delete";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import Note from "../../types/Note";
import TextFieldNote from "./TextFieldNote";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {LoadingButton} from "@mui/lab";
import {
    useDeletePageNoteMutation,
    useGetSessionQuery,
    useUpdatePageNoteMutation
} from "../../store/slice/myContentDashAdminApi";
import {useSnackbar} from "notistack";
import ReportContext from "../../context/ReportContext";
import useRelativeFormattedDate from "../../hooks/useRelativeFormattedDate";

const NoteTextCaption = ({children}: { children: string }) => {
    const split = children.split('\n');
    let text = split[0];
    if (split.length > 1) {
        text += "…";
    }
    if(text.length > 80) {
        text = text.substring(0, 80) + "…";
    }

    return <Typography
        variant={'caption'}
        display={'inline'}
        whiteSpace={'nowrap'}
        overflow={'hidden'}
        textOverflow={'ellipsis'}
        maxWidth={'300px'}
    >
        {text}
    </Typography>
}

type NoteTableRowProps = {
    note: Note;
    isExpanded: boolean;
    setIsExpanded: (isExpanded: boolean) => void;
    onUpdated: () => void;
}

const NoteTableRow = ({note, isExpanded, setIsExpanded, onUpdated}: NoteTableRowProps) => {
    const [editingText, setEditingText] = useState(note.text);
    const changesMade = useMemo(() => note.text !== editingText, [note, editingText]);
    const {enqueueSnackbar} = useSnackbar();
    const {report} = useContext(ReportContext);
    const [updatePageNote, {isLoading: isSavingChanges}] = useUpdatePageNoteMutation();
    const [doDeletePageNote, {isLoading: isDeleting}] = useDeletePageNoteMutation();
    const {data: session} = useGetSessionQuery();
    const relativeTimestamp = useRelativeFormattedDate(note.added_on * 1000);
    const editedByUser = report.access_users.find(user => user.id === note.added_by);
    const editedByUserName = editedByUser ? (editedByUser.id === session.user.id ? 'you' : editedByUser.first_name) : null;

    const saveChanges = useCallback(async () => {
        try {
            await updatePageNote({
                noteId: note.id,
                text: editingText
            }).unwrap();
            onUpdated();
        } catch (error) {
            enqueueSnackbar(extractPathFromUrl(error), {variant: "error"});
        }

    }, [updatePageNote, note.id, editingText, enqueueSnackbar, onUpdated]);

    const deleteNote = async () => {
        try {
            await doDeletePageNote({
                noteId: note.id,
                reportId: report.id
            }).unwrap();
        } catch (error) {
            enqueueSnackbar(extractPathFromUrl(error), {variant: "error"});
        }
    }

    useEffect(() => {
        if (!isExpanded && changesMade) {
            saveChanges();
        }
    }, [isExpanded, changesMade, saveChanges]);

    return <TableRow key={note.id}>
        <TableCell sx={{pl: 0}}>
            <Box display={'flex'} width={'100%'}>
                <div>
                    <IconButton onClick={() => setIsExpanded(!isExpanded)} color={'primary'}>
                        {!isExpanded ? <KeyboardArrowRightIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </div>
                <Box width={'100%'}>
                    <Link href={note.page_url} target={"_blank"} sx={{fontSize: "1.1rem"}}>{extractPathFromUrl(note.page_url)}</Link>
                    <Collapse in={!isExpanded}>
                        "<NoteTextCaption>{note.text}</NoteTextCaption>"
                        - {` by ${editedByUserName} ${relativeTimestamp}`}
                    </Collapse>
                    <Collapse in={isExpanded}>
                        <Box display={'flex'} alignItems={'center'}>
                            <TextFieldNote
                                multiline
                                value={editingText}
                                onChange={(event) => setEditingText(event.target.value)}
                                rows={6}
                                fullWidth
                                sx={{mt: 1, flex: 1}}
                                disabled={isDeleting}
                            />
                            <LoadingButton
                                loading={isSavingChanges}
                                disabled={!changesMade || isDeleting || editingText === ''}
                                onClick={saveChanges}
                            >Save</LoadingButton>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
        </TableCell>
        <TableCell>
            <Box display={'flex'} justifyContent={'right'}>
                <IconButton disabled={isDeleting} onClick={deleteNote}>
                    {isDeleting ? <CircularProgress size={24}/> : <DeleteIcon/>}
                </IconButton>
            </Box>
        </TableCell>
    </TableRow>;
}

export default NoteTableRow;