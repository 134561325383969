import {MutableRefObject, useEffect, useMemo, useState} from "react";
import FieldFeaturedFilter from "../report/filters/FieldFeaturedFilter";
import ReportsApi from "../../api/ReportsApi";
import {UniqueFilterOptionsValue} from "../report/filters/UniqueFilterOptions";
import {useSelector} from "react-redux";
import Table from "../../types/Table";
import {DataSource} from "../../types/DataSource";
import {
    getDefaultFeaturedFilterInputs, tableInputSelector,
    updateTableInput
} from "../../store/slice/filtersSlice";
import ReactGA from "react-ga";
import GAEventCategory from "../../types/GAEventCategory";
import {GAEventActionReport} from "../../types/GAEventAction";
import isEqual from "react-fast-compare";
import {parseQueryArgs} from "../../util/parseQueryArgs";
import {push} from "redux-first-history";
import {useLocation, useSearchParams} from "react-router-dom";
import {useAppDispatch} from "../../store/store";
import {PageNotesFilterValue} from "../report/filters/PageNotesFilter";

export type FeaturedFilterInput = {
    featuredFilterId: number,
    input: FeaturedFilterInputValue,
    type: string
}

export type FeaturedFilterInputValue = (string | number[] | UniqueFilterOptionsValue | PageNotesFilterValue);

export type GeneratedFeaturedFilterRow = {
    row_id: number,
    label: string,
    count: number
}

export type GeneratedFeaturedFilterData = {
    featuredFilterId: number,
    rows: GeneratedFeaturedFilterRow[],
    cache_key: string
}

export type FilterCondition = {
    field: number,
    compare_type: string,
    value: string | number,
    id: number
}

export type FilterSet = FilterCondition[];

export type FilterData = FilterSet[];

export type AppIcon = {
    name: string,
    size: string,
    color: string
}

export type FeaturedFilter = {
    id: number,
    label: string,
    field: number,
    type: "filter_options" | "unique_database_options" | "unique_database_options_csv" | "unique_database_options_ssv" | "search" | "page_note",
    help: string | null,
    start_icon: AppIcon | null,
    cache_key?: string,
    filter_options?: FeaturedFilterOption[],
    radio_mode: boolean
}

export type FeaturedFilterOption = {
    id: number,
    label: string,
    default_checked: boolean,
    filter_data: FilterData,
    order: number,
    start_icon: AppIcon,
    end_icon: AppIcon,
    count?: number
}

export type FeaturedFilterCount = {
    optionId: number,
    count: number
}

export const injectGeneratedOptions = (featuredFilter: FeaturedFilter[], generatedFeaturedFilter: GeneratedFeaturedFilterData) => {
    return {
        ...featuredFilter,
        filter_options: generatedFeaturedFilter.rows.map(row => (
            {
                id: row.row_id,
                label: row.label,
                default_checked: true,
                filter_data: [],
                start_icon: [],
                end_icon: [],
                count: row.count
            }
        )),
        cache_key: generatedFeaturedFilter.cache_key
    }
}

export type ServerFormattedFeaturedFilterInput = {
    id: number,
    selected_options: FeaturedFilterInputValue,
    deselected_options?: FeaturedFilterInputValue,
    cache_key?: string
} | {
    id: number,
    page_urls: string[];
    mode: "page_url_is" | "page_url_is_not"
}

/**
 * Get featured filters that should be used for the table.
 * Excludes featured filters that aren't configured to be used, and inserts filter options for generated featured filter options.
 * @param table
 * @param dataSource
 * @param generatedFilterData
 */
export const getApplicableTableFeaturedFilters = (table, dataSource, generatedFilterData: GeneratedFeaturedFilterData[]): FeaturedFilter[] => {
    const combinedFieldFeaturedFilters = [].concat.apply([], dataSource.fields.map(field => field.featured_filters));

    if (table.inherit_all_featured_filters) {
        return combinedFieldFeaturedFilters;
    }
    const findOrder = featuredFilterId => table.featured_filters.find(featuredFilter => featuredFilter.id === featuredFilterId).order;
    return table.featured_filters
        .map(({id}) => combinedFieldFeaturedFilters.find(featuredFilter => featuredFilter.id === id))
        .sort((a, b) => findOrder(a.id) - findOrder(b.id))
        .map(featuredFilter => {
            const generatedFilter = generatedFilterData.find(
                ({featuredFilterId}) => featuredFilterId === featuredFilter.id
            )
            if (!generatedFilter) {
                return featuredFilter;
            }

            return injectGeneratedOptions(featuredFilter, generatedFilter);
        })
        .filter(featuredFilter => featuredFilter !== undefined);
}

export const useTableFilters = (
    table: Table,
    dataSource: DataSource,
    reportId: number,
    ref: MutableRefObject<any>,
    onError: (error: Error) => void
) => {
    const [generatedFeaturedFilterOptions, setGeneratedFeaturedFilterOptions] = useState<GeneratedFeaturedFilterData[] | null>(null);
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const tableFeaturedFilters = useMemo(() => {
        return getApplicableTableFeaturedFilters(
            table, dataSource, generatedFeaturedFilterOptions ? generatedFeaturedFilterOptions : []
        );
    }, [table, dataSource, generatedFeaturedFilterOptions]);
    const defaultFeaturedFilterInput = useMemo(() => (
        getDefaultFeaturedFilterInputs(
            getApplicableTableFeaturedFilters(
                table,
                dataSource,
                []
            )
        )
    ), [table, dataSource]);
    const dispatch = useAppDispatch();
    const {advancedFilters: userAdvancedFilters, featuredFilters: featuredFilterInput} = useSelector(tableInputSelector(table.id));

    const setFeaturedFilterInput = (featuredFilterInput: FeaturedFilterInput[]) => {
        dispatch(updateTableInput({
            tableId: table.id,
            input: {
                featuredFilters: featuredFilterInput
            }
        }))
        const convertedToObject = {};
        const unchangedIds = [];

        const defaultFeaturedFilterInput = getDefaultFeaturedFilterInputs(
            getApplicableTableFeaturedFilters(
                table,
                dataSource,
                []
            )
        )
        for (let ff of featuredFilterInput) {
            const originalFeaturedFilter = defaultFeaturedFilterInput.find(dff => dff.featuredFilterId === ff.featuredFilterId);
            const originalInput = Array.isArray(originalFeaturedFilter.input) ? originalFeaturedFilter.input.sort() : originalFeaturedFilter.input;
            const newInput = Array.isArray(ff.input) ? [...ff.input].sort() : ff.input;

            if (!isEqual(originalInput, newInput)) {
                convertedToObject["ff_" + ff.featuredFilterId] = JSON.stringify(ff.input);
            } else {
                unchangedIds.push(ff.featuredFilterId);
            }
        }
        const oldQuery = parseQueryArgs(searchParams.toString());

        const newQuery = Object.fromEntries(
            Object.keys(parseQueryArgs(searchParams.toString())) //Turn into array of keys for filtering
                .filter(key => !unchangedIds.includes(parseInt(key.substring(3)))) //Filter out IDs that should be removed
                .map(key => [key, oldQuery[key]]) //Re-insert values
        );

        const parsedParams = new URLSearchParams({
            ...newQuery,
            ...convertedToObject
        }).toString();
        dispatch(push(location.pathname + (parsedParams.length > 0 ? "?" + parsedParams : "")));
    }
    const setUserAdvancedFilters = (userAdvancedFilters: FilterData) => {
        dispatch(updateTableInput({
            tableId: table.id,
            input: {
                advancedFilters: userAdvancedFilters
            }
        }));
    }

    const [activeTablePresetId, setActiveTablePresetId] = useState<number | null>(null);
    const predefinedTableFilters: FilterSet = table.filter_preset ? table.filter_preset.filter_data : null;

    useEffect(() => {
        const refCurrent = ref.current;
        if (table.disabled) {
            return;
        }
        (async () => {
            const generatedFeaturedFilters = tableFeaturedFilters
                .filter(({type}) => ["unique_database_options", "unique_database_options_csv", "unique_database_options_ssv"].includes(type))
            refCurrent.generatedFeaturedFiltersRequest = new AbortController();
            try {
                const filterOptionsResponses = await Promise.all(
                    generatedFeaturedFilters.map(({id}) => ReportsApi.getUniqueFilterOptions({
                            featuredFilterId: id,
                            reportId: reportId,
                            tableId: table.id,
                            filterData: advancedFilters
                        }, refCurrent.generatedFeaturedFiltersRequest)
                    )
                );

                setGeneratedFeaturedFilterOptions(filterOptionsResponses.map((response, index) => {
                    const featuredFilter = generatedFeaturedFilters[index];
                    return {
                        featuredFilterId: featuredFilter.id,
                        rows: response.rows,
                        cache_key: response.cache_key
                    }
                }))
            } catch (error) {
                if (error.name !== "AbortError") {
                    onError(error);
                }
            }
        })();

        return () => {
            if (refCurrent.generatedFeaturedFiltersRequest) {
                refCurrent.generatedFeaturedFiltersRequest.abort();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const advancedFilters: FilterData = useMemo(() => {
        const allFilters = [];

        if (predefinedTableFilters !== null) {
            allFilters.push(predefinedTableFilters);
        }

        featuredFilterInput
            .filter(({type}: FeaturedFilterInput) => type === "search")
            .forEach(({input, featuredFilterId}) => {
                const field = dataSource.fields
                    .find(
                        field => field.id === tableFeaturedFilters.find(
                            ff => ff.id === featuredFilterId
                        ).field
                    );
                allFilters.push(
                    FieldFeaturedFilter.convertToFilterData(input, field, tableFeaturedFilters.find(ff => ff.id === featuredFilterId))
                );
            })

        if (userAdvancedFilters.length > 0)
            allFilters.push(userAdvancedFilters);

        if (activeTablePresetId !== null) {
            allFilters.push(table.filter_presets.find(({id}) => id === activeTablePresetId).filter_data);
        }

        return allFilters.filter(filterData => filterData !== null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featuredFilterInput, userAdvancedFilters, table]);

    const updateAdvancedFilterInput = (advancedFilterInput) => {
        setUserAdvancedFilters(
            advancedFilterInput
                .map(set => set.filter(condition => condition.value !== "")) // Remove empty condition values
                .filter(set => set.length > 0) //Remove empty filter sets (groups of conditions)
        );
    }

    const updateFeaturedFilterInput = (featuredFilterId: number, newInput: FeaturedFilterInputValue) => {
        setFeaturedFilterInput(
            featuredFilterInput.map(input => input.featuredFilterId === featuredFilterId ? {
                ...input,
                input: newInput
            } : input)
        );
        ReactGA.event({
            category: GAEventCategory.REPORT,
            action: GAEventActionReport.CHANGED_FEATURED_FILTER
        });
    }

    return {
        featuredFilterInput,
        updateFeaturedFilterInput,
        userAdvancedFilters,
        setAdvancedFilters: updateAdvancedFilterInput,
        activeTablePresetId,
        setActiveTablePresetId,
        advancedFilters,
        tableFeaturedFilters,
        defaultFeaturedFilterInput,
        generatedFiltersLoaded: generatedFeaturedFilterOptions !== null
    }
}