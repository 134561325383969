import * as React from 'react';
import {SearchConsoleResults} from "../../../types/SearchConsoleResults";
import FeaturedRankingStat from "./FeaturedRankingStat";
import averageOf from "../../../util/averageOf";
import sumOf from "../../../util/sumOf";
import {Tooltip, Typography} from "@mui/material";
import {format} from "date-fns";
import formatNumber from "../../../util/formatNumber";
import {SearchConsoleHistoryDifference} from "../../../hooks/useSearchConsolePerformanceHistory";

export type SearchConsoleFeaturedStatsProps = {
    searchConsoleHistory: SearchConsoleResults;
    searchConsoleHistoryDifference: SearchConsoleHistoryDifference;
}

const SearchConsoleFeaturedStats = ({
                                        searchConsoleHistoryDifference
                                    }: SearchConsoleFeaturedStatsProps) => {

    const {beforeRows, afterRows} = searchConsoleHistoryDifference;
    const beforeUpdateStart = format(searchConsoleHistoryDifference.beforeRangeStart, "MMM do");
    const beforeUpdateEnd = format(searchConsoleHistoryDifference.beforeRangeEnd, "MMM do");
    const afterUpdateStart = format(searchConsoleHistoryDifference.afterRangeStart, "MMM do");
    const afterUpdateEnd = format(searchConsoleHistoryDifference.afterRangeEnd, "MMM do");

    return <>
        <Tooltip title={<>
            <Typography>
                {`${beforeUpdateStart} - ${beforeUpdateEnd}: ${formatNumber(sumOf(beforeRows.map(row => row.clicks)), 2)} Clicks`}
            </Typography>
            <Typography>
                {`${afterUpdateStart} - ${afterUpdateEnd}: ${formatNumber(sumOf(afterRows.map(row => row.clicks)), 2)} Clicks`}
            </Typography>
        </>}>
            <FeaturedRankingStat
                label={"Clicks"}
                value={sumOf(afterRows.map(row => row.clicks))}
                previousValue={sumOf(beforeRows.map(row => row.clicks))}
                sx={{mb: 2}}
            />
        </Tooltip>
        <Tooltip title={<>
            <Typography>
                {`${beforeUpdateStart} - ${beforeUpdateEnd}: ${formatNumber(sumOf(beforeRows.map(row => row.impressions)), 2)} Impressions`}
            </Typography>
            <Typography>
                {`${afterUpdateStart} - ${afterUpdateEnd}: ${formatNumber(sumOf(afterRows.map(row => row.impressions)), 2)} Impressions`}
            </Typography>
        </>}>
            <FeaturedRankingStat
                label={"Impressions"}
                value={sumOf(afterRows.map(row => row.impressions))}
                previousValue={sumOf(beforeRows.map(row => row.impressions))}
                sx={{mb: 2}}
            />
        </Tooltip>
        <Tooltip title={<>
            <Typography>
                {`${beforeUpdateStart} - ${beforeUpdateEnd}: ${formatNumber(averageOf(beforeRows.map(row => row.position)), 1)} Avg Position`}
            </Typography>
            <Typography>
                {`${afterUpdateStart} - ${afterUpdateEnd}: ${formatNumber(averageOf(afterRows.map(row => row.position)), 1)} Avg Position`}
            </Typography>
        </>}>
            <FeaturedRankingStat
                label={"Avg Position"}
                value={averageOf(afterRows.map(row => row.position))}
                previousValue={averageOf(beforeRows.map(row => row.position))}
                sx={{mb: 2}}
                reverseDifferencePercentage
            />
        </Tooltip>
    </>;
}

export default SearchConsoleFeaturedStats;