import React from "react";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import {useGetWpPageQuery} from "../../store/slice/myContentDashAdminApi";

const ReportWpPage = ({wpPageId}: { wpPageId: number }) => {
    const {data: page, isLoading} = useGetWpPageQuery(wpPageId);

    return <div>
        {
            isLoading ? <LinearProgress/> : null
        }
        <Box p={2}>
            <main id="site-content" role="main">
                <article className="post-1187 page type-page status-publish hentry" id="post-1187">
                    <div className="post-inner thin ">
                        <div className="entry-content">
                            <div dangerouslySetInnerHTML={{__html: page?.content?.rendered}}/>
                        </div>
                    </div>
                </article>
            </main>
        </Box>
    </div>;
}

ReportWpPage.propTypes = {
    wpPageId: PropTypes.number.isRequired
}

export default ReportWpPage;