import * as React from 'react';
import IntegrationCard from "./IntegrationCard";
import GoogleSearchConsoleBanner from "../../../icon/GoogleSearchConsoleBanner";
import SearchConsoleConfiguration from "./SearchConsoleConfiguration";
import ConnectGoogleButton from "./ConnectGoogleButton";
import ResetGoogleButton from "../../../ResetGoogleButton";
import {Box} from "@mui/material";

import {useContext} from "react";
import ReportContext from "../../../../context/ReportContext";

type GoogleSearchConsoleIntegrationCardProps = {
    defaultExpanded?: boolean;
}

const GoogleSearchConsoleIntegrationCard = ({defaultExpanded = false}: GoogleSearchConsoleIntegrationCardProps) => {
    const {report} = useContext(ReportContext);


    return <IntegrationCard
        header={<GoogleSearchConsoleBanner
            style={{height: "100%"}}
            SvgProps={{style: {width: "auto", maxWidth: "auto", height: "100%"}}}
        />}
        isConnected={
            report.integrations.google.is_connected &&
            report.integrations.google.search_console.property_domain !== null
        }
        defaultExpanded={defaultExpanded}
    >
        {report.integrations.google.is_connected && report.integrations.google.scopes.includes("webmasters.readonly") ?
            <>
                <SearchConsoleConfiguration report={report} googleIntegration={report.integrations.google}/>
                <Box display={"flex"} justifyContent={"center"}>
                    <ResetGoogleButton
                        resetFor={"search_console"}
                        variant={"contained"}
                        disabled={report.integrations.google.lock_configuration}
                    />
                </Box>
            </> :
            <ConnectGoogleButton/>
        }
    </IntegrationCard>
}


export default GoogleSearchConsoleIntegrationCard;