import {createTheme} from "@mui/material/styles";

const theme = createTheme({
    typography: {
        h1: {
            fontSize: "2rem"
        },
        h2: {
            fontSize: "1.7rem"
        },
        h3: {
            fontSize: "1.6rem"
        },
        h4: {
            fontSize: "1.4rem"
        }
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                sizeSmall: {
                    padding: "6px 10px"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Montserrat",
                    fontWeight: 500
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                h1: {
                    fontFamily: "Montserrat",
                    fontSize: "1.75rem"
                },
                h2: {
                    fontFamily: "Montserrat"
                },
                h3: {
                    fontFamily: "Montserrat"
                },
                h4: {
                    fontFamily: "Montserrat",
                    fontSize: "1.2rem"
                },
                h5: {
                    fontSize: "1.1rem",
                    fontFamily: "Montserrat"
                },
                h6: {
                    fontFamily: "Montserrat"
                }
            }
        }
    },
    palette: {
        primary: {
            main: '#FB9039',
            contrastText: "#fff" //button text white instead of black
        },
        secondary: {
            main: '#1F3044',
            dark: "#1F3044"
        },
    },
});

export default theme;