import useCurrentPage from "./useCurrentPage";
import {useAppSelector} from "../store/store";
import {searchBarSelector} from "../store/slice/reportSelector";
import {SearchBarInput} from "../components/table/useReportTable";

const defaultSearchBarInput: SearchBarInput = {
    searchTerm: "",
    useAdvancedSearch: true,
    columnIds: []
}

const useSearchBarValue = (): SearchBarInput => {
    const {id: pageId} = useCurrentPage();

    const searchBarInput = useAppSelector(typeof pageId === 'number' ? searchBarSelector(pageId) : () => undefined);
    return searchBarInput ? searchBarInput : defaultSearchBarInput;
}

export default useSearchBarValue;