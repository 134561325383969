import React from "react";
import {PageComponent as PageComponentType} from "../../../types/PostTabPage";
import PageComponentColumns from "./component/PageComponentColumns";
import PageComponentFieldValue from "./component/PageComponentFieldValue";
import {styled} from "@mui/styles";

export type PageComponentProps = {
    component: PageComponentType;
}

const componentTypes = {
    'columns': PageComponentColumns,
    'field': PageComponentFieldValue
}

const ComponentContainer = styled('div')(({theme}) => ({
    '&:not(:first-child)': {
        borderTop: '1px solid ' + theme.palette.grey['400']
    },
    position: 'relative',
    boxSizing: 'border-box'
}))

const PageComponent = ({component}: PageComponentProps) => {
    const PageComponentValue = componentTypes[component.type];

    return <ComponentContainer>
        <PageComponentValue component={component}/>
    </ComponentContainer>
}

export default PageComponent;