import React from 'react';
import {Popover} from "@mui/material";
import AddTodoItemTextField from "./AddTodoItemTextField";
import {useSnackbar} from "notistack";

type MultiTodoPopoverProps = {
    open: boolean;
    onClose: () => void;
    pageUrls: readonly string[];
    anchorEl: HTMLElement;
}
const AddMultiTodoPopover = ({open, onClose, pageUrls, anchorEl} : MultiTodoPopoverProps) => {
        const {enqueueSnackbar} = useSnackbar();

        const onTodoAdded = () => {
            onClose();
            enqueueSnackbar(`Added Todo notes to ${pageUrls.length} pages`, {variant: "success"});
        }

        return <Popover
                open={open}
                onClose={onClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                PaperProps={{sx: {p: 1}}}
                anchorEl={anchorEl}
            >
                <AddTodoItemTextField
                    sx={{width: '350px'}}
                    pageUrls={pageUrls}
                    onAdded={onTodoAdded}
                />
            </Popover>
    }


export default AddMultiTodoPopover;