import React, {FC, useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import TextField from "@mui/material/TextField";
import {IconButton} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import useEffectWhen from "../../../util/useEffectWhen";
import {FeaturedFilter} from "../../table/useTableFilters";

export type SearchFilterProps = {
    value: string,
    onChange: (string) => void,
    featuredFilter: FeaturedFilter,
    closePopover: () => void
}

const SearchFilter: FC<SearchFilterProps> = ({value, onChange, featuredFilter, closePopover}) => {
    const [unsavedFilterValue, setUnsavedFilterValue] = useState("");

    useEffectWhen(() => {
        if (value !== unsavedFilterValue) {
            setUnsavedFilterValue(value);
        }
    }, [unsavedFilterValue], [value]);

    const saveChanges = () => {
        onChange(unsavedFilterValue);
        closePopover();
    }

    return <>
        <TextField
            label={featuredFilter.label + " contains..."}
            InputProps={{startAdornment: <SearchIcon/>}}
            value={unsavedFilterValue}
            onChange={event => setUnsavedFilterValue(event.target.value)}
            size={"small"}
            onKeyDown={ev => {
                if (ev.key === "Enter") {
                    saveChanges();
                    ev.preventDefault();
                }
            }}
        />
        <IconButton onClick={saveChanges} disabled={value === unsavedFilterValue}>
            <DoneIcon/>
        </IconButton>
    </>;
}


export default SearchFilter;