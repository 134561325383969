import React, {useContext} from 'react';
import {Badge, Button, ButtonProps} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {useGetReportTodoItemsQuery} from "../../store/slice/myContentDashAdminApi";
import ReportContext from "../../context/ReportContext";
import {ListAlt as ListAltIcon} from "@mui/icons-material";
import {useAppDispatch} from "../../store/store";
import {openTodoPanel} from "../../store/slice/todoPanelSlice";

const AllTodoItemsButton = (props: Omit<ButtonProps, "onClick">) => {
    const {report} = useContext(ReportContext);
    const {data: todoItemsResponse} = useGetReportTodoItemsQuery({reportId: report.id});
    const dispatch = useAppDispatch();

    return <Button
        startIcon={
            todoItemsResponse ?
                <Badge
                    badgeContent={todoItemsResponse.todo_items.filter(todo => todo.status !== "done").length}
                    color={'primary'}
                    sx={{fontSize: '8px'}}
                >
                    <ListAltIcon/>
                </Badge> : <AssignmentIcon/>
        }
        {...props}
        onClick={() => dispatch(openTodoPanel())}
    >
        Todo
    </Button>
}

export default AllTodoItemsButton;