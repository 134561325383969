import {RootState} from "../store";
import {SearchBarInput} from "../../components/table/useReportTable";

const splitRoute = pathname => pathname.split("/").filter(urlSection => urlSection !== "");

export const defaultSearchBarValue = {
    columnIds: [],
    searchTerm: "",
    useAdvancedSearch: false
}

export const searchBarSelector = (pageId: number) => (state: RootState): SearchBarInput => {
    const searchValue = state.report.searchBarValues.find(searchBarValue => searchBarValue.pageId === pageId)
    return (!searchValue) ? defaultSearchBarValue : searchValue
}

/**
 * Get the path route of the current page (i.e "home" or "content-audit").
 * @param state
 * @returns {null|*}
 */
export const reportPagePathnameSelector = (state: RootState) => {
    const split = splitRoute(state.report.pathname);
    if (split.length < 3) {
        return null;
    }
    return split[2];
}
