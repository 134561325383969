import {Button, ButtonProps} from "@mui/material";
import {NavLink} from "react-router-dom";
import React from "react";
import {useGetReportsQuery} from "../store/slice/myContentDashAdminApi";

const SwitchReportsButton = (props: ButtonProps) => {
    const {data: reportsList} = useGetReportsQuery();
    if (reportsList === undefined || reportsList.reports.length < 2) {
        return null;
    }

    return <NavLink to={"/reports"}>
        <Button {...props}>{props.children || "Switch Report"}</Button>
    </NavLink>
}

export default SwitchReportsButton;