import React, {FC, useState} from "react";
import Checkbox from "@mui/material/Checkbox";
import {
    InputAdornment,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField
} from "@mui/material";
import {FixedSizeList} from "react-window";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import formatNumber from "../../../util/formatNumber";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import {Close, Search} from "@mui/icons-material";
import FilterIcon from "@mui/icons-material/FilterList";
import {AppIcon} from "hashtagjeff-shared-components";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Collapse from "@mui/material/Collapse";
import {makeStyles} from '@mui/styles'
import {FeaturedFilter} from "../../table/useTableFilters";

const useStyles = makeStyles(() => ({
    filterOptionLabel: {
        wordBreak: "break-word",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    uniqueFilterOptionsList: {
        wordBreak: "break-word"
    },
    listEvenItem: {
        backgroundColor: "#f8f8f0"
    },
    listOddItem: {}
}));

export type UniqueFilterOptionsValue = {
    mode: 'selected' | 'deselected',
    selected: number[]
}

export type UniqueFilterOptionsProps = {
    value: UniqueFilterOptionsValue,
    onChange: (UniqueFilterOptionsValue) => void,
    featuredFilter: FeaturedFilter,
}

const UniqueFilterOptions: FC<UniqueFilterOptionsProps> = ({value, onChange, featuredFilter}) => {
    const classes = useStyles();
    const [showCountIndex, setShowCountIndex] = useState(false);
    const [search, setSearch] = useState("");

    const setSelected = (optionId, selected) => {
        let newValue = {...value};

        if ((value.mode === 'selected' && selected) || (value.mode === 'deselected' && !selected)) {
            newValue.selected = [...newValue.selected, optionId];
        } else {
            newValue.selected = newValue.selected.filter(existingOptionId => existingOptionId !== optionId);
        }

        newValue = maybeSwapValueMode(newValue);

        onChange(newValue);
    }

    const maybeSwapValueMode = (newValue) => {
        if (newValue.selected.length * 2 > featuredFilter.filter_options.length) {
            newValue.mode = (newValue.mode === 'deselected') ? 'selected' : 'deselected';
            newValue.selected = featuredFilter.filter_options.map(option => option.id).filter(optionId => newValue.selected.indexOf(optionId) === -1)
        }

        return newValue;
    }

    const selectOnly = (selectedOptionId) => {
        onChange({
            selected: [selectedOptionId],
            mode: 'selected'
        });
    }

    const selectAll = () => {
        if (!search) {
            onChange({selected: [], mode: 'deselected'});
            return;
        }

        let newValue = {
            selected: filteredFilterOptions.map(option => option.id),
            mode: 'selected'
        };

        newValue = maybeSwapValueMode(newValue);
        onChange(newValue);
    }

    const selectNone = () => {
        if (!search) {
            onChange({selected: [], mode: 'selected'});
            return;
        }

        let newValue = {
            selected: filteredFilterOptions.map(option => option.id),
            mode: 'deselected'
        };

        newValue = maybeSwapValueMode(newValue);
        onChange(newValue);
    }

    const filteredFilterOptions =
        search === ""
            ? featuredFilter.filter_options
            : featuredFilter.filter_options.filter((filterOption) =>
                filterOption.label.toLowerCase().includes(search.toLowerCase())
            );

    const getItemCount = () => {
        if (featuredFilter.filter_options)
            return filteredFilterOptions.length;

        return 0;
    }

    const getIsChecked = (optionId) => {
        if (!featuredFilter.filter_options)
            return true;

        if (value.mode === 'selected')
            return value.selected.indexOf(optionId) !== -1;
        else
            return value.selected.indexOf(optionId) === -1;
    }

    const getAllSelected = () => {
        if (!search)
            return value.mode === 'deselected' && value.selected.length === 0;

        if (value.mode === 'selected')
            return filteredFilterOptions.every(option => value && value.selected.indexOf(option.id) !== -1);
        else
            return filteredFilterOptions.every(option => value && value.selected.indexOf(option.id) === -1);
    }

    const allSelected = getAllSelected();
    const noneSelected = value.mode === 'selected' && value.selected.length === 0;
    const someSelected = !allSelected && !noneSelected;

    function renderRow(props) {
        const {index, style} = props;

        return (<ListItem button style={style} key={index}
                          className={(index - 1) % 2 ? classes.listOddItem : classes.listEvenItem}
                          onMouseEnter={() => setShowCountIndex(index)}
                          onMouseLeave={() => setShowCountIndex(false)}
            >
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={1}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={getIsChecked(filteredFilterOptions[index].id)}
                                onChange={event => setSelected(filteredFilterOptions[index].id, event.target.checked)}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                    </Grid>
                    <Grid item xs={featuredFilter.type === 'unique_database_options' ? 10 : 9}>
                        <ListItemText
                            primary={<Typography
                                variant={featuredFilter.type === 'unique_database_options' ? "body2" : "body1"}
                            >{filteredFilterOptions[index].label}</Typography>} className={classes.filterOptionLabel}
                            title={filteredFilterOptions[index].label}/>
                    </Grid>
                    <Grid item xs={featuredFilter.type === 'unique_database_options' ? 1 : 2}>

                        {showCountIndex === index ?
                            <ListItemIcon>
                                <Button
                                    onClick={() => selectOnly(filteredFilterOptions[index].id)}
                                    size={"small"}
                                    data-test={"filter-only"}
                                >Only </Button>

                            </ListItemIcon> :
                            <ListItemText primary={<Typography
                                variant={"caption"}
                                sx={{ml: 2}}
                            >{formatNumber(filteredFilterOptions[index].count, 1)}</Typography>}
                            />
                        }
                    </Grid>
                </Grid>
            </ListItem>
        );
    }

    return <>
        {featuredFilter.filter_options ? <>
                <List dense={true} disablePadding={true}>
                    <ListItem button key={'select-all'}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={allSelected}
                                indeterminate={someSelected}
                                tabIndex={-1}
                                disableRipple
                                onChange={() => allSelected ? selectNone() : selectAll()}
                            />
                        </ListItemIcon>
                        <ListItemIcon>
                            <AppIcon iconData={featuredFilter.start_icon} defaultIcon={FilterIcon}/>
                        </ListItemIcon>
                        <ListItemText primary={featuredFilter.label}/>
                    </ListItem>
                </List>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={11}>
                        <TextField
                            label="Type to search"
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                            size="small"
                            margin="normal"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        /></Grid>
                    <Grid item xs={1}>
                        <Collapse in={search !== ""}>
                            <IconButton aria-label="cancel search" title={"cancel search"} style={{color: '#979DA2'}}
                                        edge={"end"} onClick={() => setSearch("")}>
                                <Close/>
                            </IconButton>
                        </Collapse>
                    </Grid>
                </Grid>
                <div data-test={'unique-filter-options'}><FixedSizeList height={400} width={featuredFilter.type === 'unique_database_options' ? 1000 : 500}
                               itemSize={50}
                               itemCount={getItemCount()} className={classes.uniqueFilterOptionsList}>
                    {renderRow}
                </FixedSizeList></div></> :
            <Skeleton variant="rectangular"
                      width={featuredFilter.type === 'unique_database_options' ? 1000 : 500}
                      height={400}
                      animation={"wave"}
            />
        }
    </>;

}

export default UniqueFilterOptions;