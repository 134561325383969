import * as React from 'react';
import {useEffect, useState} from 'react';
import {Report} from "../../../../types/Report";
import {GoogleIntegrationSetup} from "../../../../types/GoogleIntegration";
import {Box, Collapse, MenuItem, TextField, Typography} from "@mui/material";
import useEffectWhen from "../../../../util/useEffectWhen";
import {useConfigureSearchConsoleConnectionMutation} from "../../../../store/slice/myContentDashAdminApi";

type SearchConsoleConfigurationProps = {
    report: Report;
    googleIntegration: GoogleIntegrationSetup;
}

const fixDomainPropertyName = (propertyUrl: string): string => {
    return propertyUrl.replace("sc-domain:", "");
}

const SearchConsoleConfiguration = ({report, googleIntegration}: SearchConsoleConfigurationProps) => {
    const [selectedProperty, setSelectedProperty] = useState(googleIntegration.search_console.property_domain);
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState<string | null>(null);
    const [configureSearchConsole] = useConfigureSearchConsoleConnectionMutation();

    const saveChanges = async () => {
        setIsSaving(true);
        setSaveError(null);

        try {
            await configureSearchConsole({
                reportId: report.id,
                searchConsoleProperty: selectedProperty
            }).unwrap();
        } catch (error) {
            setSaveError(error.data.message);
            console.error(error);
        }

        setIsSaving(false);
    }

    useEffectWhen(() => {
        if (selectedProperty && (googleIntegration.search_console.property_domain === null || googleIntegration.search_console.property_domain !== selectedProperty)) {
            saveChanges();
        }
    }, [selectedProperty, googleIntegration], [selectedProperty]);

    useEffect(() => {
        setSelectedProperty(googleIntegration.search_console.property_domain);
    }, [googleIntegration]);

    return <>
        <TextField
            select
            label={"Search Console Property"}
            fullWidth
            value={selectedProperty || ""}
            onChange={event => {
                setSelectedProperty(event.target.value === "" ? null : event.target.value);
            }}
            disabled={googleIntegration.lock_configuration}
        >
            <MenuItem value={""} disabled>Select...</MenuItem>
            {googleIntegration.search_console_properties.map(property => (
                <MenuItem
                    value={property.url}
                    key={property.url}
                    disabled={property.permission_level === "siteUnverifiedUser"}
                >
                    {fixDomainPropertyName(property.url)}
                </MenuItem>
            ))}
        </TextField>
        <Box visibility={isSaving ? "visible" : "hidden"}>
            <Typography color={"success.main"} fontSize={"0.8em"}>Saving...</Typography>
        </Box>
        <Collapse in={saveError !== null}>
            <Typography color={"error.main"} fontSize={"0.8em"}>{saveError}</Typography>
        </Collapse>
    </>
}

export default SearchConsoleConfiguration