import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";

const HiddenColumnRow = ({isSelected, column, onChange}) => {
    return <TableRow>
        <TableCell>
            <Checkbox size={"small"} checked={isSelected} onChange={event => onChange(event.target.checked)}/>
        </TableCell>
        <TableCell>
            {column.label}
        </TableCell>
    </TableRow>
}

export default HiddenColumnRow;