export enum GAEventActionUser {
    LOGGED_IN = "Logged In",
    LOGGED_OUT = "Logged Out",
    SIGNED_UP = "Signed Up",
    CONNECTED_WORDPRESS = "Connected WordPress Site",
    CONNECTED_GOOGLE_ANALYTICS = "Connected Google Analytics",

}

export enum GAEventActionReport {
    SUBMITTED_TABLE_SEARCH = "Used Table Search",
    CHANGED_FEATURED_FILTER = "Changed Featured Filter"
}