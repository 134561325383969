const globalVars = {
    drawerWidthExpandedPixels: 260,
    drawerWidthExpanded: "260px",
    drawerWidthMinimized: "79px",
    colors: {
        pageBackground: "#EEEEEE",
        sidebarBackground: "#29333c",
        iconsColor: "#555555",
        text: "rgb(85, 85, 85)"
    }
}

export default globalVars;