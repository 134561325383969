import React from "react";
import Popover from "@mui/material/Popover";
import {makeStyles} from '@mui/styles'
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {Navigate, NavLink} from "react-router-dom";
import AssessmentIcon from '@mui/icons-material/Assessment';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {ListItemIcon} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useGetSessionQuery, useLogOutMutation} from "../../store/slice/myContentDashAdminApi";
import {useAppDispatch} from "../../store/store";
import {openAccountModal} from "../../store/slice/appSlice";
import {isUserSubscriptionActive} from "../../util/isUserSubscriptionActive";

const useStyles = makeStyles(theme => ({
    nameHeader: {
        fontSize: "1rem",
        color: "rgb(85, 85, 85)"
    },
    popover: {
        marginTop: theme.spacing(2)
    }
}));

export type UserProfileActionsPopoverProps = {
    anchorEl: HTMLElement;
    onClose: () => void;
    showSwitchReport: boolean;
    openSettingsPanel: () => void;
}

const UserProfileActionsPopover = ({
                                       anchorEl,
                                       onClose,
                                       showSwitchReport
                                   }: UserProfileActionsPopoverProps) => {
    const classes = useStyles();
    const {data: session} = useGetSessionQuery();
    const [logOut] = useLogOutMutation();
    const user = session.user;
    const dispatch = useAppDispatch();

    const doLogOut = async () => {
        onClose();
        await logOut();
    }
    if (!user) {
        return <Navigate to={"/login"}/>;
    }


    return <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        keepMounted={false}
        className={classes.popover}
    >
        <div data-test={"user-actions-modal"}>
            <List dense component={"div"}>
                {showSwitchReport ?
                    <NavLink to={"/reports"}>
                        <ListItem button>
                            <ListItemIcon>
                                <AssessmentIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"Switch Report"}/>
                        </ListItem>
                    </NavLink>
                    : null}
                {isUserSubscriptionActive(user) ?
                    <>
                        <ListItem
                            button
                            onClick={() => {
                                dispatch(openAccountModal())
                                onClose();
                            }}
                        >
                            <ListItemIcon>
                                <CreditCardIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={"Account"}
                            />
                        </ListItem>
                    </> : null
                }
                <NavLink to={"/reports/add"} style={{textDecoration: "none"}}>
                    <ListItem
                        button
                    >
                        <ListItemIcon>
                            <AddIcon/>
                        </ListItemIcon>
                        <ListItemText
                            primary={"Add Website"}
                        />
                    </ListItem>
                </NavLink>
                <ListItem button onClick={doLogOut} data-test={"logout-button"}>
                    <ListItemIcon>
                        <ExitToAppIcon/>
                    </ListItemIcon>
                    <ListItemText
                        primary={"Log Out"}
                    />
                </ListItem>
            </List>
        </div>
    </Popover>
}

export default UserProfileActionsPopover;