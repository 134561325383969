import * as React from 'react';
import {useContext, useRef, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {Button, ButtonProps, Popover} from '@mui/material';
import {GoogleIntegrationSetup} from '../types/GoogleIntegration';

import ReportContext from '../context/ReportContext';
import {
    useDeleteAnalyticsConfigurationMutation,
    useDeleteSearchConsoleConfigurationMutation, useResetGoogleConnectionMutation
} from '../store/slice/myContentDashAdminApi';

type ResetGoogleButtonProps = {
    resetFor: 'analytics' | 'search_console',
    children?: string
} & ButtonProps;

const optionLabel = {
    google: 'Google',
    analytics: 'Google Analytics',
    search_console: 'Search Console'
}

const ResetGoogleButton = ({resetFor, children, ...props}: ResetGoogleButtonProps) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const {report} = useContext(ReportContext);
    const ref = useRef();
    const google = report.integrations.google as GoogleIntegrationSetup;
    let isConnected = resetFor === 'search_console' ? google.search_console.property_domain !== null : (google.analytics_type === 'UA' ? google.analytics.view !== null : google.analytics4.property !== null);
    const label = isConnected ? 'Reset...' : 'Disconnect Google';
    const serviceName = optionLabel[resetFor];
    const [resetGoogleAnalyticsConfiguration, {isLoading: isResettingAnalytics}] = useDeleteAnalyticsConfigurationMutation();
    const [resetSearchConsoleConfiguration, {isLoading: isResettingSearchConsole}] = useDeleteSearchConsoleConfigurationMutation();
    const [deleteGoogleConnection, {isLoading}] = useResetGoogleConnectionMutation();

    const disconnectGoogle = async () => {
        setPopoverOpen(false);
        deleteGoogleConnection({reportId: report.id});
    }

    const resetGoogleService = async () => {
        setPopoverOpen(false);
        const resetFunc = resetFor === 'analytics' ? resetGoogleAnalyticsConfiguration : resetSearchConsoleConfiguration;
        resetFunc({reportId: report.id});
    }

    return <>
        <LoadingButton
            loading={isLoading || isResettingAnalytics || isResettingSearchConsole}
            disabled={popoverOpen}
            ref={ref}
            onClick={() => !isConnected ? disconnectGoogle() : setPopoverOpen(true)}
            {...props}
        >{label}</LoadingButton>
        <Popover
            open={popoverOpen}
            anchorEl={ref.current}
            onClose={() => setPopoverOpen(false)}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            PaperProps={{sx: {p: 1}}}
        >
            <Button
                sx={{display: 'block', mb: 1}}
                variant={'outlined'}
                color={'secondary'}
                onClick={() => resetGoogleService()}
            >
                {'Reset ' + serviceName}
            </Button>
            <Button
                sx={{display: 'block'}}
                variant={'outlined'}
                color={'error'}
                onClick={() => disconnectGoogle()}
            >
                Disconnect Google
            </Button>
        </Popover>
    </>
}


export default ResetGoogleButton;