import {
    CircularProgress,
    Collapse,
    Dialog,
    DialogContent,
    DialogTitle,
    FormHelperText,
} from "@mui/material";
import React from "react";
import useBillingPortal from "../../hooks/useBillingPortal";
import {LoadingButton} from "@mui/lab";
import {
    useGetSessionQuery,
    useGetSubscriptionQuery,
} from "../../store/slice/myContentDashAdminApi";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SubscriptionInfo from "./SubscriptionInfo";
import UpgradePlanButton from "../report/UpgradePlanButton";

type AccountModalProps = {
    open: boolean;
    onClose: () => void;
}

const AccountModal = ({open, onClose}: AccountModalProps) => {
    const {
        isLoadingSession: isLoadingBillingPortal,
        error: billingPortalError,
        navigateToBillingPortal
    } = useBillingPortal();
    const {data: subResponse} = useGetSubscriptionQuery(undefined, {skip: !open});
    const {data: {user}} = useGetSessionQuery();

    return <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth={'xs'}
    >
        <DialogTitle>My Account</DialogTitle>
        <DialogContent>
            <LoadingButton loading={isLoadingBillingPortal} onClick={navigateToBillingPortal} variant={'contained'}
                           endIcon={<KeyboardArrowRightIcon/>}>
                Go To Billing Portal
            </LoadingButton>
            {user.can_plan_be_upgraded &&
                <UpgradePlanButton variant={"outlined"} size={"medium"} sx={{mt: 2}}>
                    Upgrade to Full Plan
                </UpgradePlanButton>
            }
            <Collapse in={billingPortalError !== null}>
                <FormHelperText color={'error'}>{billingPortalError}</FormHelperText>
            </Collapse>
            {!subResponse ? <CircularProgress sx={{mt: 2}} size={24}/> : <SubscriptionInfo
                billingPeriod={subResponse.subscription.period}
                allowYearly={subResponse.subscription.allow_yearly}
                mt={2}
            />}
        </DialogContent>
    </Dialog>
}

export default AccountModal;