import React, {useContext, useRef, useState} from "react";
import {makeStyles} from '@mui/styles'
import {Box, Fab, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListIcon from '@mui/icons-material/List';
import PropTypes from "prop-types";
import PersonIcon from '@mui/icons-material/Person';
import Button from "@mui/material/Button";
import UserProfileActionsPopover from "./UserProfileActionsPopover";
import globalVars from "../../styles/globalVars";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsIcon from '@mui/icons-material/Settings';
import doesUserOwnReport from "../../util/doesUserOwnReport";
import {useGetReportsQuery, useGetSessionQuery} from "../../store/slice/myContentDashAdminApi";
import useCurrentPage from "../../hooks/useCurrentPage";
import ReportContext from "../../context/ReportContext";
import AllPageNotesButton from "../page-notes/AllPageNotesButton";
import AllTodoItemsButton from "../todo-items/AllTodoItemsButton";
import {GoogleIntegrationNotSetup} from "../../types/GoogleIntegration";

const useStyles = makeStyles(theme => ({
    dashboardHeader: {
        height: "40px",
        padding: "15px"
    },
    dashboardHeaderLeft: {
        float: "left"
    },
    dashboardHeaderRight: {
        float: "right"
    },
    minimizeSidebarButton: {
        color: globalVars.colors.iconsColor,
        background: "#FFF",
        boxShadow: "0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)",
        '&:hover': {
            backgroundColor: "#FFF",
            boxShadow: "0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2)"
        },
        float: "left"
    },
    pageTitle: {
        color: theme.palette.grey.A700,
        display: "inline-block",
        lineHeight: "30px"
    },
    headerButton: {
        paddingLeft: "12px",
        paddingRight: "12px",
        fontSize: "20px",
        height: "41px",
        minWidth: "41px",
        width: "41px",
        "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
            marginRight: "0px"
        },
        color: globalVars.colors.iconsColor,
        margin: `0px ${theme.spacing(1)}px`
    },
    headerLinkButtonIcon: {
        width: "24px !important",
        height: "24px !important"
    },
    reportDomainTitle: {
        color: theme.palette.grey.A700,
        fontSize: "14px",
        marginLeft: "2px"
    },
    profileButton: {
        textTransform: "none"
    },
    headerContainer: {
        display: "inline-block",
        marginLeft: theme.spacing(3),
    }
}))

type DashboardHeaderProps = {
    sidebarMinimized: boolean;
    toggleSidebarMinimized: () => void;
    openSettingsPanel: () => void;
}

const DashboardHeader = ({sidebarMinimized, toggleSidebarMinimized, openSettingsPanel}: DashboardHeaderProps) => {
    const [profileButtonEl, setProfileButtonEl] = useState(null);
    const {report} = useContext(ReportContext);
    const {data: session} = useGetSessionQuery();
    const {data: reportsList} = useGetReportsQuery();
    const userButtonRef = useRef();
    const user = session.user;
    const {label: pageTitle} = useCurrentPage();

    const classes = useStyles();
    const ToggleIcon = sidebarMinimized ? ListIcon : MoreVertIcon;

    return <Box height={'40px'} padding={3} display={'flex'} justifyContent={'space-between'}>
        <Box>
            <Fab size="small" className={classes.minimizeSidebarButton} onClick={toggleSidebarMinimized}>
                <ToggleIcon/>
            </Fab>
            <div className={classes.headerContainer}>
                <Typography variant={"h1"} className={classes.pageTitle}>{pageTitle}</Typography>
                <Typography variant={"h3"} className={classes.reportDomainTitle}>{report.domain}</Typography>
            </div>
        </Box>
        <Box>
            {report.enable_todo ? <AllTodoItemsButton sx={{mr: 1}} color={'secondary'}/> : null}
            {(report.integrations.google as GoogleIntegrationNotSetup).expired &&
                <Box display={"inline"}>
                    <Typography color={"error"} display={"inline"} sx={{mr: 2}}>Google integration expired</Typography>
                    <Button sx={{mr: 2}} variant="outlined" size="small" color="error" onClick={openSettingsPanel}>Fix</Button>
                </Box>
            }
            {report.enable_notes ? <AllPageNotesButton sx={{mr: 1}} color={'secondary'}/> : null}
            {doesUserOwnReport(user, report) ?
                <Button
                    startIcon={<SettingsIcon/>}
                    sx={{mr: 1}}
                    onClick={() => openSettingsPanel()}
                    color={'secondary'}
                >Manage Site</Button>
                : null}
            <Button
                startIcon={<PersonIcon/>}
                className={classes.profileButton}
                ref={userButtonRef}
                onClick={() => setProfileButtonEl(userButtonRef.current)}
                data-test={"user-actions"}
                endIcon={profileButtonEl === null ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
                variant={'outlined'}
            >
                {user.first_name + " " + user.last_name}
            </Button>
            <UserProfileActionsPopover
                anchorEl={profileButtonEl}
                onClose={() => setProfileButtonEl(null)}
                showSwitchReport={reportsList.reports.length > 1}
                openSettingsPanel={openSettingsPanel}
            />
        </Box>
    </Box>;
}

DashboardHeader.propTypes = {
    sidebarMinimized: PropTypes.bool.isRequired,
    toggleSidebarMinimized: PropTypes.func.isRequired,
    openSettingsPanel: PropTypes.func.isRequired
}


export default DashboardHeader;