import {Box, BoxProps, Tooltip, Typography} from "@mui/material";
import React from "react";
import {
    useGetSwitchBillingPeriodCheckoutUrlMutation
} from "../../store/slice/myContentDashAdminApi";
import {useSnackbar} from "notistack";
import extractErrorMessageFromError from "../../util/extractErrorMessageFromError";
import {LoadingButton} from "@mui/lab";

type SubscriptionInfoProps = {
    billingPeriod: "year" | "month";
    allowYearly: boolean;
} & BoxProps;

const SubscriptionInfo = ({billingPeriod, allowYearly, ...props}: SubscriptionInfoProps) => {
    const [generateNewPeriodUrl, {isLoading: isGeneratingNewPeriodUrl}] = useGetSwitchBillingPeriodCheckoutUrlMutation();
    const {enqueueSnackbar} = useSnackbar();

    const switchBillingPeriod = async () => {
        const newPeriod = billingPeriod === "month" ? "year" : "month";

        try {
            const response = await generateNewPeriodUrl({newPeriod}).unwrap();
            window.location.href = response.checkout_url;
        } catch (error) {
            enqueueSnackbar(extractErrorMessageFromError(error), {variant: 'error'});
        }
    }


    return <Box display={'flex'} alignItems={'center'} {...props}>
        <Typography fontWeight={'bold'}>
            Billing period: <Typography fontWeight={'normal'}
                                        component={'span'}>{billingPeriod === "month" ? "Monthly" : "Yearly"}</Typography>
        </Typography>
        {billingPeriod === "month" && !allowYearly ? null :
            <Tooltip
                title={billingPeriod === "month" ? "" : "Switching to a monthly subscription will apply credits to your account for the unused portion of the paid year"}>
                <LoadingButton
                    size={'small'}
                    sx={{textTransform: 'none', ml: 1}}
                    loading={isGeneratingNewPeriodUrl}
                    onClick={switchBillingPeriod}
                >{`Switch to ${billingPeriod === "month" ? "yearly" : "monthly"}`}</LoadingButton>
            </Tooltip>}
    </Box>
}

export default SubscriptionInfo;