import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {SearchBarInput} from "../../components/table/useReportTable";

export type ReportState = {
    pathname: string | null;
    searchBarValues: (SearchBarInput & { pageId: number })[];
}

const initialState: ReportState = {
    pathname: null,
    searchBarValues: [],
}


export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        updateSearchBar: (state, action: PayloadAction<(SearchBarInput & { pageId: number })>) => {
            console.log("Update search bar", action.payload)
            const index = state.searchBarValues.findIndex(searchBarValue => searchBarValue.pageId === action.payload.pageId);
            if (index === -1) {
                state.searchBarValues = [...state.searchBarValues, action.payload];
            } else {
                state.searchBarValues[index] = action.payload;
            }
        },
        updateReportRoutePath: (state, action: PayloadAction<string>) => {
            state.pathname = action.payload;
        },
    }
});

// Action creators are generated for each case reducer function
export const {
    updateReportRoutePath,
    updateSearchBar
} = reportSlice.actions

export default reportSlice.reducer