import React, {useContext, useEffect, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, TextField,} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {Search} from "@mui/icons-material";
import Box from "@mui/material/Box";
import SearchBarColumnSelect from "./SearchBarColumnSelect";
import {useDispatch} from "react-redux";
import {updateSearchBar} from "../../store/slice/reportSlice";
import Column from "../../types/Column";
import store, {useAppSelector} from "../../store/store";
import {initializeTableFilterInputs, resetFeaturedFilterInputInUrl} from "../../store/slice/filtersSlice";
import ReactGA from "react-ga";
import GAEventCategory from "../../types/GAEventCategory";
import {GAEventActionReport} from "../../types/GAEventAction";
import useCurrentPage from "../../hooks/useCurrentPage";
import ReportContext from "../../context/ReportContext";
import {searchBarSelector} from "../../store/slice/reportSelector";

const SearchBar = () => {
    const dispatch = useDispatch();
    const page = useCurrentPage();
    const {report} = useContext(ReportContext);
    if (!('table_data' in page)) {
        throw new Error('A page search bar cannot be used outside of a ReportPage.');
    }
    const columns = (page.table_data
        .filter(component => (component.hasOwnProperty("type") ? component.type : "table") === "table")
        .map(component => report.tables.find(table => table.id === component.id).columns).flat() as Column[])
        .filter(column => column.is_search_column);
    const [searchBarContents, setSearchBarContents] = useState('');
    const [columnIds, setColumnIds] = useState(() => columns.map(({id}) => id));
    const [advancedSearchHelpDialogOpen, setAdvancedSearchHelpDialogOpen] = useState(false);
    const searchBarInput = useAppSelector(searchBarSelector(page.id));

    useEffect(() => {
        setSearchBarContents(searchBarInput.searchTerm);
        setColumnIds(searchBarInput.columnIds);
    }, [searchBarInput]);

    const onSubmit = () => {
        dispatch(updateSearchBar({
            pageId: page.id,
            columnIds,
            searchTerm: searchBarContents,
            useAdvancedSearch: true
        }));
        if (searchBarContents !== "") {
            ReactGA.event({
                category: GAEventCategory.REPORT,
                action: GAEventActionReport.SUBMITTED_TABLE_SEARCH
            });
        }
    }

    const clearSearch = () => {
        setSearchBarContents("");
        setColumnIds(columns.map(({id}) => id));
        resetFeaturedFilterInputInUrl(store);
        initializeTableFilterInputs(report, store);
        dispatch(updateSearchBar({
            pageId: page.id,
            columnIds: columns.map(({id}) => id),
            searchTerm: "",
            useAdvancedSearch: true
        }));
    }

    return <Box p={1}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}/>
            <Grid item xs={6}>
                <TextField
                    InputProps={{
                        autoComplete: "off"
                    }}
                    value={searchBarContents}
                    onChange={event => setSearchBarContents(event.target.value)}
                    label={"Search"}
                    name={"table-search"}
                    autoComplete={"on"}
                    onKeyDown={ev => {
                        if (ev.key === "Enter") {
                            onSubmit();
                            ev.preventDefault();
                        }
                    }}
                    fullWidth
                />
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    height={"42px"}
                >
                    <div/>
                    <SearchBarColumnSelect
                        selectedColumnIds={columnIds}
                        setSelectedColumnIds={setColumnIds}
                        availableColumns={columns}
                    />
                </Box>
            </Grid>
            <Grid item xs={2} display={"flex"}>
                <Button
                    onClick={onSubmit}
                    variant="contained"
                    startIcon={<Search/>}
                    disableElevation
                    data-test={"search-bar-button"}
                >
                    Search
                </Button>
                <Button
                    sx={{ml: 1.5}}
                    onClick={clearSearch}
                    color={"secondary"}
                    data-test={"search-bar-clear-button"}
                >Clear</Button>
            </Grid>
            <Grid item xs={2}/>
        </Grid>
        <Dialog
            open={advancedSearchHelpDialogOpen}
            onClose={() => setAdvancedSearchHelpDialogOpen(false)}
            fullWidth
            maxWidth={'xs'}
        >
            <DialogTitle>Advanced Search</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    With advanced search functionality enabled, you can use special characters in your search term.
                </DialogContentText>
                <Divider sx={{mt: 1, mb: 3}}/>
                <TextField
                    label={"Alternative (OR) Keywords"}
                    size={"small"}
                    value={"mushroom (stew, soup)"}
                    onChange={() => {
                    }}
                    helperText={'Matches both "mushroom stew" and "mushroom soup"'}
                    sx={{mb: 1}}
                />
                <Divider sx={{my: 1, mb: 2}}/>
                <TextField
                    label={"Exclude keyword"}
                    size={"small"}
                    value={"bread -keto"}
                    onChange={() => {
                    }}
                    helperText={'Excludes "keto" from search. Matches "cinnamon bread" but not "keto cinnamon bread"'}
                    sx={{mb: 1}}
                />
                <Divider sx={{my: 1, mb: 2}}/>
                <TextField
                    label={"Match keywords in any order (all contains)"}
                    size={"small"}
                    value={"[spicy soup]"}
                    onChange={() => {
                    }}
                    helperText={'Match keywords in any order. Matches "spicy chicken soup" but not "tomato soup".'}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setAdvancedSearchHelpDialogOpen(false)}
                >Close</Button>
            </DialogActions>
        </Dialog>
    </Box>;
}

export default SearchBar;